import React from 'react';

import { Checkbox, Flex, Stack } from '@chakra-ui/react';
import { Formik } from 'formik';

import { cpfCnpjMask } from '../../../services/cpfCnpj.service';
import { getZipcodeService } from '../../../services/getzipcode.service';

import { zipCodeMask } from '../../../services/mask.service';
import { PaymentForm } from '../types/paymentForm.type';

import { schema } from '../schema/FormSchema';

import { useConsultVehicleHistory } from '../context/ConsultVehicleHistoryProvider';

import { defaultColors } from '../../../config/variables';

import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import TabsWrapper from './TabsWrapper';


const Checkout = () => {
	const { payer } = useConsultVehicleHistory()
	const payerName = (payer?.first_name && payer.last_name) ? `${payer?.first_name} ${payer?.last_name}` : ''
	const initialValues: PaymentForm = {
		email: payer?.email ?? '',
		name:  payerName,
		document: payer?.document ?? '',
		zipcode: payer?.address.zipcode ?? '',
		number: payer?.address.number ?? '',
		street: payer?.address.street ?? '',
		city: payer?.address.city ?? '',
		state: payer?.address.state ?? '',
		complement: payer?.address.complement ?? '',
		district: payer?.address.district ?? '',
		confirm: false,
	};

	return (
		<Stack>
			<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={() => {}} validateOnMount>
				{({ handleSubmit, isValid, values, setFieldValue }) => {
					const getZipCode = async (value: string) => {
						const address = await getZipcodeService(value);
						setFieldValue('street', address?.logradouro);
						setFieldValue('city', address?.localidade);
						setFieldValue('state', address?.uf);
						setFieldValue('complement', address?.complemento);
						setFieldValue('district', address?.bairro);
					};

					return (
						<form onSubmit={handleSubmit}>
							<Stack alignItems='center' w='100%'>
								<Text color='black' textAlign={['center', 'start']}>
									Preencha o formulário para realizar o pagamento
								</Text>
								<Stack w={['90%', '45%']} gap='0' border='1px solid grey' borderRadius={10} p='20px'>
									<Stack flexDirection={['column', 'row']} gap={[0, '20px']} w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Nome Completo</Text>
												<Input
													name='name'
													id='input__name'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='name' />
											</Stack>
										</FormControl>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>CPF/CNPJ</Text>
												<Input
													name='document'
													id='input__document'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
													maxLength={18}
													onChange={(e) => setFieldValue('document', cpfCnpjMask(e.target.value))}
												/>
												<FormErrorMessage name='document' />
											</Stack>
										</FormControl>
									</Stack>
									<Stack w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>E-mail:</Text>
												<Input
													name='email'
													id='input__email'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													w='100%'
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='email' />
											</Stack>
										</FormControl>
									</Stack>
									<Stack flexDirection={['column', 'row']} gap={[0, '20px']} w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>CEP</Text>
												<Input
													name='zipcode'
													id='input__zipcode'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
													maxLength={9}
													onChange={(e) => {
														setFieldValue('zipcode', zipCodeMask(e.target.value));
														const value = e.target.value.replace(/[^0-9]/g, '');
														if (value.length === 8) {
															getZipCode(value);
														}
													}}
												/>
												<FormErrorMessage name='zipcode' />
											</Stack>
										</FormControl>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Número</Text>
												<Input
													name='number'
													id='input__number'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='number' />
											</Stack>
										</FormControl>
									</Stack>
									<Stack w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Rua</Text>
												<Input
													name='street'
													id='input__street'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													w='100%'
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='street' />
											</Stack>
										</FormControl>
									</Stack>
									<Stack flexDirection={['column', 'row']} gap={[0, '20px']} w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Complemento</Text>
												<Input
													name='complement'
													id='input__complement'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='complement' />
											</Stack>
										</FormControl>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Bairro</Text>
												<Input
													name='district'
													id='input__district'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='district' />
											</Stack>
										</FormControl>
									</Stack>
									<Stack flexDirection={['column', 'row']} gap={[0, '20px']} w='100%'>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Cidade</Text>
												<Input
													name='city'
													id='input__city'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='city' />
											</Stack>
										</FormControl>
										<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
											<Stack flexDirection='column' w='100%' gap='0'>
												<Text color='black'>Estado</Text>
												<Input
													name='state'
													id='input__state'
													textAlign={['center', 'center', 'start']}
													isReadOnly={false}
													maxLength={2}
													border={`1px solid ${defaultColors.lightGreen}`}
												/>
												<FormErrorMessage name='state' />
											</Stack>
										</FormControl>
									</Stack>
								</Stack>
								<FormControl
									display='flex'
									w={['100%', '50%']}
									flexDirection='row'
									pb='8px'
									mb='20px'
									borderBottom={values.confirm ? '1px solid #ccc' : 'none'}
								>
									{' '}
									<Checkbox
										id='input__checkbox-consult-vehicle-data-confirm'
										name='confirm'
										color='#757575'
										justifyContent='center'
										size={['md', 'lg']}
										w='100%'
										onChange={(e) => setFieldValue('confirm', e.target.checked)}
									>
										Confirmo as informações preenchidas
									</Checkbox>
								</FormControl>
								{isValid && values.confirm && (
									<>
										<Flex>
											<TabsWrapper
												formValues={values}
											/>
										</Flex>
									</>
								)}
							</Stack>
						</form>
					);
				}}
			</Formik>
		</Stack>
	);
};

export default Checkout;
