import { Flex, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import Button from '../../../components/Button';
import FileUploadInput from '../../../components/FileUploadInput';
import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import ConfirmModal from './ConfirmModal';

type UploadFormProps = {
	handleFormSubmit: () => Promise<void>;
	file: File | null;
	setFile: React.Dispatch<React.SetStateAction<File | null>>;
	isLoading: boolean;
};

const UploadForm: React.FC<UploadFormProps> = (props: UploadFormProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Formik enableReinitialize initialValues={{}} onSubmit={onOpen}>
			{({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Flex alignItems='center' flexDirection={['column', 'column', 'row']} gap='20px'>
							<Flex alignItems='center' border='1px solid lightgray' borderRadius='md' p={2}>
								<FileUploadInput
									containerProps={{ m: 0 }}
									inputProps={{
										id: 'file-upload',
										onChange: (e) => {
											if (e.target.files?.length) {
												props.setFile(e.target.files[0]);
											}
										},
										accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv',
									}}
								/>
								<Text mx={2} whiteSpace='nowrap'>
									{props.file ? props.file.name : 'Selecione um arquivo.'}
								</Text>
							</Flex>
							<Button
								type='submit'
								isDisabled={!props.file}
								h='40px'
								isLoading={props.isLoading}
								border={`1px solid ${defaultColors.lightGreen}`}
							>
								Enviar
							</Button>
							<ConfirmModal handleModalSubmit={props.handleFormSubmit} isOpen={isOpen} onClose={onClose} />
						</Flex>
					</form>
				);
			}}
		</Formik>
	);
};

export default UploadForm;
