import { StylesConfig } from 'react-select';

export const customStyle: StylesConfig = {
	control: (provided) => ({
		...provided,
		backgroundColor: 'transparent',
		paddingRight: '13px',
		paddingLeft: '13px',
		fontSize: '14px',
		fontFamily: 'Montserrat',
		fontWeight: '600',
		width: '100%',
		border: '2px solid black',
		borderRadius: '15px',
	}),

	dropdownIndicator: (provided) => ({
		...provided,
		color: '#7D7D7D',
		svg: {
			height: '22px',
			width: '22px',
		},
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: '12px',
	}),
};
