import React from 'react';

import { Box, Image, Stack, Text } from '@chakra-ui/react';

import { GetReportDto, GetVehicleInquiriesDto, ReportSeller } from '../clients';

import { InquiriesPrint } from './InquiriesPrint';
import { centsToRealWithComma } from '../services/replaceDotWithComma.service';
import { format } from 'date-fns';

type PayerReceiptProps = {
	report: GetReportDto | undefined;
	payerName?: string;
	payerDocument?: string;
	inquiries?: GetVehicleInquiriesDto;
	seller?: ReportSeller;
};

export const PayerReceipt: React.FC<PayerReceiptProps> = (props: PayerReceiptProps) => {
	return (
		<Stack
			css={{
				'@media screen': {
					display: 'none',
				},
				'@media print': {
					display: 'flex',
				},
			}}
			w='100%'
			alignItems='center'
		>
			<Stack w='85%' alignItems='center'>
				<Box borderBottom='4px solid #E2E8F0' p='16px' borderRadius='md' boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)' w='100%'>
					<Stack w='100%' alignItems='center'>
						<Stack mb='20px'>
							<Text fontSize='3xl' borderBottom='1px solid grey'>
								Recibo de Pagamento
							</Text>
						</Stack>
						<Stack direction='row' alignItems='center' justifyContent='space-between' w='100%'>
							<Stack spacing='8px' textAlign='center' flex='1' w='100%'>
								<Text fontWeight='bold'>Colégio Notarial do Brasil - Seção Minas Gerais</Text>
								<Text>
									<strong>CNPJ: </strong> 14.947.092/0001-26
								</Text>
								<Text>
									<strong>ENDEREÇO: </strong> Avenida Brasil, 1479, Sala 701, Belo Horizonte/MG, 30.140-005
								</Text>
							</Stack>
							<Box textAlign='right' w='100px'>
								<Image src={require('./../assets/images/seal.png')} />
							</Box>
						</Stack>
					</Stack>
				</Box>
				{props.payerName && props.payerDocument && props.payerName !== '' && props.payerDocument !== '' && (
					<Box
						borderTop='1px solid #E2E8F0'
						borderBottom='4px solid #E2E8F0'
						p='16px'
						borderRadius='md'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
						w='100%'
					>
						<Stack w='100%'>
							<Text fontSize='22px' fontWeight='bold' borderBottom='1px solid grey'>
								Dados do Pagador:
							</Text>
							<Text>NOME: {props.payerName}</Text>
							<Text>CPF/CNPJ: {props.payerDocument}</Text>
						</Stack>
					</Box>
				)}
				<Box
					borderTop='1px solid #E2E8F0'
					borderBottom='4px solid #E2E8F0'
					p='16px'
					borderRadius='md'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
					w='100%'
				>
					<Stack w='100%'>
						<Text fontSize='22px' fontWeight='bold' borderBottom='1px solid grey'>
							Serviço Realizado:
						</Text>
						<Text>Consulta Veicular {props.report?.document_type}</Text>
						<Text>Placa: {props.report?.license_plate}</Text>
						<Text>Valor Total: R$ {centsToRealWithComma(props.report?.consult_value ?? 0)}</Text>
						<Text>
							Data da consulta: { props.report?.created_at ? format(new Date(props.report.created_at), 'dd/MM/yyyy HH:mm:ss'): ''}
						</Text>
					</Stack>
				</Box>
				{props.report?.url && <InquiriesPrint report={props?.report} inquiries={props?.inquiries} />}
			</Stack>
		</Stack>
	);
};
