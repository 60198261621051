import { Configuration } from '../clients/runtime';
import { getSessionCookie } from '../session';

export const getApiAuthConfig = (idToken?: string) => {
	const session = getSessionCookie();

	let token = idToken || session?.id_token;

	return new Configuration({
		basePath: process.env.REACT_APP_BASE_URL,
		headers: {
			Authorization: token,
		},
	});
};

export const getApiDefaultConfig = () =>
	new Configuration({
		basePath: process.env.REACT_APP_BASE_URL,
	});

export const parseExceptionMessage = async (exception: any, defaultErrorMessage: string): Promise<string> => {
	let message = exception.message || defaultErrorMessage;

	if (exception.status) {
		const responseData = await exception.json();

		if (!Array.isArray(responseData.message) && !(responseData.message instanceof Object)) {
			message = responseData.message;
		}
	}

	return message;
};
