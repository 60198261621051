import React from 'react';


import { startOfMonth, startOfToday, startOfYesterday, subDays } from 'date-fns';
import Button from '../../../components/Button';
import { IPeriod } from '../../../components/RangeDatePicker';
import { defaultColors } from '../../../config/variables';

type QuickFiltersProps = {
	setPeriod: React.Dispatch<React.SetStateAction<IPeriod[]>>;
	setSelectedFilter: React.Dispatch<React.SetStateAction<number>>;
	setQuickDateChange: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFilter: number;
};

export type QuickFiltersData = {
	id: number;
	description: string;
	period: IPeriod;
};

const QuickFilters: React.FC<QuickFiltersProps> = (props: QuickFiltersProps) => {

	const date = new Date()
	const filters: QuickFiltersData[] = [
		{
			id: 1,
			description: 'Hoje',
			period: {
				startDate: startOfToday(),
				endDate: startOfToday(),
				key: 'selection',
			},
		},
		{
			id: 2,
			description: 'Ontem',
			period: {
				startDate: startOfYesterday(),
				endDate: startOfYesterday(),
				key: 'selection',
			},
		},
		{
			id: 3,
			description: 'Últimos 7 dias',
			period: {
				startDate: subDays(startOfToday(), 7),
				endDate: startOfToday(),
				key: 'selection',
			},
		},
		{
			id: 4,
			description: 'Este mês',
			period: {
				startDate: startOfMonth(new Date()),
				endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
				key: 'selection',
			},
		},
		{
			id: 5,
			description: 'Últimos 30 dias',
			period: {
				startDate: subDays(startOfToday(), 30),
				endDate: startOfToday(),
				key: 'selection',
			},
		},
	];

	const handleClick = (filter: QuickFiltersData) => {
		props.setPeriod([filter.period]);
		props.setQuickDateChange((prevState) => !prevState);
		props.setSelectedFilter(filter.id);
	};

	return (
		<>
			{filters.map((filter) => {
				return (
					<Button
						key={filter.id}
						onClick={() => handleClick(filter)}
						size='md'
						bgColor={filter.id === props.selectedFilter ? defaultColors.darkBlue : defaultColors.primaryColor}
						whiteSpace='normal'
					>
						{filter.description}
					</Button>
				);
			})}
		</>
	);
};

export default QuickFilters;
