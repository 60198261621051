import React from 'react';

import { Box, Modal } from '@chakra-ui/react';

import PaymentThreeDS from './PaymentThreeDS';

type PaymentSecureModalProps = {
	paymentSecure: boolean;
	action: any;
	setPaymentSecure(open: boolean): void;
	saleID: string;
};

const PaymentSecureModal: React.FC<PaymentSecureModalProps> = ({
	paymentSecure,
	action,
	setPaymentSecure,
	saleID,
}) => {
	return (
		<Modal isOpen={paymentSecure} onClose={()=> setPaymentSecure(false)}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<PaymentThreeDS
					action={action}
					setPaymentSecure={setPaymentSecure}
					saleID={saleID}
				/>
			</Box>
		</Modal>
	);
};

export default PaymentSecureModal;
