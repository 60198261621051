import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputLeftElement, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';

interface SingleDatePickerProps {
	selectedDate: Date | null;
	setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

const SingleDatePicker: React.FC<SingleDatePickerProps> = (props: SingleDatePickerProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleSelect = (date: Date) => {
		props.setSelectedDate(date);
		setIsOpen(false);
	};

	return (
		<Box>
			<Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<PopoverTrigger>
					<InputGroup>
						<InputLeftElement>
							<CalendarIcon color='gray.500' />
						</InputLeftElement>
						<Input
							value={props.selectedDate ? props.selectedDate.toLocaleDateString('pt-BR') : ''}
							onClick={() => setIsOpen(!isOpen)}
							readOnly
						/>
					</InputGroup>
				</PopoverTrigger>
				<PopoverContent width='350px'>
					<Box p='10px'>
						<Calendar locale={ptBR} minDate={new Date()} date={props.selectedDate || new Date()} onChange={handleSelect} />
					</Box>
				</PopoverContent>
			</Popover>
		</Box>
	);
};

export default SingleDatePicker;
