/* eslint-disable */
import React from 'react';

import AdyenCheckout from '@adyen/adyen-web';

import '@adyen/adyen-web/dist/adyen.css';

import { Spinner } from '@chakra-ui/react';

const threeDSConfiguration = {
	size: '02',
};

type PaymentThreeDSProps = {
	action: any;
	setPaymentSecure(secure: boolean): void;
	saleID: string;
};

const PaymentThreeDS: React.FC<PaymentThreeDSProps> = ({ action }) => {
	const config = {
		environment: process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT,
		clientKey: process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY,
		action,
	};

	React.useEffect(() => {
		const checkout = new AdyenCheckout(config);
		checkout.createFromAction(action, threeDSConfiguration).mount('#three-checkout');
	}, [action, config]);

	return (
		<>
			<div id='three-checkout' style={{ display: 'block' }}></div>
			<Spinner
				thickness='5px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='lg'
				p='8px'
				mb='8px'
				display='none'
			/>
		</>
	);
};

export default PaymentThreeDS;
