import React from 'react';

import { Box, FormLabel, Input, InputProps, Stack, Textarea } from '@chakra-ui/react';

import { defaultColors } from '../../../config/variables';

interface CrvInputProps extends InputProps {
	ml?: string | string[];
	w?: string | string[];
	value?: any;
	id: string;
	children?: string;
	isTextArea?: boolean;
	className?: string;
}

const CrvInput: React.FC<CrvInputProps> = React.forwardRef(({ ml, w, children, value, id, isTextArea = false, className }, ref) => {
	const printFontSize = {
		'@media print': {
			fontSize: 'medium',
		},
	};
	return (
		<Stack ml={ml || '0px'} w={w} className={className}>
			<Box border={`3px solid ${defaultColors.darkBlue}`} p='8px' position='relative' borderRadius='12px'>
				<FormLabel
					position='absolute'
					top='-10px'
					zIndex='0'
					backgroundColor='white'
					px='8px'
					color={defaultColors.darkBlue}
					fontSize={['10', '10', '13']}
					css={{
						'@media print': {
							fontSize: 'small',
						},
					}}
				>
					{children}
				</FormLabel>
				{!isTextArea ? (
					<Input readOnly variant='unstyled' value={value} id={id} fontSize={['12', '12', '16']} css={printFontSize} />
				) : (
					<Textarea
						w='100%'
						resize='none'
						readOnly
						variant='unstyled'
						value={value}
						id={id}
						fontSize={['12', '12', '16']}
						css={printFontSize}
					/>
				)}
			</Box>
		</Stack>
	);
});

export default CrvInput;
