import React, { useEffect } from 'react';

import { Flex, Spinner, Stack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { usePubNub } from 'pubnub-react';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useToasts } from 'react-toast-notifications';

import {
	DebtsListsApi,
	GetBilletInoformationResponseDto,
	GetDebtsListResponseDto,
	GetDebtsListResponseDtoStatusEnum,
	PaymentsApi,
} from '../../clients';

import { getApiDefaultConfig } from '../../services/api.service';
import { RealTimeChannel } from '../../services/real-time.service';
import { centsToRealWithComma } from '../../services/replaceDotWithComma.service';

import { useConsultDebts } from './context/ConsultDebtsProvider';

import { PagesEnum } from '../Consult/enums/pages.enum';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Checkout from './components/Checkout';
import ConsultDebtsTable from './components/ConsultDebtsTable';

type ConsultVehicleDataProps = {
	setStage: React.Dispatch<React.SetStateAction<number>>;
};

const ConsultDebts: React.FC<ConsultVehicleDataProps> = (props: ConsultVehicleDataProps) => {
	const { token, setSimulation, setGetBilletResponse, getBilletResponse, selectedDebt } = useConsultDebts();
	const apiConfig = getApiDefaultConfig();
	const paymentsApi = new PaymentsApi(apiConfig);
	const debtsListsApi = new DebtsListsApi(apiConfig);
	const { addToast } = useToasts();

	const [checkout, setCheckout] = React.useState<boolean>(false);
	const [vehicleDebts, setVehicleDebts] = React.useState<GetDebtsListResponseDto | undefined>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const pubNub = usePubNub();

	async function getVehicleDebts() {
		setLoading(true);
		try {
			if (token) {
				const vehicleDebtsResponse: GetDebtsListResponseDto = await debtsListsApi.getDebtsListByLicensePlate({ token });

				if (vehicleDebtsResponse.status === GetDebtsListResponseDtoStatusEnum.Succeeded) {
					setVehicleDebts(vehicleDebtsResponse);
					setLoading(false);
				}

				addToast('Sua busca está sendo processada!', {
					appearance: 'info',
					autoDismiss: true,
				});
			}
		} catch (error) {
			addToast('Erro ao buscar os débitos do veículo.', {
				appearance: 'error',
				autoDismiss: true,
			});
			setLoading(false);
		}
	}

	async function authorizeBarcodePayment(id: string) {
		setLoading(true);
		try {
			const response: GetBilletInoformationResponseDto = await paymentsApi.getBilletInformation({ debtId: id });
			setGetBilletResponse(response);
			return true;
		} catch (error) {
			addToast('Erro ao buscar a informação do boleto.', {
				appearance: 'error',
				autoDismiss: true,
			});
			return false;
		} finally {
			setLoading(false);
		}
	}

	const completeSearchDebtsList = () => {
		if (token) {
			const channels = pubNub.getSubscribedChannels().filter((channel) => channel !== RealTimeChannel.MAINTENANCE_MODE);
			pubNub.unsubscribe({ channels });
			pubNub.subscribe({ channels: [token] });
			pubNub.addListener({
				message(event) {
					const { message, channel } = event;
					if (channel === token) {
						const { search_debts } = message;
						const { updatedDebtsList } = search_debts;
						setVehicleDebts(updatedDebtsList);

						setLoading(false);
					}
				},
			});
		}
	};

	async function getSimulation(value: number) {
		setLoading(true);
		try {
			const simulationResponse = await paymentsApi.listSimulation({ amountCents: value, isCelcoin: true });
			setSimulation(simulationResponse);
			setCheckout(true);
		} catch (simulationError) {
			addToast('Erro ao gerar a simulação dos valores.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (!vehicleDebts) {
			getVehicleDebts();
		}
		completeSearchDebtsList();
		//eslint-disable-next-line
	}, [pubNub]);

	return (
		<>
			{!checkout ? (
				<>
					{loading ? (
						<>
							<Stack w='100%' alignItems='start'>
								<Button
									variant='unset'
									bgColor='transparent'
									color='black'
									onClick={() => {
										props.setStage(PagesEnum.ConsultSelect);
									}}
								>
									<MdOutlineKeyboardBackspace size={30} />
									<Text> Voltar</Text>
								</Button>
							</Stack>
							<Stack justify='center' align='center' height='76vh' w='100%'>
								<Spinner />
								<Text textAlign='center'>Aguarde, esta consulta pode demorar alguns minutos!</Text>
							</Stack>
						</>
					) : (
						<Stack minH='76vh'>
							<Stack p='15px' alignItems={['center', 'start']}>
								<Button
									variant='unset'
									bgColor='transparent'
									color='black'
									onClick={() => {
										props.setStage(PagesEnum.ConsultSelect);
									}}
								>
									<MdOutlineKeyboardBackspace size={30} />
									<Text> Voltar</Text>
								</Button>
							</Stack>

							<Stack gap='75px'>
								<Stack alignItems='center'>
									<Title fontSize='24px'>Consulta de Débitos Veiculares</Title>
									<Text textAlign='center'>Para o seu conforto e segurança, pague seus débitos individualmente.</Text>
								</Stack>
							</Stack>
							<ConsultDebtsTable list={vehicleDebts} authorizeBarcodePayment={authorizeBarcodePayment} getSimulation={getSimulation} />
							<Stack gap='75px'>
								<Stack alignItems='center' mt='10px' px={['10px', '20px']} spacing='5px' p='10px'>
									{vehicleDebts?.created_at && (
										<Flex textAlign='center' gap='5px'>
											<Text fontWeight='bold' mb='2px'>
												Consulta realizada em:
											</Text>
											<Text>{format(new Date(vehicleDebts.created_at), 'dd/MM/yyyy HH:mm:ss')}</Text>
										</Flex>
									)}
									<Stack spacing={4} textAlign={['justify', 'center']} px={['10px', '20px']} py='10px'>
										<Text fontSize='sm' color='gray.700'>
											<Text as='span' color='red.500' mr='5px'>
												*
											</Text>
											Esta consulta retorna débitos não vencidos, gerados na data acima.
										</Text>
										<Text fontSize='sm' color='gray.700'>
											<Text as='span' color='red.500' mr='5px'>
												*
											</Text>
											No ato da consulta, os débitos gerados podem ter sua data de vencimento para o mesmo dia, não sendo possível efetuar o
											pagamento após esta data pela plataforma.
										</Text>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					)}
				</>
			) : (
				<>
					<Stack p='15px' alignItems={['center', 'start']}>
						<Button
							variant='unset'
							bgColor='transparent'
							color='black'
							onClick={() => {
								setCheckout(false);
								props.setStage(PagesEnum.ConsultSelect);
							}}
						>
							<MdOutlineKeyboardBackspace size={30} />
							<Text> Voltar</Text>
						</Button>
					</Stack>
					<Stack alignItems='center' mb='20px'>
						<Title>Pagamento de Débitos</Title>
						<Text textAlign='center'>{selectedDebt.description}</Text>
						<Text>Valor: R$ {centsToRealWithComma(selectedDebt.total)}</Text>
						<Text>Vencimento: {format(new Date(selectedDebt.due_date), 'dd/MM/yyyy')}</Text>
					</Stack>
					<Stack>
						<Stack alignItems='center' mb='20px' fontWeight='bold' fontSize='larger'>
							<Text>Pague este débito em até 12x no cartão.</Text>
						</Stack>
					</Stack>
					<Stack p={[2, 0]}>{getBilletResponse && <Checkout setStage={props.setStage} />}</Stack>
				</>
			)}
		</>
	);
};

export default ConsultDebts;
