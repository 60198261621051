import React from 'react';

import { Flex, Stack, Table, TableCaption, TableContainer, TableContainerProps, Tbody, Td, Tr, useBreakpointValue } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai';

import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';

import { StatusInfo } from '../../Reports/interfaces/StatusInfo';

import { useConsultDebts } from '../context/ConsultDebtsProvider';

import { defaultColors } from '../../../config/variables';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { DebtReceiptModal } from './DebtReceiptModal';

type TableProps = TableContainerProps & {
	list: any;
	getSimulation: (value: number) => Promise<void>;
	authorizeBarcodePayment: (value: string) => Promise<boolean>;
};

enum Status {
	Succeeded = 'succeeded',
	Opened = 'opened',
	Pending = 'pending',
	Failed = 'failed',
}

const statusText: Record<Status, StatusInfo> = {
	[Status.Succeeded]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Pago',
	},
	[Status.Pending]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Processando',
	},
	[Status.Opened]: {
		icon: <AiFillExclamationCircle color='blue' size={16} />,
		text: 'Em Aberto',
	},
	[Status.Failed]: {
		icon: <AiFillCloseCircle color='tomato' size={16} />,
		text: 'Falha',
	},
};

const ConsultDebtsTable = (props: TableProps) => {
	const {setSelectedDebt}  = useConsultDebts()
	const isMobile = useBreakpointValue({ base: true, lg: false });
	return (
		<Stack w='100%' alignItems='center'>
			{!isMobile ? (
				<TableContainer mt='40px' minW='50%'>
					<Table variant='simple' size='md'>
						{(props?.list?.debts === undefined || props?.list?.debts.length === 0) && <TableCaption>Não constam débitos.</TableCaption>}
						{props?.list &&
							props?.list?.debts?.map((debt: any, index: number) => (
								<>
									<Tbody key={index}>
										<Tr
											_hover={{
												background: '#CCCCCC',
												cursor: debt.status === 'opened' || debt.status === 'failed' ? 'pointer' : 'default',
											}}
											onClick={async () => {
												if (
													(debt?.status === 'opened' || debt?.status === 'failed') &&
													new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).getTime() >=
														new Date().setHours(0, 0, 0, 0)
												) {
													setSelectedDebt(debt);
													const authorized = await props.authorizeBarcodePayment(debt?.id);
													if (authorized) {
														props.getSimulation(debt?.total);
													}
												}
											}}
										>
											<Td>
												<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
													DESCRIÇÃO
												</Text>
												<div>{debt?.description}</div>
											</Td>
											<Td>
												<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
													STATUS
												</Text>
												<Flex flexDirection='row' gap='12px' alignItems='center'>
													{statusText[debt.status as Status].icon} {statusText[debt.status as Status].text}
												</Flex>
											</Td>
											<Td>
												<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
													DATA VENCIMENTO
												</Text>
												<div>
													{debt?.due_date
														? new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).toLocaleDateString('pt-BR')
														: ''}
												</div>
											</Td>
											<Td>
												<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
													VALOR
												</Text>
												<div>R$ {centsToRealWithComma(debt?.total)}</div>
											</Td>
											{debt?.status === 'opened' &&
												debt?.due_date &&
												(new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).getTime() >=
												new Date().setHours(0, 0, 0, 0) ? (
													<Td>
														<Flex flexDirection='row' justifyContent='center' fontSize='md' px={3} pb={2}>
															<Button variant='unset' backgroundColor='transparent' color={defaultColors.primaryColor}>
																Pagar
															</Button>
														</Flex>
													</Td>
												) : (
													<Td>
														<Flex flexDirection='row' justifyContent='center' fontSize='md' px={3} pb={2} color='red.500'>
															Expirado
														</Flex>
													</Td>
												))}
											{debt?.status === 'succeeded' && (
												<Td>
													<Flex flexDirection='row' justifyContent='center' fontSize='md' px={3} pb={2}>
														<DebtReceiptModal
															sizeButton='sm'
															bgColorButton='transparent'
															variantButton='transparent'
															colorButton={defaultColors.primaryColor}
															paddingButton={0}
															debt={debt}
															list={props.list}
														/>
													</Flex>
												</Td>
											)}
										</Tr>
									</Tbody>
								</>
							))}
					</Table>
				</TableContainer>
			) : (
				<TableContainer mt='40px' minW='80%' px={3}>
					<Table variant='simple' size='md' flexDirection='column' w='100%'>
						{(!props?.list?.debts || props?.list?.debts.length === 0) && <TableCaption>Não constam débitos.</TableCaption>}
						{props?.list?.debts?.map((debt: any, index: number) => (
							<Tbody
								key={index}
								w='100%'
								display='flex'
								alignItems='center'
								flexDirection='column'
								p='10px'
								bg={defaultColors.white}
								boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
								borderRadius='8px'
								border='2px solid rgba(0, 0, 0, 0.2)'
								mb='10px'
								_hover={{ background: defaultColors.white }}
							>
								<Tr
									w='100%'
									display='flex'
									flexDirection='column'
									alignItems='center'
									gap='12px'
									onClick={async () => {
										if (
											(debt?.status === 'opened' || debt?.status === 'failed') &&
											new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).getTime() >=
												new Date().setHours(0, 0, 0, 0)
										) {
											setSelectedDebt(debt);
											const authorized = await props.authorizeBarcodePayment(debt?.id);
											if (authorized) {
												props.getSimulation(debt?.total);
											}
										}
									}}
								>
									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<Text wordBreak='break-word' textAlign='center' whiteSpace='normal' fontWeight='bold' fontSize='12px'>
											{debt?.description}
										</Text>
									</Flex>

									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											STATUS:
										</Text>
										<Flex flexDirection='row' gap='12px' alignItems='center'>
											{statusText[debt.status as Status].icon} {statusText[debt.status as Status].text}
										</Flex>
									</Flex>
									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											DATA VENCIMENTO:
										</Text>
										<Text textAlign='center'>
											{debt?.due_date
												? new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).toLocaleDateString('pt-BR')
												: ''}
										</Text>
									</Flex>
									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											VALOR:
										</Text>
										<Text textAlign='center'>R$ {centsToRealWithComma(debt?.total)}</Text>
									</Flex>
									{debt?.status === 'opened' &&
										debt?.due_date &&
										(new Date(new Date(debt.due_date).setHours(new Date(debt.due_date).getHours() + 3)).getTime() >=
										new Date().setHours(0, 0, 0, 0) ? (
											<Stack w='100%'>
												<Button variant='unset' backgroundColor='transparent' color={defaultColors.primaryColor}>
													Pagar
												</Button>
											</Stack>
										) : (
											<Flex flexDirection='row' justifyContent='center' fontSize='md' px={3} pb={2} color='red.500'>
												Expirado
											</Flex>
										))}
									{debt?.status === 'succeeded' && (
										<Stack w='100%'>
											<DebtReceiptModal
												sizeButton='sm'
												bgColorButton='transparent'
												variantButton='transparent'
												colorButton={defaultColors.primaryColor}
												paddingButton={0}
												debt={debt}
												list={props.list}
											/>
										</Stack>
									)}
								</Tr>
							</Tbody>
						))}
					</Table>
				</TableContainer>
			)}
		</Stack>
	);
};

export default ConsultDebtsTable;
