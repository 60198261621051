import React from 'react';

import { Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { GetVehicleByLicensePlateDocumentTypeEnum } from '../../../clients';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useDebounce } from '../../../services/useDebounce.service';

interface ConfirmModalTypes {
	onClose: () => void;
	isOpen: boolean;
	licensePlate: string;
	type: string;
	handleModalSubmit: () => Promise<void>;
	resetForm: () => void;
	crvAtpve: GetVehicleByLicensePlateDocumentTypeEnum;
	setAcceptNewConsult: (value: React.SetStateAction<boolean>) => void;
	setIsSameDayReport: (value: React.SetStateAction<boolean>) => void;
	setLicensePlate: (value: React.SetStateAction<string>) => void;
}

const ConfirmModal: React.FC<ConfirmModalTypes> = (props: ConfirmModalTypes) => {
	const [isAcceptLoading, setIsAcceptLoading] = React.useState<boolean>(false);

	const handleDecline = () => {
		props.resetForm();
		props.setAcceptNewConsult(false);
		props.setIsSameDayReport(false);
		props.setLicensePlate('');
		props.onClose();
	};

	const handleAccept = useDebounce(() => {
		props.handleModalSubmit();
		props.setAcceptNewConsult(true);
		props.onClose();
		setIsAcceptLoading(false);
	}, 300);

	return (
		<Modal isOpen={props.isOpen} onClose={handleDecline} closeOnOverlayClick={false} size='lg'>
			<ModalOverlay />
			<ModalContent>
				<Flex padding='20px' flexDirection='column'>
					<Title textAlign='center' fontSize='2xl' borderBottom='1px solid grey'>
						Confirmar Pesquisa
					</Title>
					<Flex mt='15px'>
						<Text>
							Confirma a realização da consulta <strong>{props.crvAtpve}</strong> para o veículo de placa <strong>{props.licensePlate}</strong> ?
						</Text>
					</Flex>
					<Flex my='25px' justifyContent='space-around'>
						<Button
							type='submit'
							bgColor='red'
							onClick={handleDecline}>
							Não
						</Button>
						<Button
							isLoading={isAcceptLoading}
							type='submit'
							onClick={() => {
								setIsAcceptLoading(true);
								handleAccept();
							}}
						>
							Sim
						</Button>
					</Flex>
					<Flex>
						<Flex textAlign='justify' fontSize='13px' fontStyle='italic' flexDirection='column' gap='10px'>
							<Flex gap='5px'>
								<Text color='red'>*</Text>
								<Text>
									Garanta que os dados estejam corretos! A consulta será cobrada mesmo que os dados informados estejam incorretos, pois o
									sistema buscará todas as informações.
								</Text>
							</Flex>
							<Flex gap='5px'>
								<Text color='red'>*</Text>
								<Text>
									Caso tenha dificuldade na visualização da placa no documento CRV ou ATPVe, recomendamos consultar o cliente antes de
									prosseguir com a consulta.
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmModal;
