import React, { Dispatch, SetStateAction } from 'react';

import {
	Button,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	TableContainerProps,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';
import {
	MdDownload,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { GetBatchesDto, GetBatchesStatusEnum, SearchBatch } from '../../../clients';

import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import { StatusInfo } from '../../Reports/interfaces/StatusInfo';
import { DataValues } from '../interfaces/DataValues';

type TableProps = TableContainerProps & {
	batches: GetBatchesDto | undefined;
	body: SearchBatch[] | undefined;
	dataValues: DataValues;
	setDataValues: Dispatch<SetStateAction<DataValues>>;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setLimit: Dispatch<SetStateAction<number>>;
	fetchData: (dataValues: DataValues) => Promise<void>;
	currentPage: number;
	limit: number;
	isBackoffice: boolean;
};

const statusText: Record<GetBatchesStatusEnum, StatusInfo> = {
	[GetBatchesStatusEnum.Success]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Sucesso',
	},
	[GetBatchesStatusEnum.Pending]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Pendente',
	},
	[GetBatchesStatusEnum.Failed]: {
		icon: <AiFillCloseCircle color='tomato' size={16} />,
		text: 'Falha',
	},
};

const SimpleTable = (props: TableProps) => {
	return (
		<>
			<TableContainer w='100%'>
				<Table variant='simple' size='md' display={['none', 'none', 'table']}>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody key={body.id} _hover={{ background: '#CCCCCC' }}>
							<Tr>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO
									</Text>
									<div>{body?.seller_name}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS
									</Text>
									<div>
										{JSON.stringify(body?.seller_cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2')}
									</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL
									</Text>
									<div>{body?.email ? body.email : 'Não Informado'}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{statusText[body.status as GetBatchesStatusEnum].icon} {statusText[body.status as GetBatchesStatusEnum].text}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										USUÁRIO
									</Text>
									<div>{body?.user_name}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA/HORA DE ENVIO
									</Text>
									<div>{body.created_at ? format(new Date(body.created_at), 'dd/MM/yyyy HH:mm:ss') : ''}</div>
								</Td>
								{props.isBackoffice && (
									<Td>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											ENVIADO
										</Text>
										<>
											{body.sent_batch_url ? (
												<Button
													fontSize='20px'
													as='a'
													href={body?.sent_batch_url}
													download
													variant='link'
													leftIcon={<Icon as={MdDownload} />}
												/>
											) : (
												<div>Indisponível</div>
											)}
										</>
									</Td>
								)}
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										LOTE
									</Text>
									{body.status === GetBatchesStatusEnum.Success ? (
										<>
											{body.url ? (
												<Button fontSize='20px' as='a' href={body?.url} download variant='link' leftIcon={<Icon as={MdDownload} />} />
											) : (
												<div>Indisponível</div>
											)}
										</>
									) : (
										<Text>Indisponível</Text>
									)}
								</Td>
							</Tr>
						</Tbody>
					))}
				</Table>
				<Table variant='simple' size='md' display={['flex', 'flex', 'none']} flexDirection='column' w='100%'>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody
							w='100%'
							display='flex'
							alignItems='center'
							flexDirection='column'
							p='10px'
							bg={defaultColors.white}
							boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
							borderRadius='8px'
							border='2px solid rgba(0, 0, 0, 0.2)'
							mb='10px'
							_hover={{ background: defaultColors.white }}
						>
							<Tr w='100%' display='flex' flexDirection='column' alignItems='center' gap='12px'>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO:
									</Text>
									<div>{body?.seller_name}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS:
									</Text>
									<div>
										{JSON.stringify(body?.seller_cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2')}
									</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL:
									</Text>
									<div>{body?.email ? body.email : 'Não Informado'}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS:
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{statusText[body.status as GetBatchesStatusEnum].icon} {statusText[body.status as GetBatchesStatusEnum].text}
									</Flex>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px' whiteSpace='normal'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										USUÁRIO:
									</Text>
									<div>{body?.user_name}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA/HORA DE ENVIO:
									</Text>
									<div>{body.created_at ? format(new Date(body.created_at), 'dd/MM/yyyy HH:mm:ss') : ''}</div>
								</Flex>
								{props.isBackoffice && (
									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											ENVIADO:
										</Text>
										<>
											{body.sent_batch_url ? (
												<Button
													fontSize='20px'
													as='a'
													href={body?.sent_batch_url}
													download
													variant='link'
													leftIcon={<Icon as={MdDownload} />}
												/>
											) : (
												<div>Indisponível</div>
											)}
										</>
									</Flex>
								)}
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										LOTE:
									</Text>
									{body.status === GetBatchesStatusEnum.Success ? (
										<>
											{body.url ? (
												<Button fontSize='20px' as='a' href={body?.url} download variant='link' leftIcon={<Icon as={MdDownload} />} />
											) : (
												<div>Indisponível</div>
											)}
										</>
									) : (
										<Text>Indisponível</Text>
									)}
								</Flex>
							</Tr>
						</Tbody>
					))}
				</Table>
			</TableContainer>
			{props?.body && props.body.length > 0 && (
				<Stack justifyContent='space-around' flexDirection='row'>
					<Stack flexDirection='row'>
						<Button
							variant='link'
							id='button__double-arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									props.setCurrentPage(1);
									const dataValues = { ...props.dataValues, currentPage: 1 };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowLeft size='20px' />
						</Button>
						<Button
							variant='link'
							id='button__arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									const previousPage = props.currentPage - 1;
									props.setCurrentPage(previousPage);
									const dataValues = { ...props.dataValues, currentPage: previousPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowLeft size='20px' />
						</Button>
					</Stack>
					<Stack>
						<Text>
							<Stack flexDirection='row' alignItems='center' justifyContent='space-around' spacing='40px'>
								<Stack>
									<Text>
										Página {props.batches?.current_page} de {props.batches?.total_pages}
									</Text>
								</Stack>
								<Stack flexDirection='row' alignItems='center' w='40%'>
									<Text>Ir para página: </Text>
									<NumberInput
										id='input__page-number'
										min={1}
										max={props.batches?.total_pages}
										w='50%'
										minW='70px'
										defaultValue={props.currentPage}
										value={props.currentPage}
										onChange={(e) => {
											const newPage = Number(e);
											props.setCurrentPage(newPage);
											if (props.batches && props.batches.total_pages && newPage >= 1 && newPage <= props.batches.total_pages) {
												const dataValues = { ...props.dataValues, currentPage: newPage };
												props.setDataValues(dataValues);
												props.fetchData(dataValues);
											}
										}}
									>
										<NumberInputField
											min={1}
											max={props.batches?.total_pages}
											border='2px solid black'
											borderRadius='15px'
											_disabled={{
												color: 'black',
											}}
										/>
										<NumberInputStepper w='30%'>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Stack>
								<Stack>
									<Select
										id='input_select'
										defaultValue={10}
										w='60%'
										minW='120px'
										border='2px solid black'
										borderRadius='15px'
										value={props.limit}
										onChange={(e) => {
											const selectedValue = Number(e.target.value);
											props.setLimit(selectedValue);
											const dataValues = { ...props.dataValues, limit: selectedValue, currentPage: 1 };
											props.setDataValues(dataValues);
											props.setCurrentPage(1);
											props.fetchData(dataValues);
										}}
									>
										<option value={10}>Ver 10</option>
										<option value={20}>Ver 20</option>
										<option value={30}>Ver 30</option>
										<option value={40}>Ver 40</option>
										<option value={50}>Ver 50</option>
										<option value={100}>Ver 100</option>
									</Select>
								</Stack>
							</Stack>
						</Text>
					</Stack>
					<Stack flexDirection='row'>
						<Button
							variant='link'
							color='black'
							id='button__arrow-right'
							onClick={() => {
								const totalPages = props?.batches?.total_pages || 1;
								if (props.currentPage < totalPages) {
									const nextPage = props.currentPage + 1;
									props.setCurrentPage(nextPage);
									const dataValues = { ...props.dataValues, currentPage: nextPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowRight size='20px' />
						</Button>
						<Button
							variant='link'
							color='black'
							id='button__double-arrow-right'
							onClick={() => {
								const totalPages = props?.batches?.total_pages || 1;
								if (props.currentPage < totalPages) {
									props.setCurrentPage(totalPages);
									const dataValues = { ...props.dataValues, currentPage: totalPages };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowRight size='20px' />
						</Button>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default SimpleTable;
