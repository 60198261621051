/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBilletDto
 */
export interface GetBilletDto {
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    barcode: string;
    /**
     * 
     * @type {Date}
     * @memberof GetBilletDto
     */
    expires_at: Date;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    payment_status: GetBilletDtoPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    shipping_status: GetBilletDtoShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDto
     */
    seller_cns: string;
    /**
     * 
     * @type {number}
     * @memberof GetBilletDto
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBilletDto
     */
    is_reissued: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetBilletDto
     */
    created_at: Date;
}


/**
 * @export
 */
export const GetBilletDtoPaymentStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    Expired: 'expired'
} as const;
export type GetBilletDtoPaymentStatusEnum = typeof GetBilletDtoPaymentStatusEnum[keyof typeof GetBilletDtoPaymentStatusEnum];

/**
 * @export
 */
export const GetBilletDtoShippingStatusEnum = {
    Pending: 'pending',
    Received: 'received',
    Failed: 'failed'
} as const;
export type GetBilletDtoShippingStatusEnum = typeof GetBilletDtoShippingStatusEnum[keyof typeof GetBilletDtoShippingStatusEnum];


/**
 * Check if a given object implements the GetBilletDto interface.
 */
export function instanceOfGetBilletDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "barcode" in value;
    isInstance = isInstance && "expires_at" in value;
    isInstance = isInstance && "payment_status" in value;
    isInstance = isInstance && "shipping_status" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "seller_id" in value;
    isInstance = isInstance && "seller_name" in value;
    isInstance = isInstance && "seller_cns" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "is_reissued" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function GetBilletDtoFromJSON(json: any): GetBilletDto {
    return GetBilletDtoFromJSONTyped(json, false);
}

export function GetBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcode': json['barcode'],
        'expires_at': (new Date(json['expires_at'])),
        'payment_status': json['payment_status'],
        'shipping_status': json['shipping_status'],
        'url': json['url'],
        'email': json['email'],
        'seller_id': json['seller_id'],
        'seller_name': json['seller_name'],
        'seller_cns': json['seller_cns'],
        'amount': json['amount'],
        'is_reissued': json['is_reissued'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function GetBilletDtoToJSON(value?: GetBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'expires_at': (value.expires_at.toISOString()),
        'payment_status': value.payment_status,
        'shipping_status': value.shipping_status,
        'url': value.url,
        'email': value.email,
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_cns': value.seller_cns,
        'amount': value.amount,
        'is_reissued': value.is_reissued,
        'created_at': (value.created_at.toISOString()),
    };
}

