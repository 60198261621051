import React from 'react';

import { FormControl as DefaultFormControl, FormControlProps } from '@chakra-ui/react';

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {
	return (
		<DefaultFormControl {...props}>
			{props.children}
		</DefaultFormControl>
	)
};

export default FormControl;
