import React from 'react';

import { Alert, AlertIcon, Flex, Image, Stack, Text } from '@chakra-ui/react';

const Maintenance: React.FC = () => {
	return (
		<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
			<Stack w={`40%`} spacing={3} mt={20}>
				<Flex minH={120} flexDir={`column`} alignItems={`center`}>
				<Image src={require('../../assets/images/atencao.png')} w={[150, 150, 250]} />
				</Flex>
				<Flex flexDir={`column`}>					
					<Alert mt={10} status='error'>
						<AlertIcon />
						<Text>
							<b>
								Horário de funcionamento da CNTV
							</b>
							<br/><br/>
							<b> Segunda a Sexta: </b>
							07:30 às 19:00
							<br/>

							<b> Sábado: </b>
							De 07:30 às 14:00
							<br/>

							<b>Domingo e Feriado: </b>
							Sem Funcionamento.
						</Text>
					</Alert>					
				</Flex>
			</Stack>
		</Flex>
	)
};

export default Maintenance;