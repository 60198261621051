/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetConsultDebtsDto } from './GetConsultDebtsDto';
import {
    GetConsultDebtsDtoFromJSON,
    GetConsultDebtsDtoFromJSONTyped,
    GetConsultDebtsDtoToJSON,
} from './GetConsultDebtsDto';
import type { GetReportDto } from './GetReportDto';
import {
    GetReportDtoFromJSON,
    GetReportDtoFromJSONTyped,
    GetReportDtoToJSON,
} from './GetReportDto';
import type { GetVehicleDto } from './GetVehicleDto';
import {
    GetVehicleDtoFromJSON,
    GetVehicleDtoFromJSONTyped,
    GetVehicleDtoToJSON,
} from './GetVehicleDto';

/**
 * 
 * @export
 * @interface GetDataDto
 */
export interface GetDataDto {
    /**
     * 
     * @type {GetVehicleDto}
     * @memberof GetDataDto
     */
    vehicle: GetVehicleDto;
    /**
     * 
     * @type {GetReportDto}
     * @memberof GetDataDto
     */
    report: GetReportDto;
    /**
     * 
     * @type {GetConsultDebtsDto}
     * @memberof GetDataDto
     */
    consult_debt?: GetConsultDebtsDto;
}

/**
 * Check if a given object implements the GetDataDto interface.
 */
export function instanceOfGetDataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vehicle" in value;
    isInstance = isInstance && "report" in value;

    return isInstance;
}

export function GetDataDtoFromJSON(json: any): GetDataDto {
    return GetDataDtoFromJSONTyped(json, false);
}

export function GetDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicle': GetVehicleDtoFromJSON(json['vehicle']),
        'report': GetReportDtoFromJSON(json['report']),
        'consult_debt': !exists(json, 'consult_debt') ? undefined : GetConsultDebtsDtoFromJSON(json['consult_debt']),
    };
}

export function GetDataDtoToJSON(value?: GetDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicle': GetVehicleDtoToJSON(value.vehicle),
        'report': GetReportDtoToJSON(value.report),
        'consult_debt': GetConsultDebtsDtoToJSON(value.consult_debt),
    };
}

