import React, { FormEvent } from 'react';

import { Flex, Heading, Box, Spacer, Text, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '../../../components/Button';

type CreditCardModalProps = {
	confirmPayment: boolean;
	setConfirmPayment: React.Dispatch<React.SetStateAction<boolean>>;
	isLoading: boolean;
	handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};
const CreditCardModal: React.FC<CreditCardModalProps> = (props: CreditCardModalProps) => {
	return (
		<Modal isOpen={props.confirmPayment} onClose={() => props.setConfirmPayment(false)}>
			<ModalOverlay />
			<ModalContent margin='auto'>
				<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
					<Flex p='32px' flexDirection='column' alignItems='center'>
						<Heading fontSize='lg' paddingBottom='32px'>
							Confirmação de pagamento
						</Heading>
						<Box mt='8px' mb='20px'>
							<Text align='center'>
								Esta transação estará identificada na sua fatura como <strong>ParcelaExpress.</strong>
							</Text>
						</Box>

						<Flex flexDirection='row' alignItems='center' fontSize='md' px='12px' pb='8px'>
							<Spacer />

							<Button size='lg' color='white' onClick={() => props.handleSubmit()} isLoading={props.isLoading}>
								Confirmar Pagamento!
							</Button>
						</Flex>
					</Flex>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default CreditCardModal;
