/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBilletInoformationResponseDto
 */
export interface GetBilletInoformationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    celcoin_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    transaction_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    assignor: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    digitable: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    external_terminal: string;
    /**
     * 
     * @type {number}
     * @memberof GetBilletInoformationResponseDto
     */
    original_value: number;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    document_payer: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    due_date: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    recipient_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletInoformationResponseDto
     */
    recipient_document: string;
    /**
     * 
     * @type {number}
     * @memberof GetBilletInoformationResponseDto
     */
    type: number;
}

/**
 * Check if a given object implements the GetBilletInoformationResponseDto interface.
 */
export function instanceOfGetBilletInoformationResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "celcoin_id" in value;
    isInstance = isInstance && "transaction_id" in value;
    isInstance = isInstance && "assignor" in value;
    isInstance = isInstance && "digitable" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "external_terminal" in value;
    isInstance = isInstance && "original_value" in value;
    isInstance = isInstance && "document_payer" in value;
    isInstance = isInstance && "due_date" in value;
    isInstance = isInstance && "recipient_name" in value;
    isInstance = isInstance && "recipient_document" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function GetBilletInoformationResponseDtoFromJSON(json: any): GetBilletInoformationResponseDto {
    return GetBilletInoformationResponseDtoFromJSONTyped(json, false);
}

export function GetBilletInoformationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBilletInoformationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'celcoin_id': json['celcoin_id'],
        'transaction_id': json['transaction_id'],
        'assignor': json['assignor'],
        'digitable': json['digitable'],
        'created_at': json['created_at'],
        'external_terminal': json['external_terminal'],
        'original_value': json['original_value'],
        'document_payer': json['document_payer'],
        'due_date': json['due_date'],
        'recipient_name': json['recipient_name'],
        'recipient_document': json['recipient_document'],
        'type': json['type'],
    };
}

export function GetBilletInoformationResponseDtoToJSON(value?: GetBilletInoformationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'celcoin_id': value.celcoin_id,
        'transaction_id': value.transaction_id,
        'assignor': value.assignor,
        'digitable': value.digitable,
        'created_at': value.created_at,
        'external_terminal': value.external_terminal,
        'original_value': value.original_value,
        'document_payer': value.document_payer,
        'due_date': value.due_date,
        'recipient_name': value.recipient_name,
        'recipient_document': value.recipient_document,
        'type': value.type,
    };
}

