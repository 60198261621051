import {
	Button as ChakraButton,
	Checkbox,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	TableContainerProps,
	Tbody,
	Td,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { Dispatch, SetStateAction } from 'react';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';
import {
	MdDownload,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { GetBilletsPaymentStatusEnum, GetBilletsShippingStatusEnum, GetBilletDto, GetPaginatedBilletsDto } from '../../../clients';
import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import { useAuth } from '../../../contexts/AuthProvider';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';
import { StatusInfo } from '../../Reports/interfaces/StatusInfo';
import { DataValues } from '../interfaces/DataValues';
import Button from '../../../components/Button';
import ReissueModal from '../components/ReissueModal';
import { FaFileInvoice } from 'react-icons/fa';

type TableProps = TableContainerProps & {
	billets: GetPaginatedBilletsDto | undefined;
	body: GetBilletDto[] | undefined;
	dataValues: DataValues;
	setDataValues: Dispatch<SetStateAction<DataValues>>;
	fetchData: (dataValues: DataValues) => Promise<void>;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setLimit: Dispatch<SetStateAction<number>>;
	currentPage: number;
	limit: number;
	toggleRowSelection: (id: string, paymentStatus: GetBilletsPaymentStatusEnum) => void;
	selectedRows: string[];
};

const paymentStatus: Record<GetBilletsPaymentStatusEnum, StatusInfo> = {
	[GetBilletsPaymentStatusEnum.Success]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Sucesso',
	},
	[GetBilletsPaymentStatusEnum.Pending]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Pendente',
	},
	[GetBilletsPaymentStatusEnum.Expired]: {
		icon: <AiFillCloseCircle color='red' size={16} />,
		text: 'Expirado',
	},
};

const shippingStatus: Record<GetBilletsShippingStatusEnum, StatusInfo> = {
	[GetBilletsShippingStatusEnum.Received]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Enviado',
	},
	[GetBilletsShippingStatusEnum.Pending]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Pendente',
	},
	[GetBilletsShippingStatusEnum.Failed]: {
		icon: <AiFillCloseCircle color='red' size={16} />,
		text: 'Falho',
	},
};

const FinancialTable = (props: TableProps) => {
	const { isBackoffice } = useAuth();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedBillet, setSelectedBillet] = React.useState<GetBilletDto | null>(null);
	return (
		<>
			<TableContainer w='100%'>
				<Table w='100%' variant='simple' size='md' display={['none', 'none', 'table']}>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody key={body.id} _hover={{ background: '#CCCCCC' }}>
							<Tr>
								{isBackoffice && (
									<Td>
										{body.payment_status === GetBilletsPaymentStatusEnum.Pending && (
											<Checkbox
												border='black'
												isChecked={props.selectedRows.includes(body.id)}
												onChange={() => props.toggleRowSelection(body.id, body.payment_status)}
											/>
										)}
									</Td>
								)}
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO
									</Text>
									<div>{body?.seller_name}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS
									</Text>
									<div>
									{body?.seller_cns ? JSON.stringify(body?.seller_cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2'): ''}
									</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL
									</Text>
									<div>{body?.email}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS DE PAGAMENTO
									</Text>
									<Flex flexDirection='row' gap='5px' alignItems='center'>
										{paymentStatus[body.payment_status as GetBilletsPaymentStatusEnum].icon}
										{paymentStatus[body.payment_status as GetBilletsPaymentStatusEnum].text}
									</Flex>
								</Td>
								{isBackoffice && (
									<Td>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											STATUS DE ENVIO
										</Text>
										<Flex flexDirection='row' gap='5px' alignItems='center'>
											{shippingStatus[body.shipping_status as GetBilletsShippingStatusEnum].icon}
											{shippingStatus[body.shipping_status as GetBilletsShippingStatusEnum].text}
										</Flex>
									</Td>
								)}
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA DE VENCIMENTO
									</Text>
									<Flex flexDirection='row' alignItems='center'>
										{body.expires_at ? format(new Date(body.expires_at), 'dd/MM/yyyy') : ''}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR
									</Text>
									<Flex flexDirection='row' alignItems='center'>
										R$ {centsToRealWithComma(body.amount || 0)}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										BOLETO
									</Text>
									<Flex flexDirection='row' alignItems='center'>
										<ChakraButton fontSize='20px' as='a' href={body?.url} download variant='link' leftIcon={<Icon as={MdDownload} />} />
									</Flex>
								</Td>
								{isBackoffice && (
									<Td>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											REEMITIR
										</Text>
										{body.payment_status === GetBilletsPaymentStatusEnum.Expired ? (
											<Button
												variant='link'
												backgroundColor='transparent'
												color={defaultColors.primaryColor}
												onClick={() => {
													setSelectedBillet(body);
													onOpen();
												}}
											>
												<FaFileInvoice />
											</Button>
										) : (
											<Text>Indisponível</Text>
										)}
									</Td>
								)}
							</Tr>
							{isBackoffice && (
								<Flex>
									<ReissueModal
										isOpen={isOpen}
										onClose={() => {
											onClose();
											setSelectedBillet(null);
										}}
										billet={selectedBillet}
										setDataValues={props.setDataValues}
										dataValues={props.dataValues}
										fetchData={props.fetchData}
									/>
								</Flex>
							)}
						</Tbody>
					))}
				</Table>
				<Table variant='simple' size='md' display={['flex', 'flex', 'none']} flexDirection='column' w='100%'>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody
							w='100%'
							display='flex'
							alignItems='center'
							flexDirection='column'
							p='10px'
							bg={defaultColors.white}
							boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
							borderRadius='8px'
							border='2px solid rgba(0, 0, 0, 0.2)'
							mb='10px'
							_hover={{ background: defaultColors.white }}
						>
							<Tr w='100%' display='flex' flexDirection='column' alignItems='center' gap='12px'>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									{(body.payment_status === GetBilletsPaymentStatusEnum.Pending && isBackoffice) && (
										<>
											<Checkbox
												border='black'
												isChecked={props.selectedRows.includes(body.id)}
												onChange={() => props.toggleRowSelection(body.id, body.payment_status)}
											/>
											<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
												Selecionar
											</Text>
										</>
									)}
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO:
									</Text>
									<div>{body?.seller_name}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS:
									</Text>
									<div>
										{body?.seller_cns ? JSON.stringify(body?.seller_cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2'): ''}
									</div>
								</Flex>

								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL:
									</Text>
									<div>{body?.email}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS DE PAGAMENTO:
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{paymentStatus[body.payment_status as GetBilletsPaymentStatusEnum].icon}
										{paymentStatus[body.payment_status as GetBilletsPaymentStatusEnum].text}
									</Flex>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS DE ENVIO:
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{shippingStatus[body.shipping_status as GetBilletsShippingStatusEnum].icon}
										{shippingStatus[body.shipping_status as GetBilletsShippingStatusEnum].text}
									</Flex>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA DE VENCIMENTO:
									</Text>
									<div>{body.expires_at ? format(new Date(body.expires_at), 'dd/MM/yyyy') : ''}</div>
								</Flex>

								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR:
									</Text>
									<div>R$ {centsToRealWithComma(body.amount || 0)}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px' whiteSpace='normal'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										BOLETO:
									</Text>
									<ChakraButton fontSize='20px' as='a' href={body?.url} download variant='link' leftIcon={<Icon as={MdDownload} />} />
								</Flex>
								{isBackoffice && (
									<Flex flexDirection='row' alignItems='center' gap='8px' whiteSpace='normal'>
										<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
											REEMITIR:
										</Text>
										{body.payment_status === GetBilletsPaymentStatusEnum.Expired ? (
											<Button
												variant='link'
												backgroundColor='transparent'
												color={defaultColors.primaryColor}
												onClick={() => {
													setSelectedBillet(body);
													onOpen();
												}}
											>
												<FaFileInvoice />
											</Button>
										) : (
											<Text>Indisponível</Text>
										)}
									</Flex>
								)}
							</Tr>
						</Tbody>
					))}
				</Table>
			</TableContainer>
			{props?.body && props.body.length > 0 && (
				<Stack justifyContent='space-around' flexDirection='row'>
					<Stack flexDirection='row'>
						<ChakraButton
							variant='link'
							id='button__double-arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									props.setCurrentPage(1);
									const dataValues = { ...props.dataValues, currentPage: 1 };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowLeft size='20px' />
						</ChakraButton>
						<ChakraButton
							variant='link'
							id='button__arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									const previousPage = props.currentPage - 1;
									props.setCurrentPage(previousPage);
									const dataValues = { ...props.dataValues, currentPage: previousPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowLeft size='20px' />
						</ChakraButton>
					</Stack>
					<Stack>
						<Text>
							<Stack flexDirection='row' alignItems='center' justifyContent='space-around' spacing='40px'>
								<Stack>
									<Text>
										Página {props.billets?.current_page} de {props.billets?.total_pages}
									</Text>
								</Stack>
								<Stack flexDirection='row' alignItems='center' w='40%'>
									<Text>Ir para página: </Text>
									<NumberInput
										id='input__page-number'
										min={1}
										max={props.billets?.total_pages}
										w='50%'
										minW='70px'
										defaultValue={props.currentPage}
										value={props.currentPage}
										onChange={(e) => {
											const newPage = Number(e);
											props.setCurrentPage(newPage);
											if (props.billets && props.billets.total_pages && newPage >= 1 && newPage <= props.billets.total_pages) {
												const dataValues = { ...props.dataValues, currentPage: newPage };
												props.setDataValues(dataValues);
												props.fetchData(dataValues);
											}
										}}
									>
										<NumberInputField
											min={1}
											max={props.billets?.total_pages}
											border='2px solid black'
											borderRadius='15px'
											_disabled={{
												color: 'black',
											}}
										/>
										<NumberInputStepper w='30%'>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Stack>
								<Stack>
									<Select
										id='input_select'
										defaultValue={10}
										w='60%'
										minW='120px'
										border='2px solid black'
										borderRadius='15px'
										value={props.limit}
										onChange={(e) => {
											const selectedValue = Number(e.target.value);
											props.setLimit(selectedValue);
											const dataValues = { ...props.dataValues, limit: selectedValue, currentPage: 1 };
											props.setDataValues(dataValues);
											props.setCurrentPage(1);
											props.fetchData(dataValues);
										}}
									>
										<option value={10}>Ver 10</option>
										<option value={20}>Ver 20</option>
										<option value={30}>Ver 30</option>
										<option value={40}>Ver 40</option>
										<option value={50}>Ver 50</option>
										<option value={100}>Ver 100</option>
									</Select>
								</Stack>
							</Stack>
						</Text>
					</Stack>
					<Stack flexDirection='row'>
						<ChakraButton
							variant='link'
							color='black'
							id='button__arrow-right'
							onClick={() => {
								const totalPages = props?.billets?.total_pages || 1;
								if (props.currentPage < totalPages) {
									const nextPage = props.currentPage + 1;
									props.setCurrentPage(nextPage);
									const dataValues = { ...props.dataValues, currentPage: nextPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowRight size='20px' />
						</ChakraButton>
						<ChakraButton
							variant='link'
							color='black'
							id='button__double-arrow-right'
							onClick={() => {
								const totalPages = props?.billets?.total_pages || 1;
								if (props.currentPage < totalPages) {
									props.setCurrentPage(totalPages);
									const dataValues = { ...props.dataValues, currentPage: totalPages };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowRight size='20px' />
						</ChakraButton>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default FinancialTable;
