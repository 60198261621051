import React, { useRef, useState } from 'react';

import { Flex, Link, Spinner, Stack, useBreakpointValue } from '@chakra-ui/react';
import { pdfjs, Document, Page } from 'react-pdf';
import ReactToPrint from 'react-to-print';
import Button from './Button';
import Text from './Text';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PDFComponentProps = {
	url: string | undefined;
};

const PDFConsultVehicleHistory: React.FC<PDFComponentProps> = (props: PDFComponentProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [numPages, setNumPages] = useState();

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	const componentRef = useRef<HTMLDivElement>(null);
	const handlePrint = () => {
		if (componentRef.current) {
			componentRef.current.click();
		}
	};
	return (
		<Stack width='100%'>
			{props.url ? (
				<>
					<Stack flexDirection={['column', 'row']} justifyContent='space-evenly' alignItems='center' mt={[0, '20px']}>
						<Flex>
							<Link href={props.url} isExternal>
								<Button>Baixar Documento</Button>
							</Link>
						</Flex>
						<Flex>
							<ReactToPrint
								content={() => componentRef.current}
								trigger={() => (
									<Button id='button__print' onClick={handlePrint}>
										Imprimir Documento
									</Button>
								)}
							/>
						</Flex>
					</Stack>
					<Stack w='100%' alignItems='center'>
						<Flex ref={componentRef}>
							<Document
								file={props.url}
								onLoadSuccess={onDocumentLoadSuccess}
								loading='Carregando Consulta Veicular...'
								noData='Consulta indisponível.'
								error='Consulta indisponível.'
							>
								{Array.apply(null, Array(numPages))
									.map((x, i) => i + 1)
									.map((page) => {
										return isMobile ? (
											<Page scale={1} width={400} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
										) : (
											<Page scale={1} width={1000} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
										);
									})}
							</Document>
						</Flex>
					</Stack>
				</>
			) : (
				<Stack justify='center' align='center' height='76vh' w='100%'>
					<Spinner />
					<Text textAlign='center'>Gerando consulta veicular. Aguarde, a consulta pode demorar alguns minutos.</Text>
				</Stack>
			)}
		</Stack>
	);
};

export default PDFConsultVehicleHistory;
