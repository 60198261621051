import React from 'react';

import { Link, Stack } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import { GetReportDto, GetVehicleHistoryPDFV2TypeEnum, VehiclesApi } from '../clients';

import { getApiDefaultConfig } from '../services/api.service';

import Button from './Button';
import fileDownload from 'js-file-download';

type FreeVehicleHistoryDownloadButtonProps = {
	report: GetReportDto | undefined;
};

export const FreeVehicleHistoryDownloadButton: React.FC<FreeVehicleHistoryDownloadButtonProps> = (
	props: FreeVehicleHistoryDownloadButtonProps
) => {
	const apiConfig = getApiDefaultConfig();
	const vehiclesApi = new VehiclesApi(apiConfig);
	const { addToast } = useToasts();

	const [loading, setLoading] = React.useState<boolean>(false);

	const freeConsultVehiclePDF = async () => {
		setLoading(true);
		const reportId = props.report?.id
		try {
			if (reportId) {
				const type = GetVehicleHistoryPDFV2TypeEnum.Pexbasica;
				const response: any = await vehiclesApi.getVehicleHistoryPDFV2({ reportId , type });
				fileDownload(response, `Consulta Veicular ${props.report?.license_plate}.pdf`);
			}
		} catch (error) {
			addToast('Erro ao realizar a consulta do histórico veicular.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	};
	return (
		<Stack w='100%' alignItems='center' mb='10px'>
			<Link onClick={freeConsultVehiclePDF} isExternal={true}>
				<Button isLoading={loading}>Dados Complementares do Veículo</Button>
			</Link>
		</Stack>
	);
};