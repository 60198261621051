export const cpfCnpjMask = (value: string) => {
  if (!!value) {
    const cleanedValue = value.replace(/\D/g, '');
    const isCpf = cleanedValue.length <= 11;

    if (isCpf) {
      return cleanedValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2');
    } else {
      return cleanedValue
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2');
    }
  }
};

export const cpfCnpjValidator = (cpfCnpj: string) => {
  if (cpfCnpj.length !== 11 && cpfCnpj.length !== 14) return false;
  if (cpfCnpj.length === 11) {
    var sum;
    var rest;
    sum = 0;

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpfCnpj.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpfCnpj.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpfCnpj.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpfCnpj.substring(10, 11))) return false;
    return true;
  }
  if (cpfCnpj.length === 14) {
    let length = cpfCnpj.length - 2;
    let numbers = cpfCnpj.substring(0, length);
    let digits = cpfCnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += parseInt(numbers.charAt(length - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    const result1 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result1 !== parseInt(digits.charAt(0))) return false;

    length = length + 1;
    numbers = cpfCnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += parseInt(numbers.charAt(length - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    const result2 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    return result2 === parseInt(digits.charAt(1));
  }
};
