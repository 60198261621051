/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSummaryByDateResponseDto
 */
export interface GetSummaryByDateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_invoiced: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_atpve: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_atpve_invoiced: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_crv: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_crv_invoiced: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_crv_value: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_atpve_value: number;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryByDateResponseDto
     */
    total_value: number;
}

/**
 * Check if a given object implements the GetSummaryByDateResponseDto interface.
 */
export function instanceOfGetSummaryByDateResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "total_invoiced" in value;
    isInstance = isInstance && "total_atpve" in value;
    isInstance = isInstance && "total_atpve_invoiced" in value;
    isInstance = isInstance && "total_crv" in value;
    isInstance = isInstance && "total_crv_invoiced" in value;
    isInstance = isInstance && "total_crv_value" in value;
    isInstance = isInstance && "total_atpve_value" in value;
    isInstance = isInstance && "total_value" in value;

    return isInstance;
}

export function GetSummaryByDateResponseDtoFromJSON(json: any): GetSummaryByDateResponseDto {
    return GetSummaryByDateResponseDtoFromJSONTyped(json, false);
}

export function GetSummaryByDateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSummaryByDateResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'total_invoiced': json['total_invoiced'],
        'total_atpve': json['total_atpve'],
        'total_atpve_invoiced': json['total_atpve_invoiced'],
        'total_crv': json['total_crv'],
        'total_crv_invoiced': json['total_crv_invoiced'],
        'total_crv_value': json['total_crv_value'],
        'total_atpve_value': json['total_atpve_value'],
        'total_value': json['total_value'],
    };
}

export function GetSummaryByDateResponseDtoToJSON(value?: GetSummaryByDateResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'total_invoiced': value.total_invoiced,
        'total_atpve': value.total_atpve,
        'total_atpve_invoiced': value.total_atpve_invoiced,
        'total_crv': value.total_crv,
        'total_crv_invoiced': value.total_crv_invoiced,
        'total_crv_value': value.total_crv_value,
        'total_atpve_value': value.total_atpve_value,
        'total_value': value.total_value,
    };
}

