/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOwnerDto
 */
export interface GetOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof GetOwnerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOwnerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetOwnerDto
     */
    documentType: string;
    /**
     * 
     * @type {string}
     * @memberof GetOwnerDto
     */
    licensePlate: string;
}

/**
 * Check if a given object implements the GetOwnerDto interface.
 */
export function instanceOfGetOwnerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "document" in value;
    isInstance = isInstance && "documentType" in value;
    isInstance = isInstance && "licensePlate" in value;

    return isInstance;
}

export function GetOwnerDtoFromJSON(json: any): GetOwnerDto {
    return GetOwnerDtoFromJSONTyped(json, false);
}

export function GetOwnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOwnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'document': json['document'],
        'documentType': json['documentType'],
        'licensePlate': json['licensePlate'],
    };
}

export function GetOwnerDtoToJSON(value?: GetOwnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'document': value.document,
        'documentType': value.documentType,
        'licensePlate': value.licensePlate,
    };
}

