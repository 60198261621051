/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchBatch } from './SearchBatch';
import {
    SearchBatchFromJSON,
    SearchBatchFromJSONTyped,
    SearchBatchToJSON,
} from './SearchBatch';

/**
 * 
 * @export
 * @interface GetBatchesDto
 */
export interface GetBatchesDto {
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    current_page: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    total_pages: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchesDto
     */
    total_items: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBatchesDto
     */
    has_more: boolean;
    /**
     * 
     * @type {Array<SearchBatch>}
     * @memberof GetBatchesDto
     */
    results: Array<SearchBatch>;
}

/**
 * Check if a given object implements the GetBatchesDto interface.
 */
export function instanceOfGetBatchesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "current_page" in value;
    isInstance = isInstance && "total_pages" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "total_items" in value;
    isInstance = isInstance && "has_more" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function GetBatchesDtoFromJSON(json: any): GetBatchesDto {
    return GetBatchesDtoFromJSONTyped(json, false);
}

export function GetBatchesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBatchesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_page': json['current_page'],
        'total_pages': json['total_pages'],
        'limit': json['limit'],
        'start': json['start'],
        'end': json['end'],
        'total_items': json['total_items'],
        'has_more': json['has_more'],
        'results': ((json['results'] as Array<any>).map(SearchBatchFromJSON)),
    };
}

export function GetBatchesDtoToJSON(value?: GetBatchesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_page': value.current_page,
        'total_pages': value.total_pages,
        'limit': value.limit,
        'start': value.start,
        'end': value.end,
        'total_items': value.total_items,
        'has_more': value.has_more,
        'results': ((value.results as Array<any>).map(SearchBatchToJSON)),
    };
}

