import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/Home';
import { Box, Icon, Stack } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';

import './assets/css/index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AuthProvider } from './contexts/AuthProvider';
import { ToastProps, ToastProvider } from 'react-toast-notifications';
import './index.css'
import Text from './components/Text';
import { RefreshTokenProvider } from './contexts/RefreshTokenProvider';
import { IconType } from 'react-icons';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { AiFillClockCircle } from 'react-icons/ai';

enum toastTypes {
	ERROR = 'error',
	WARNING = 'warning',
	SUCCESS = 'success'
}

const CustomToast = ({ appearance, children }: ToastProps) => {
	const getToastParams = (appearance: toastTypes): {
		className: string,
		backgroundColor: string,
		icon: IconType
	} => {
		const appearances = {
			[toastTypes.ERROR] : { className: 'toast__error', backgroundColor: '#E85642', icon: MdError },
			[toastTypes.WARNING]: { className: 'toast__warning', backgroundColor: '#FFA500', icon: AiFillClockCircle },
			[toastTypes.SUCCESS]: { className: 'toast__success', backgroundColor: '#07C50E', icon: FaCheck },
		};

		return appearances[appearance];
	}

	return (
		<Box
			className={getToastParams(appearance as toastTypes).className}
			rounded='xl'
			bg={getToastParams(appearance as toastTypes).backgroundColor}
			py={8}
			px={15}
		>
			<Stack isInline spacing={12} alignItems='center'>
				<Icon as={getToastParams(appearance as toastTypes).icon} boxSize={22} color='white' />
				<Text color='white' fontSize='xs' fontWeight='bold'>
					{children}
				</Text>
			</Stack>
		</Box>
	);
}

const pubNub = new PubNub({
	publishKey: `${process.env.REACT_APP_PUBNUB_PUBLISH_KEY}`,
	subscribeKey: `${process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY}`,
	uuid: `${process.env.REACT_APP_PUBNUB_UUID}`
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(

		<PubNubProvider client={pubNub}>
			<ToastProvider components={{ Toast: CustomToast }}>
				<AuthProvider>
					<RefreshTokenProvider>
						<App />
					</RefreshTokenProvider>
				</AuthProvider>
			</ToastProvider>
		</PubNubProvider>

);
