import * as React from 'react';

import { useParams } from 'react-router-dom';

import { GetSimulationDto, PayerDto } from '../../../clients';

interface ConsultVehicleHistoryContextData {
	token: string | undefined;
	simulation: GetSimulationDto[];
	setSimulation: React.Dispatch<React.SetStateAction<GetSimulationDto[]>>;
	linkPaid: boolean;
	setLinkPaid: React.Dispatch<React.SetStateAction<boolean>>;
	showDocument: boolean;
	setShowDocument: React.Dispatch<React.SetStateAction<boolean>>;
	payer: PayerDto | undefined;
	setPayer: React.Dispatch<React.SetStateAction<PayerDto | undefined>>;
}

const ConsultVehicleHistoryContext = React.createContext<ConsultVehicleHistoryContextData>({} as ConsultVehicleHistoryContextData);

type ConsultVehicleHistoryProviderProps = {
	children: React.ReactElement;
};

export const ConsultVehicleHistoryProvider: React.FC<ConsultVehicleHistoryProviderProps> = ({ children }) => {
	let { token } = useParams();
	const [simulation, setSimulation] = React.useState<GetSimulationDto[]>([]);
	const [linkPaid, setLinkPaid] = React.useState<boolean>(false);
	const [showDocument, setShowDocument] = React.useState<boolean>(false);
	const [payer, setPayer] = React.useState<PayerDto>();

	return (
		<ConsultVehicleHistoryContext.Provider
			value={{ token, simulation, setSimulation, linkPaid, setLinkPaid, showDocument, setShowDocument, payer, setPayer }}
		>
			{children}
		</ConsultVehicleHistoryContext.Provider>
	);
};

export function useConsultVehicleHistory() {
	const context = React.useContext(ConsultVehicleHistoryContext);
	return context;
}
