/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPaginatedBilletsDto,
  GetPaginatedUngeneratedBilletsDto,
  ReissueExpiredBilletDto,
  SendBilletEmailDto,
} from '../models/index';
import {
    GetPaginatedBilletsDtoFromJSON,
    GetPaginatedBilletsDtoToJSON,
    GetPaginatedUngeneratedBilletsDtoFromJSON,
    GetPaginatedUngeneratedBilletsDtoToJSON,
    ReissueExpiredBilletDtoFromJSON,
    ReissueExpiredBilletDtoToJSON,
    SendBilletEmailDtoFromJSON,
    SendBilletEmailDtoToJSON,
} from '../models/index';

export interface GetBilletsRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    sellerIds?: string;
    paymentStatus?: GetBilletsPaymentStatusEnum;
    shippingStatus?: GetBilletsShippingStatusEnum;
}

export interface GetUngeneratedBilletsRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    sellerIds?: string;
}

export interface ReissueExpiredBilletRequest {
    billetId: string;
    reissueExpiredBilletDto: ReissueExpiredBilletDto;
}

export interface SendBilletEmailRequest {
    sendBilletEmailDto: SendBilletEmailDto;
}

/**
 * 
 */
export class BilletsApi extends runtime.BaseAPI {

    /**
     * Gerar boletos para todos sellers
     */
    async generateBilletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/generate-billets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gerar boletos para todos sellers
     */
    async generateBillets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateBilletsRaw(initOverrides);
    }

    /**
     * Buscar boletos gerados
     */
    async getBilletsRaw(requestParameters: GetBilletsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaginatedBilletsDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBillets.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getBillets.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBillets.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getBillets.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        if (requestParameters.paymentStatus !== undefined) {
            queryParameters['payment_status'] = requestParameters.paymentStatus;
        }

        if (requestParameters.shippingStatus !== undefined) {
            queryParameters['shipping_status'] = requestParameters.shippingStatus;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaginatedBilletsDtoFromJSON(jsonValue));
    }

    /**
     * Buscar boletos gerados
     */
    async getBillets(requestParameters: GetBilletsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaginatedBilletsDto> {
        const response = await this.getBilletsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar sellers sem boletos gerados.
     */
    async getUngeneratedBilletsRaw(requestParameters: GetUngeneratedBilletsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaginatedUngeneratedBilletsDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getUngeneratedBillets.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getUngeneratedBillets.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getUngeneratedBillets.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getUngeneratedBillets.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/ungenerated-billets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaginatedUngeneratedBilletsDtoFromJSON(jsonValue));
    }

    /**
     * Buscar sellers sem boletos gerados.
     */
    async getUngeneratedBillets(requestParameters: GetUngeneratedBilletsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaginatedUngeneratedBilletsDto> {
        const response = await this.getUngeneratedBilletsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reemitir boleto expirado
     */
    async reissueExpiredBilletRaw(requestParameters: ReissueExpiredBilletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billetId === null || requestParameters.billetId === undefined) {
            throw new runtime.RequiredError('billetId','Required parameter requestParameters.billetId was null or undefined when calling reissueExpiredBillet.');
        }

        if (requestParameters.reissueExpiredBilletDto === null || requestParameters.reissueExpiredBilletDto === undefined) {
            throw new runtime.RequiredError('reissueExpiredBilletDto','Required parameter requestParameters.reissueExpiredBilletDto was null or undefined when calling reissueExpiredBillet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/reissue-billet/{billetId}`.replace(`{${"billetId"}}`, encodeURIComponent(String(requestParameters.billetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReissueExpiredBilletDtoToJSON(requestParameters.reissueExpiredBilletDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reemitir boleto expirado
     */
    async reissueExpiredBillet(requestParameters: ReissueExpiredBilletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reissueExpiredBilletRaw(requestParameters, initOverrides);
    }

    /**
     * Enviar email para estabelecimentos
     */
    async sendBilletEmailRaw(requestParameters: SendBilletEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sendBilletEmailDto === null || requestParameters.sendBilletEmailDto === undefined) {
            throw new runtime.RequiredError('sendBilletEmailDto','Required parameter requestParameters.sendBilletEmailDto was null or undefined when calling sendBilletEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/send-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendBilletEmailDtoToJSON(requestParameters.sendBilletEmailDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enviar email para estabelecimentos
     */
    async sendBilletEmail(requestParameters: SendBilletEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendBilletEmailRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetBilletsPaymentStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    Expired: 'expired'
} as const;
export type GetBilletsPaymentStatusEnum = typeof GetBilletsPaymentStatusEnum[keyof typeof GetBilletsPaymentStatusEnum];
/**
 * @export
 */
export const GetBilletsShippingStatusEnum = {
    Pending: 'pending',
    Received: 'received',
    Failed: 'failed'
} as const;
export type GetBilletsShippingStatusEnum = typeof GetBilletsShippingStatusEnum[keyof typeof GetBilletsShippingStatusEnum];
