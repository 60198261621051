import React from 'react';

import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';

import Modal from '../../../containers/Modal';

import { getApiDefaultConfig } from '../../../services/api.service';

import { AuthApi, ForgotPasswordRequestRequest } from '../../../clients';

import { defaultColors } from '../../../config/variables';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';

interface ForgotPasswordModalTypes {
	setForgotPasswordIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	forgotPasswordIsOpen: boolean;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalTypes> = (props: ForgotPasswordModalTypes) => {
	const { addToast } = useToasts();
	const apiConfig = getApiDefaultConfig();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const initialValues = { email: '' };

	const schema = yup.object().shape({
		email: yup.string().required('Campo obrigatório'),
	});

	const handleFormSubmit = async (e: any) => {
		setIsLoading(true);
		const { email } = e;

		try {
			const authApi = new AuthApi(apiConfig);

			const params: ForgotPasswordRequestRequest = {
				forgotPasswordRequestDto: {
					email,
				},
			};

			await authApi.forgotPasswordRequest(params);

			addToast('E-mail enviado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao solicitar', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			props.setForgotPasswordIsOpen(false);
			setIsLoading(false);
		}
	};

	return (
		<Modal isOpen={props.forgotPasswordIsOpen} onClose={() => props.setForgotPasswordIsOpen(false)}>
			<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Flex padding='20px' flexDirection='column'>
								<Flex alignItems='end'>
									<FormControl>
										<FormLabel>Informe o e-mail:</FormLabel>
										<Input id='forgot_password_input' name='email' isReadOnly={false} fontSize='lg' />
									</FormControl>
									<Button isLoading={isLoading} type='submit' ml='20px' border={`2px solid ${defaultColors.secondaryColor}`}>
										Enviar
									</Button>
								</Flex>

								<FormErrorMessage name='email' />
							</Flex>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default ForgotPasswordModal;
