/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ForgotPasswordRequestDto,
  GetForgotPasswordRequestDto,
  ResetPasswordRequestDto,
  UserLoginDto,
  UserLoginResponseDto,
  UserRefreshTokenDto,
  UserRefreshTokenResponseDto,
} from '../models/index';
import {
    ForgotPasswordRequestDtoFromJSON,
    ForgotPasswordRequestDtoToJSON,
    GetForgotPasswordRequestDtoFromJSON,
    GetForgotPasswordRequestDtoToJSON,
    ResetPasswordRequestDtoFromJSON,
    ResetPasswordRequestDtoToJSON,
    UserLoginDtoFromJSON,
    UserLoginDtoToJSON,
    UserLoginResponseDtoFromJSON,
    UserLoginResponseDtoToJSON,
    UserRefreshTokenDtoFromJSON,
    UserRefreshTokenDtoToJSON,
    UserRefreshTokenResponseDtoFromJSON,
    UserRefreshTokenResponseDtoToJSON,
} from '../models/index';

export interface ForgotPasswordRequestRequest {
    forgotPasswordRequestDto: ForgotPasswordRequestDto;
}

export interface GetForgotPasswordRequestDataRequest {
    token: string;
}

export interface LoginRequest {
    userLoginDto: UserLoginDto;
}

export interface RefreshTokenRequest {
    userRefreshTokenDto: UserRefreshTokenDto;
}

export interface ResetPasswordRequestRequest {
    resetPasswordRequestDto: ResetPasswordRequestDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Solicitar alteração de senha
     */
    async forgotPasswordRequestRaw(requestParameters: ForgotPasswordRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forgotPasswordRequestDto === null || requestParameters.forgotPasswordRequestDto === undefined) {
            throw new runtime.RequiredError('forgotPasswordRequestDto','Required parameter requestParameters.forgotPasswordRequestDto was null or undefined when calling forgotPasswordRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestDtoToJSON(requestParameters.forgotPasswordRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Solicitar alteração de senha
     */
    async forgotPasswordRequest(requestParameters: ForgotPasswordRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Validar token enviado no esqueci minha senha
     */
    async getForgotPasswordRequestDataRaw(requestParameters: GetForgotPasswordRequestDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetForgotPasswordRequestDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getForgotPasswordRequestData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/auth/forgot-password/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetForgotPasswordRequestDtoFromJSON(jsonValue));
    }

    /**
     * Validar token enviado no esqueci minha senha
     */
    async getForgotPasswordRequestData(requestParameters: GetForgotPasswordRequestDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetForgotPasswordRequestDto> {
        const response = await this.getForgotPasswordRequestDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Realizar autenticação na aplicação
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserLoginResponseDto>> {
        if (requestParameters.userLoginDto === null || requestParameters.userLoginDto === undefined) {
            throw new runtime.RequiredError('userLoginDto','Required parameter requestParameters.userLoginDto was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginDtoToJSON(requestParameters.userLoginDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseDtoFromJSON(jsonValue));
    }

    /**
     * Realizar autenticação na aplicação
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserLoginResponseDto> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserRefreshTokenResponseDto>> {
        if (requestParameters.userRefreshTokenDto === null || requestParameters.userRefreshTokenDto === undefined) {
            throw new runtime.RequiredError('userRefreshTokenDto','Required parameter requestParameters.userRefreshTokenDto was null or undefined when calling refreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRefreshTokenDtoToJSON(requestParameters.userRefreshTokenDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRefreshTokenResponseDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshToken(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserRefreshTokenResponseDto> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Realizar alteração de senha
     */
    async resetPasswordRequestRaw(requestParameters: ResetPasswordRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordRequestDto === null || requestParameters.resetPasswordRequestDto === undefined) {
            throw new runtime.RequiredError('resetPasswordRequestDto','Required parameter requestParameters.resetPasswordRequestDto was null or undefined when calling resetPasswordRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestDtoToJSON(requestParameters.resetPasswordRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Realizar alteração de senha
     */
    async resetPasswordRequest(requestParameters: ResetPasswordRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRequestRaw(requestParameters, initOverrides);
    }

}
