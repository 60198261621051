import React from 'react';

import { Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useDebounce } from '../../../services/useDebounce.service';

interface ConfirmModalTypes {
	onClose: () => void;
	isOpen: boolean;
	handleModalSubmit: () => Promise<void>;
}

const ConfirmModal: React.FC<ConfirmModalTypes> = (props: ConfirmModalTypes) => {
	const [isAcceptLoading, setIsAcceptLoading] = React.useState<boolean>(false);

	const handleDecline = () => {
		props.onClose();
	};

	const handleAccept = useDebounce(() => {
		props.handleModalSubmit();
		props.onClose();
		setIsAcceptLoading(false);
	}, 300);

	return (
		<Modal isOpen={props.isOpen} onClose={handleDecline} closeOnOverlayClick={false} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<Flex padding='20px' flexDirection='column'>
					<Title textAlign='center' fontSize='2xl' borderBottom='1px solid grey'>
						Confirmar Cadastro de Cartórios
					</Title>
					<Flex mt='15px'>
						<Text textAlign='justify'>Os cartórios do arquivo serão cadastrados. Deseja continuar?</Text>
					</Flex>
					<Flex my='25px' justifyContent='space-around'>
						<Button type='submit' bgColor='red' onClick={handleDecline}>
							Não
						</Button>
						<Button
							isLoading={isAcceptLoading}
							type='submit'
							onClick={() => {
								setIsAcceptLoading(true);
								handleAccept();
							}}
						>
							Sim
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmModal;
