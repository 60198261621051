import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Grid, IconButton, Select, useOutsideClick } from '@chakra-ui/react';
import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import { defaultColors } from '../config/variables';

type MonthYearPickerProps = {
	setDates: React.Dispatch<React.SetStateAction<any>>;
};

const MonthYearPicker: React.FC<MonthYearPickerProps> = ({ setDates }) => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [showMonths, setShowMonths] = useState<boolean>(false);
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const [years, setYears] = useState<number[]>([]);
	const months = Array.from(Array(12).keys());

	const ref = useRef<HTMLDivElement>(null);
	useOutsideClick({
		ref,
		handler: () => setShowMonths(false),
	});

	useEffect(() => {
		generateYears(selectedYear);
	}, [selectedYear]);

	useEffect(() => {
		if (selectedDate) {
			const firstDay = startOfDay(startOfMonth(selectedDate));
			const lastDay = startOfDay(endOfMonth(selectedDate));
			setDates({ start_date: firstDay, end_date: lastDay });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	const generateYears = (centerYear: number) => {
		const newYearStart = centerYear - 5;
		const newYearEnd = centerYear + 5;
		const yearsArray: number[] = [];

		for (let year = newYearStart; year <= newYearEnd; year++) {
			yearsArray.push(year);
		}
		setYears(yearsArray);
	};

	const nextYear = () => {
		setSelectedYear((prev) => prev + 1);
	};

	const previousYear = () => {
		setSelectedYear((prev) => prev - 1);
	};

	const handleMonthClick = (month: number) => {
		setSelectedDate(new Date(selectedYear, month - 1));
		setSelectedMonth(month);
		setShowMonths(false);
	};

	const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedYear(Number(event.target.value));
	};

	return (
		<Box ref={ref} position='relative' width='fit-content'>
			<Button onClick={() => setShowMonths((prev) => !prev)} bgColor={defaultColors.primaryColor} color='white'>
				{selectedDate ? format(selectedDate, 'MMMM / yyyy', { locale: ptBR }).replace(/^\w/, (c) => c.toUpperCase()) : 'Selecionar Mês/Ano'}
			</Button>

			{showMonths && (
				<Box position='absolute' zIndex={1} borderRadius='md' backgroundColor='white' p={4} border='1px solid #ccc' mt={2} width='350px'>
					<Box mb={4} display='flex' alignItems='center' justifyContent='space-between'>
						<IconButton icon={<ChevronLeftIcon />} aria-label='Ano anterior' onClick={previousYear} bgColor={defaultColors.white} />
						<Select value={selectedYear} onChange={handleYearChange} width='auto' textAlign='center' border='none' fontSize='lg'>
							{years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
						</Select>
						<IconButton icon={<ChevronRightIcon />} aria-label='Próximo ano' onClick={nextYear} bgColor={defaultColors.white} />
					</Box>

					<Grid templateColumns='repeat(3, 1fr)' gap={4}>
						{months.map((_month, i) => (
							<Button
								key={i}
								onClick={() => handleMonthClick(i + 1)}
								bgColor={selectedMonth === i + 1 ? defaultColors.primaryColor : defaultColors.white}
								color={selectedMonth === i + 1 ? defaultColors.white : 'black'}
								variant={selectedMonth === i + 1 ? 'solid' : 'outline'}
								width='100%'
								height='50px'
								display='flex'
								alignItems='center'
								justifyContent='center'
								border='1px solid #ccc'
							>
								{format(new Date(0, i), 'MMMM', { locale: ptBR }).replace(/^\w/, (c) => c.toUpperCase())}
							</Button>
						))}
					</Grid>
				</Box>
			)}
		</Box>
	);
};

export default MonthYearPicker;
