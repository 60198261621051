import React from 'react';

import { CopyIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/layout';
import { Button, Icon, Skeleton } from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';

import { CreatePaymentQrCodeRequest, CreatePixPaymentResponseDTO, PaymentsApi, PixPayConsultDto } from '../../../clients';

import { getApiDefaultConfig } from '../../../services/api.service';
import { RealTimeChannel } from '../../../services/real-time.service';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';

import { useConsultVehicleHistory } from '../context/ConsultVehicleHistoryProvider';

import { PaymentForm } from '../types/paymentForm.type';

import { SimulationType } from '../../../enums/SimulationType.enum';
import { PixPaymentStatus } from '../../../enums/PixPaymentStatus.enum';

type QrCodeProps = {
	formValues: PaymentForm;
	tabIndex: number;
};

const QrCode: React.FC<QrCodeProps> = (props: QrCodeProps) => {
	const { token, simulation, setLinkPaid, setShowDocument } = useConsultVehicleHistory();
	const { addToast } = useToasts();
	const apiConfig = getApiDefaultConfig();
	const paymentsApi = new PaymentsApi(apiConfig);
	const pubNub = usePubNub();

	const indexPixSimulation = simulation.findIndex((simulation) => simulation.type === SimulationType.Pix);
	const pixSimulation = simulation[indexPixSimulation];
	const pixOriginalCost = centsToRealWithComma(pixSimulation?.original_amount_cents);
	const pixFee = centsToRealWithComma(pixSimulation?.total_amount_cents - pixSimulation?.original_amount_cents);
	const pixTotalCost = centsToRealWithComma(pixSimulation?.total_amount_cents);
	const payerName = props.formValues.name.split(' ');
	const [paymentResponse, setPaymentResponse] = React.useState<CreatePixPaymentResponseDTO>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const verifyIfPixPaymentWasPaid = () => {
		pubNub.addListener({
			message(event) {
				const { message, channel } = event;

				if (channel === RealTimeChannel.PAYMENT) {
					const { payment_update } = message;
					const { sale_id, status } = payment_update;

					if (paymentResponse?.saleId === sale_id && status === PixPaymentStatus.Paid) {
						addToast('Pagamento realizado com sucesso!', {
							appearance: 'success',
							autoDismiss: true,
						});

						setLinkPaid(true);
						setShowDocument(true);
					}
				}
			},
		});

		pubNub.subscribe({ channels: [RealTimeChannel.PAYMENT] });
	};

	const createQrcodePayment = async () => {
		if (token) {
			setIsLoading(true);
			const pixPayConsultDto: PixPayConsultDto = {
				token,
				amount_cents: pixSimulation?.total_amount_cents,
				installments: 1,
				payer: {
					address: {
						city: props.formValues.city,
						complement: props.formValues.complement ?? '',
						district: props.formValues.district,
						number: props.formValues.number,
						state: props.formValues.state,
						street: props.formValues.street,
						zipcode: props.formValues.zipcode,
					},
					document: props.formValues.document,
					email: props.formValues.email,
					first_name: payerName[0],
					last_name: payerName[payerName.length - 1],
				},
			};
			const parsedPayload: CreatePaymentQrCodeRequest = {
				pixPayConsultDto,
			};

			try {
				const response = await paymentsApi.createPaymentQrCode(parsedPayload);

				setPaymentResponse(response);
				setIsLoading(false);
			} catch (err) {
				addToast('Falha ao criar QrCode! Verifique os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
	};

	React.useEffect(() => {
		if (props.tabIndex === 1) {
			createQrcodePayment();
		}
		// eslint-disable-next-line
	}, [props.tabIndex]);

	React.useEffect(() => {
		verifyIfPixPaymentWasPaid();
		// eslint-disable-next-line
	}, [paymentResponse, pubNub]);

	return (
		<Stack flexDir='column' w='100%'>
			{!isLoading ? (
				<>
					<Stack direction='column' mt='16px' alignItems='center'>
						<Stack direction='column' fontSize='2sm' bgColor='gray.200' rounded='md' p='16px'>
							<Flex justifyContent='space-between' gap='40px'>
								<Text>Valor original do serviço</Text>

								<Text>R$ {pixOriginalCost}</Text>
							</Flex>
							<Flex justifyContent='space-between' gap='40px'>
								<Text>* Custo financeiro da operação:</Text>

								<Text>R$ {pixFee}</Text>
							</Flex>

							<Box bgColor='gray.400' height='0.063rem' />

							<Flex justifyContent='space-between' gap='40px'>
								<Text>Valor a ser pago com acréscimo</Text>

								<Text>R$ {pixTotalCost}</Text>
							</Flex>
						</Stack>
					</Stack>

					<Flex direction='column' alignItems='center' my='16px'></Flex>

					<>
						<Flex justifyContent='center' align='center' className='data__qrcode'>
							<Flex>
								<QRCode size={200} value={paymentResponse?.qrCodeData ?? ''} />
							</Flex>
						</Flex>
						<HStack align='center' justify='center' my='32px'>
							<CopyToClipboard
								text={''}
								onCopy={() => {
									addToast('Link copiado com sucesso', {
										appearance: 'success',
										autoDismiss: true,
									});
								}}
							>
								<Button>
									<HStack>
										<Icon w='4' as={CopyIcon} />
										<Text fontWeight='smibold'>Copiar código do pagamento</Text>
									</HStack>
								</Button>
							</CopyToClipboard>
						</HStack>
					</>
				</>
			) : (
				<>
					<Text color={`primary`}>A geração do código pode levar alguns segundos</Text>
					<Box pt='4px' mb='16px'>
						<Flex justifyContent='center' align='center'>
							<Flex>
								<Skeleton height={200} variant='rect' width={200} />
							</Flex>
						</Flex>
						<Skeleton height={25} variant='text' width='30%' mt='16px' />
					</Box>
				</>
			)}
		</Stack>
	);
};

export default QrCode;
