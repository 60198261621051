/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDebts
 */
export interface GetDebts {
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    digitable: string;
    /**
     * 
     * @type {number}
     * @memberof GetDebts
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetDebts
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof GetDebts
     */
    fees: number;
    /**
     * 
     * @type {number}
     * @memberof GetDebts
     */
    penalty: number;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    due_date: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    original_due_date: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebts
     */
    status: GetDebtsStatusEnum;
}


/**
 * @export
 */
export const GetDebtsStatusEnum = {
    Opened: 'opened',
    Pending: 'pending',
    Succeeded: 'succeeded',
    Failed: 'failed'
} as const;
export type GetDebtsStatusEnum = typeof GetDebtsStatusEnum[keyof typeof GetDebtsStatusEnum];


/**
 * Check if a given object implements the GetDebts interface.
 */
export function instanceOfGetDebts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "barcode" in value;
    isInstance = isInstance && "digitable" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "subtotal" in value;
    isInstance = isInstance && "fees" in value;
    isInstance = isInstance && "penalty" in value;
    isInstance = isInstance && "due_date" in value;
    isInstance = isInstance && "original_due_date" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetDebtsFromJSON(json: any): GetDebts {
    return GetDebtsFromJSONTyped(json, false);
}

export function GetDebtsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDebts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcode': json['barcode'],
        'digitable': json['digitable'],
        'total': json['total'],
        'subtotal': json['subtotal'],
        'fees': json['fees'],
        'penalty': json['penalty'],
        'due_date': json['due_date'],
        'original_due_date': json['original_due_date'],
        'description': json['description'],
        'status': json['status'],
    };
}

export function GetDebtsToJSON(value?: GetDebts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'digitable': value.digitable,
        'total': value.total,
        'subtotal': value.subtotal,
        'fees': value.fees,
        'penalty': value.penalty,
        'due_date': value.due_date,
        'original_due_date': value.original_due_date,
        'description': value.description,
        'status': value.status,
    };
}

