import React from 'react';

import { Flex, Icon, Image, InputGroup, InputRightElement, Stack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import './index.css';

import MfaModal from '../../containers/mfaModal/MfaModal';

import { useAuth } from '../../contexts/AuthProvider';

import { defaultColors } from '../../config/variables';

import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import Input from '../../components/Input';
import Text from '../../components/Text';
import ForgotPasswordModal from './components/ForgotPasswordModal';

const Login: React.FC = () => {
	const [show, setShow] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [mfaOpen, setMfaOpen] = React.useState<boolean>(false);
	const [forgotPasswordIsOpen, setForgotPasswordIsOpen] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<string>('');

	const navigate = useNavigate();
	const { login } = useAuth();
	const { addToast } = useToasts();

	const handleClick = () => setShow(!show);

	const initialValues = {
		email: '',
		password: '',
	};

	const schema = yup.object().shape({
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		password: yup.string().required('Campo obrigatório'),
	});

	async function readStream(reader: any) {
		let data = '';
		try {
			while (true) {
				const { value, done } = await reader.read();
				if (done) {
					break;
				}
				data += new TextDecoder().decode(value);
			}
		} catch (error) {
		}
		return data;
	}

	const handleFormSubmit = async (values: any) => {
		setIsLoading(true);

		try {
			await login(values);

			navigate('/admin');
		} catch (error: any) {
			const reader = error.response.body.getReader();

			const response = await readStream(reader);
			const responseJson = JSON.parse(response);

			if (responseJson.message === 'Usuário pendente de ativação') {
				setUser(responseJson.additionalData.userId);
				setMfaOpen(true);
			} else {
				addToast('E-mail ou senha inválidos', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Stack
			direction={['column', 'column', 'row']}
			width='100%'
			justifyContent={['center', 'center', 'space-between']}
			justify='center'
			alignItems='center'
			minH='88%'
		>
			<Stack
				boxShadow={`2px 0px 5px -1px ${defaultColors.secondaryColor}`}
				className='image__login'
				position='relative'
				display={['none', 'none', 'block']}
			>
				<div id='first-color-rectangle'></div>
				<div id='second-color-rectangle'></div>
				<div id='third-color-rectangle'></div>
				<div id='fourth-color-rectangle'></div>
				<Image src={require('../../assets/images/login.jpeg')} h='88vh' />
			</Stack>
			<VStack w='70%' h='80%' px={[0, 0, 20]} pt={[150, 50, 0]} justifyContent='space-around' display='flex' flexDirection='column'>
				<Stack>
					<Image src={require('../../assets/images/logo-fundo-branco.png')} w={[150, 150, 250]} />
				</Stack>
				<Stack w={['100%', '80%', '65%']} maxW='600px'>
					<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit}>
									<Stack spacing='12px'>
										<Stack>
											<FormControl>
												<Input
													name='email'
													id='input__email'
													placeholder='Login'
													mb='4px'
													fontStyle='italic'
													placehoderFontStyle='italic'
													isReadOnly={false}
												/>
												<FormErrorMessage name='email' />
											</FormControl>

											<FormControl>
												<InputGroup size='md'>
													<Input
														name='password'
														id='input__password'
														placeholder='Senha'
														mb='4px'
														autoComplete='nope'
														pr='72px'
														type={show ? 'text' : 'password'}
														fontStyle='italic'
														placehoderFontStyle='italic'
														isReadOnly={false}
													/>
													<InputRightElement width='72px'>
														<Button size='xs' bgColor='transparent' onClick={handleClick} border='none'>
															<Icon as={show ? BsEyeFill : BsEyeSlashFill} boxSize='20px' color={defaultColors.primaryColor} />
														</Button>
													</InputRightElement>
												</InputGroup>
												<FormErrorMessage name='password' />
											</FormControl>
											<Flex w='100%' justifyContent='center' mt='20px'>
												<Button
													id='button__login'
													border={`2px solid ${defaultColors.secondaryColor}`}
													type='submit'
													disabled={!isValid}
													isLoading={isLoading}
													bgColor={defaultColors.primaryColor}
													h='30px'
													w='120px'
													fontStyle='italic'
													className='button__login'
												>
													Entrar
												</Button>
											</Flex>
										</Stack>
										<Flex mt='12px' w='100%' justifyContent='center'>
											<Text
												color={defaultColors.primaryColor}
												paddingLeft='4px'
												fontStyle='italic'
												_hover={{
													textDecoration: 'underline',
													cursor: 'pointer',
												}}
												onClick={() => setForgotPasswordIsOpen(true)}
											>
												Esqueceu a senha?
											</Text>
										</Flex>
										<Flex mt='12px' gap='12px' w='100%' justifyContent='center' alignItems='center'>
											<Text color={defaultColors.primaryColor} paddingLeft='4px' fontStyle='italic'>
												Novo usuário?
											</Text>
											<Button
												id='button__register'
												border={`2px solid ${defaultColors.secondaryColor}`}
												type='button'
												bgColor={defaultColors.primaryColor}
												h='30px'
												fontStyle='italic'
												className='button__login'
												onClick={() => {
													navigate('/cadastro');
												}}
											>
												Cadastre-se
											</Button>
										</Flex>
									</Stack>
								</form>
							);
						}}
					</Formik>
					{user && <MfaModal mfaOpen={mfaOpen} userId={user} setMfaOpen={setMfaOpen} />}
					<ForgotPasswordModal forgotPasswordIsOpen={forgotPasswordIsOpen} setForgotPasswordIsOpen={setForgotPasswordIsOpen} />
				</Stack>
			</VStack>
		</Stack>
	);
};

export default Login;
