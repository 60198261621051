import React from "react";

import { Input as DefaultInput, InputProps } from "@chakra-ui/react";
import { Field } from 'formik';

import { defaultColors } from "../config/variables";

const Input: React.FC<InputProps & { placehoderFontStyle?: string}> = React.forwardRef(
  (props: InputProps & { placehoderFontStyle?: string}, ref) => {
    return (
      <Field {...props}>
        {({ form, field }: any) => {
          return (
            <DefaultInput
              { ...field }
              fontFamily='montserrat'
              color='black'
              onBlur={form.handleBlur}
              fontSize={props.fontSize || 'xl'}
              isReadOnly={props.isReadOnly || true}
              borderRadius={'40px'}
              border={props.border || `1px solid ${defaultColors.primaryColor}`}
              placeholder={props.placeholder}
              bg='white'
              name={props.name}
              type={props.type || 'text'}
              fontStyle={props.fontStyle || 'normal'}
              focusBorderColor={defaultColors.lightGreen}
              _placeholder={{ color: defaultColors.lightGreen, fontStyle: props.placehoderFontStyle ? props.placehoderFontStyle : 'normal' }}
              {...props}
            />
          )
        }}
      </Field>
    );
  }
);

export default Input;