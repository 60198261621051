import React from 'react';

//@ts-ignore
import Checkout from '@parcelaexpress/checkout-react-component';
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/layout';
import { Formik } from 'formik';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';

import { GetSimulationDto, PayConsultDto, PayConsultRequest, PaymentsApi } from '../../../clients';

import { useConsultVehicleHistory } from '../context/ConsultVehicleHistoryProvider';

import { getApiDefaultConfig } from '../../../services/api.service';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';

import { PaymentForm } from '../types/paymentForm.type';

import Button from '../../../components/Button';
import CreditCardModal from './CreditCardModal';
import PaymentSecureModal from './PaymentSecureModal';

type CreditCardProps = {
	formValues: PaymentForm;
};

type PaymentType = 'credit' | 'debit' | 'online_debit';

const paymentTypesLiterals: Record<PaymentType, string> = {
	credit: 'Crédito',
	debit: 'Débito',
	online_debit: 'Débito',
};

const CreditCard: React.FC<CreditCardProps> = (props: CreditCardProps) => {
	const { token, simulation, setLinkPaid, setShowDocument } = useConsultVehicleHistory();
	const apiConfig = getApiDefaultConfig();
	const { addToast } = useToasts();
	const [confirmPayment, setConfirmPayment] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState<GetSimulationDto | undefined>(undefined);
	const [secureData, setSecureData] = React.useState<any>({});
	const [paymentSecure, setPaymentSecure] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [saleId, setSaleId] = React.useState<string>('');
	const [creditCheckout, setCreditCheckout] = React.useState({
		holder_name: '',
		number: '',
		expiration_month: '',
		expiration_year: '',
		security_code: '',
	});

	const payerName = props.formValues.name.split(' ');
	const creditOriginalCost = centsToRealWithComma(selectedValue?.original_amount_cents ?? 0);
	const creditFee = centsToRealWithComma((selectedValue?.total_amount_cents ?? 0) - (selectedValue?.original_amount_cents ?? 0));
	const creditTotalCost = centsToRealWithComma(selectedValue?.total_amount_cents ?? 0);
	const actionTypes = ['threeDS2Fingerprint', 'redirect', 'threeDS2Challenge'];

	const handlePayment = async () => {
		if (!selectedValue?.total_amount_cents) return;
		setIsLoading(true);
		if (token) {
			try {
				let payConsultDto: PayConsultDto = {
					token,
					amount_cents: Number(selectedValue.total_amount_cents),

					card_attributes: {
						holder_name: creditCheckout.holder_name,
						number: creditCheckout.number,
						expiration_month: creditCheckout.expiration_month,
						expiration_year: creditCheckout.expiration_year,
						security_code: creditCheckout.security_code,
					},
					installments: selectedValue.installment,
					payer: {
						address: {
							city: props.formValues.city,
							complement: props.formValues?.complement ?? '',
							district: props.formValues.district,
							number: props.formValues.number,
							state: props.formValues.state,
							street: props.formValues.street,
							zipcode: props.formValues.zipcode,
						},
						document: props.formValues.document,
						email: props.formValues.email,
						first_name: payerName[0],
						last_name: payerName[payerName.length - 1],
					},
				};

				const createConsultPaymentRequest: PayConsultRequest = {
					payConsultDto,
				};

				let response: any;
				const paymentsApi = new PaymentsApi(apiConfig);
				response = await paymentsApi.payConsult(createConsultPaymentRequest);
				setSaleId(response.sale_id);

				const { action } = response || {};
				if (actionTypes.includes(action?.type)) {
					setConfirmPayment(!confirmPayment);
					setSecureData(action);
					setPaymentSecure(!paymentSecure);
					return;
				} else if (!action) {
					addToast('Pagamento realizado com sucesso!', {
						appearance: 'success',
						autoDismiss: true,
					});
					setConfirmPayment(!confirmPayment);

					setLinkPaid(true);
					setShowDocument(true);
				}
			} catch (err) {
				addToast('Falha no pagamento! Verifique os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
				setConfirmPayment(!confirmPayment);
			} finally {
				setIsLoading(false);
			}
		}
	};

	const checkoutOnChangeHandle = (state: any) => {
		const { data } = state;
		const { paymentMethod } = data;

		setCreditCheckout({
			holder_name: paymentMethod.holderName,
			number: paymentMethod.encryptedCardNumber,
			expiration_month: paymentMethod.encryptedExpiryMonth,
			expiration_year: paymentMethod.encryptedExpiryYear,
			security_code: paymentMethod.encryptedSecurityCode,
		});
	};

	return (
		<Box>
			<Stack>
				<Select
					placeholder='Selecione o número de parcelas...'
					options={simulation
						.filter((installment: GetSimulationDto) => installment.type === 'credit' || installment.type === 'online_debit')
						.sort((a: GetSimulationDto, b: GetSimulationDto) => b.type.localeCompare(a.type))
						.map((installment: GetSimulationDto) => ({
							label: (
								<HStack justify='space-between'>
									<Text>
										<strong>
											{installment.installment} x no {paymentTypesLiterals[installment.type as PaymentType]}
										</strong>{' '}
										de R$ {centsToRealWithComma(installment.installment_amount_cents)}
									</Text>

									<Text>
										{' '}
										<strong>Total:</strong> R$ {centsToRealWithComma(installment.total_amount_cents)}
									</Text>
								</HStack>
							),
							value: installment,
						}))}
					onChange={(event) => setSelectedValue(event?.value)}
				/>
			</Stack>

			<Stack direction='column' mt='16px' alignItems='center'>
				<Stack direction='column' fontSize='2sm' bgColor='gray.200' rounded='md' p='16px' w='100%'>
					<Flex justifyContent='space-between'>
						<Text>Valor original do serviço</Text>

						<Text>R$ {creditOriginalCost}</Text>
					</Flex>
					<Flex justifyContent='space-between'>
						<Text>* Custo financeiro da operação:</Text>

						<Text>R$ {creditFee}</Text>
					</Flex>

					<Box bgColor='gray.400' height='0.063rem' />

					<Flex justifyContent='space-between'>
						<Text>Valor a ser pago com acréscimo</Text>

						<Text>R$ {creditTotalCost}</Text>
					</Flex>
				</Stack>
			</Stack>

			<>
				<Formik enableReinitialize validateOnMount onSubmit={handlePayment} initialValues={[]}>
					{({ handleSubmit }) => {
						return (
							<form onSubmit={handleSubmit} id='payment' className='data__credit-card'>
								<Stack flexDirection='column' height='100%' display='block' mb='40px' alignItems='center'>
									<Stack rounded='xl' p='4px'>
										<Checkout
											environment={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT}
											clientKey={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY}
											theme='outline'
											showPayButton={false}
											onChange={checkoutOnChangeHandle}
										/>
										<Button
											isDisabled={!selectedValue?.total_amount_cents}
											onClick={() => {
												setConfirmPayment(true);
											}}
										>
											Pagamento
										</Button>
									</Stack>

									<Text mt='8px' fontSize='0.75rem'>
										* Sobre o valor original dos serviços será acrescentada a taxa da administradora do cartão.
									</Text>
								</Stack>
								<CreditCardModal
									confirmPayment={confirmPayment}
									setConfirmPayment={setConfirmPayment}
									isLoading={isLoading}
									handleSubmit={handleSubmit}
								/>
							</form>
						);
					}}
				</Formik>
			</>

			{actionTypes.includes(secureData?.type) && (
				<PaymentSecureModal
					paymentSecure={paymentSecure}
					action={secureData}
					setLinkPaid={setLinkPaid}
					setPaymentSecure={setPaymentSecure}
					saleID={saleId}
				/>
			)}
		</Box>
	);
};

export default CreditCard;
