import React, { ReactNode } from 'react';

import { Button as DefaultButton, ButtonProps as DefaultButtonProps } from '@chakra-ui/react';

import { defaultColors } from '../config/variables';

type ButtonProps = DefaultButtonProps & {
	children: ReactNode;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
	return (
		<DefaultButton
			rounded={props.rounded || 'md'}
			variant={props.variant || 'solid'}
			bgColor={props.bgColor || defaultColors.primaryColor}
			color={props.color || defaultColors.white}
			fontSize={props.fontSize || 'md'}
			_hover={{ opacity: 0.8 }}
			isLoading={props.isLoading || false}
			px={props.px || '24px'}
			width={props.width}
			size={props.size || 'lg'}
			height={props.height}
			{...props}
		>
			{props.children}
		</DefaultButton>
	);
};

export default Button;
