import store from 'store2';
import cleanDeep from 'clean-deep';

export const getAll = () => {
	return store.getAll();
};

export const getKey = (key: string) => {
	return store.get(key);
}

export const persist = (data: any) => {
	store(cleanDeep(data));
}

export const persistKey = (key: string, data: any) => {
	store.add(key, cleanDeep(data));
}

export const clear = () => {
	store(false);
}

export const clearKey = (key: string) => {
	store.remove(key)
}