import React from 'react';

import { Container, Heading } from '@chakra-ui/react';

import Text from '../../components/Text';

export default function ErrorPage() {
	return (
		<Container width='100%' minH='88%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
			<Heading>Oops!</Heading>
			<Text>Desculpe, ocorreu um erro inesperado.</Text>
			<Text>Recarregue a página</Text>
			<Text>Se o erro persistir, contate o suporte!</Text>
		</Container>
	);
}
