import * as React from 'react';

import { useParams } from 'react-router-dom';

import { GetBilletInoformationResponseDto, GetSimulationDto, PayerDto } from '../../../clients';

interface ConsultDebtsContextData {
	token: string | undefined;
	simulation: GetSimulationDto[];
	setSimulation: React.Dispatch<React.SetStateAction<GetSimulationDto[]>>;
	getBilletResponse: GetBilletInoformationResponseDto | undefined;
	setGetBilletResponse: React.Dispatch<React.SetStateAction<GetBilletInoformationResponseDto | undefined>>;
	selectedDebt: any;
	setSelectedDebt: React.Dispatch<any>;
	payer: PayerDto | undefined;
	setPayer: React.Dispatch<React.SetStateAction<PayerDto | undefined>>;
}

const ConsultDebtsContext = React.createContext<ConsultDebtsContextData>({} as ConsultDebtsContextData);

type ConsultDebtsProviderProps = {
	children: React.ReactElement;
};

export const ConsultDebtsProvider: React.FC<ConsultDebtsProviderProps> = ({ children }) => {
	let { token } = useParams();
	const [simulation, setSimulation] = React.useState<GetSimulationDto[]>([]);
	const [getBilletResponse, setGetBilletResponse] = React.useState<GetBilletInoformationResponseDto>();
	const [selectedDebt, setSelectedDebt] = React.useState<any>(false);
	const [payer, setPayer] = React.useState<PayerDto>();

	return (
		<ConsultDebtsContext.Provider
			value={{
				token,
				simulation,
				setSimulation,
				getBilletResponse,
				setGetBilletResponse,
				selectedDebt,
				setSelectedDebt,
				payer,
				setPayer,
			}}
		>
			{children}
		</ConsultDebtsContext.Provider>
	);
};

export function useConsultDebts() {
	const context = React.useContext(ConsultDebtsContext);
	return context;
}
