import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';

import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';

type ReceiptProps = {
	debt: any;
	list: any;
};

export const Receipt: React.FC<ReceiptProps> = (props: ReceiptProps) => {
	return (
		<Stack
			css={{
				'@media screen': {
					display: 'none',
				},
				'@media print': {
					display: 'flex',
				},
			}}
			w='100%'
			alignItems='center'
			justifyContent='center'
		>
			<Stack w='85%' >
			<Box borderBottom='4px solid #E2E8F0' p='16px' borderRadius='md' boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)' w='100%' >
				<Stack w='100%' alignItems='center'>
					<Stack mb='20px'>
						<Text fontSize='3xl' fontWeight='bold' borderBottom='1px solid grey'>
							Recibo de Pagamento
						</Text>
					</Stack>
					<Stack direction='row' alignItems='center' justifyContent='space-between' w='100%'>
						<Stack spacing='8px' textAlign='center' flex='1' w='100%'>
							<Text>
								<strong>Placa:</strong> {props.list?.license_plate}
							</Text>
							<Text>
								<strong>Proprietário:</strong> {props.list?.owner_name}
							</Text>
						</Stack>
					</Stack>
				</Stack>
			</Box>
			<Box
				borderTop='1px solid #E2E8F0'
				borderBottom='4px solid #E2E8F0'
				p='16px'
				borderRadius='md'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
				w='100%'
			>
				<Stack w='100%'>
					<Text fontSize='22px' fontWeight='bold' borderBottom='1px solid grey'>
						Dados do Título:
					</Text>
					<Text>Débito: {props.debt?.description}</Text>
					<Text>Valor: R$ {centsToRealWithComma(props.debt?.total)}</Text>
					<Text>Código de Barras: {props.debt?.barcode}</Text>
					<Text>Data de Vencimento: {props.debt?.due_date ? format(new Date(props.debt.due_date), 'dd/MM/yyyy') : ''}</Text>
				</Stack>
			</Box>
			</Stack>
		</Stack>
	);
};
