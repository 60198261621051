import React from 'react';

import { Text, TextProps } from '@chakra-ui/react';

type TitleProps = TextProps & {
	children: string;
	fontSize?: string[] | string;
	fontWeight?: string | number;
	color?: string;
};

const Title: React.FC<TitleProps> = ({ children, fontSize = ['3xl', '3xl', '4xl'], color, fontWeight, textAlign, borderBottom, fontFamily }: TitleProps) => {
	return (
		<Text
			fontSize={fontSize}
			fontWeight={fontWeight || 'bold'}
			color={color || `#000`}
			textAlign={textAlign}
			borderBottom={borderBottom}
			fontFamily={fontFamily || 'Montserrat'}
		>
			{children}
		</Text>
	);
};

export default Title;
