/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmMailDto,
  ConfirmMailResponseDto,
  RegisterUserDto,
  RegisterUserResponseDto,
  ResentKeyDto,
  ResentKeyResponseDto,
  UserNameEntity,
} from '../models/index';
import {
    ConfirmMailDtoFromJSON,
    ConfirmMailDtoToJSON,
    ConfirmMailResponseDtoFromJSON,
    ConfirmMailResponseDtoToJSON,
    RegisterUserDtoFromJSON,
    RegisterUserDtoToJSON,
    RegisterUserResponseDtoFromJSON,
    RegisterUserResponseDtoToJSON,
    ResentKeyDtoFromJSON,
    ResentKeyDtoToJSON,
    ResentKeyResponseDtoFromJSON,
    ResentKeyResponseDtoToJSON,
    UserNameEntityFromJSON,
    UserNameEntityToJSON,
} from '../models/index';

export interface ConfirmMailRequest {
    userId: string;
    confirmMailDto: ConfirmMailDto;
}

export interface RegisterUserRequest {
    registerUserDto: RegisterUserDto;
}

export interface ResendKeyRequest {
    resentKeyDto: ResentKeyDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Confirmar e-mail
     */
    async confirmMailRaw(requestParameters: ConfirmMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmMailResponseDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling confirmMail.');
        }

        if (requestParameters.confirmMailDto === null || requestParameters.confirmMailDto === undefined) {
            throw new runtime.RequiredError('confirmMailDto','Required parameter requestParameters.confirmMailDto was null or undefined when calling confirmMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/users/{user_id}/confirm`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmMailDtoToJSON(requestParameters.confirmMailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmMailResponseDtoFromJSON(jsonValue));
    }

    /**
     * Confirmar e-mail
     */
    async confirmMail(requestParameters: ConfirmMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmMailResponseDto> {
        const response = await this.confirmMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Busca usuários pelo cartório
     */
    async getUsersBySellerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNameEntity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/seller-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserNameEntityFromJSON));
    }

    /**
     * Busca usuários pelo cartório
     */
    async getUsersBySeller(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNameEntity>> {
        const response = await this.getUsersBySellerRaw(initOverrides);
        return await response.value();
    }

    /**
     * Realizar registro no sistema
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterUserResponseDto>> {
        if (requestParameters.registerUserDto === null || requestParameters.registerUserDto === undefined) {
            throw new runtime.RequiredError('registerUserDto','Required parameter requestParameters.registerUserDto was null or undefined when calling registerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/users/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserDtoToJSON(requestParameters.registerUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterUserResponseDtoFromJSON(jsonValue));
    }

    /**
     * Realizar registro no sistema
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterUserResponseDto> {
        const response = await this.registerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reenviar chave de confirmação
     */
    async resendKeyRaw(requestParameters: ResendKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResentKeyResponseDto>> {
        if (requestParameters.resentKeyDto === null || requestParameters.resentKeyDto === undefined) {
            throw new runtime.RequiredError('resentKeyDto','Required parameter requestParameters.resentKeyDto was null or undefined when calling resendKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/users/resent-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResentKeyDtoToJSON(requestParameters.resentKeyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResentKeyResponseDtoFromJSON(jsonValue));
    }

    /**
     * Reenviar chave de confirmação
     */
    async resendKey(requestParameters: ResendKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResentKeyResponseDto> {
        const response = await this.resendKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
