import React from 'react';

import { Center, Flex, Menu, MenuButton, MenuList, MenuItem, ButtonProps, MenuButtonProps } from '@chakra-ui/react';
import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range/dist/defaultRanges';

import { defaultColors } from '../config/variables';

import Button from './Button';

const staticRangesLabels = {
	Today: 'Hoje',
	Yesterday: 'Ontem',
	'This Week': 'Essa Semana',
	'Last Week': 'Semana Passada',
	'This Month': 'Esse Mês',
	'Last Month': 'Mês Passado',
};

const inputRangesLabels = {
	'days up to today': 'Dias até hoje',
	'days starting today': 'Dias a partir de hoje',
};

function translateRange(dictionary: any) {
	return (item: any) => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
}

const ptStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
const ptInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

export interface IPeriod {
	startDate: Date;
	endDate: Date;
	key: string;
}

type IRangeDatePicker = ButtonProps &
	MenuButtonProps & {
		period: IPeriod[];
		setPeriod: React.Dispatch<React.SetStateAction<IPeriod[]>>;
		setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
		handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
	};

const RangeDatePicker: React.FC<IRangeDatePicker> = (props: IRangeDatePicker) => {
	const { period, setPeriod, setFieldValue, handleSubmit, p, fontSize, size, ...rest } = props;

	return (
		<Menu
			placement='end-end'
			onClose={() => {
				if (handleSubmit) {
					if (setFieldValue) {
						setFieldValue('startDate', format(period[0].startDate, 'yyyy-MM-dd'));
						setFieldValue('endDate', format(period[0].endDate, 'yyyy-MM-dd'));
					}
					handleSubmit();
				}
			}}
			isLazy
		>
			<Flex>
				<MenuButton
					as={Button}
					_focus={{ outline: 'none' }}
					px={p || 4}
					fontSize={fontSize || 'md'}
					size={size || 'sm'}
					{...rest}
					border={`2px solid ${defaultColors.secondaryColor}`}
					backgroundColor={defaultColors.primaryColor}
					borderRadius='15px'
				>
					{`${format(period[0].startDate, 'dd/MM/yyyy')} → ${format(period[0].endDate, 'dd/MM/yyyy')}`}
				</MenuButton>

				<MenuList backgroundColor='white' position={'relative'} w={['60%', '100%']} left={['0%', '0%', '45%']}>
					<Flex flexDirection='column' alignItems='flex-end'>
						<Center>
							<DateRangePicker
								onChange={(item: any) => setPeriod([item.selection])}
								moveRangeOnFirstSelection={false}
								months={2}
								ranges={period}
								direction='vertical'
								maxDate={addDays(new Date(), 7)}
								locale={ptBR}
								staticRanges={ptStaticRanges}
								inputRanges={ptInputRanges}
							/>
						</Center>
						<Center>
							<MenuItem backgroundColor='transparent'>
								<Button size={size || 'sm'} color='primary' backgroundColor='transparent'>
									Filtrar
								</Button>
							</MenuItem>
						</Center>
					</Flex>
				</MenuList>
			</Flex>
		</Menu>
	);
};

export default RangeDatePicker;
