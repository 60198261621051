import React, { useEffect, useState } from 'react';
import { usePubNub } from 'pubnub-react';
import { useToasts } from 'react-toast-notifications';
import { Alert, Spinner, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Text from '../../components/Text';
import {
  GetReportDto,
  GetReportDtoDocumentTypeEnum,
  GetVehicleDto,
  VehiclesApi
} from '../../clients';
import Crv from '../Crv';
import Atpv from '../Atpv';
import { getApiAuthConfig } from '../../services/api.service';
import { RealTimeChannel, RealTimeEvent } from '../../services/real-time.service';
import { ReportStatusEnum } from '../Reports/enums/ReportStatusEnum';


const Result: React.FC = () => {
  const apiConfig = getApiAuthConfig();
  const vehiclesApi = new VehiclesApi(apiConfig);
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionNumber } = useParams();
  const { addToast } = useToasts();
  const pubNub = usePubNub();

  const [licensePlateData, setLicensePlateData] = useState<GetVehicleDto | undefined>(undefined);
  const [report, setReport] = useState<GetReportDto | undefined>(undefined);
  const [inquiries, setInquiries] = useState(undefined);
  const [inquiriesLoading, setInquiriesLoading] = useState(true);
  const [isSlowSearch, setIsSlowSearch] = useState(false);
  const [atpveUrl, setAtpveUrl] = useState(undefined);
  const [atpveUrlLoading, setAtpveUrlLoading] = useState(true);
  const [isAtpveSuccess, setIsAtpveSuccess] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false)


  if (!location.state) {
    navigate('/admin/busca');
  }

  const { licensePlate, crvAtpve, whatsapp, email, isSearching } = location?.state || {};

  useEffect(() => {
    if (isSearching) {
      getData();

      navigate(
        '.',
        {
          state: {
            licensePlate,
            crvAtpve,
            whatsapp,
            email,
            isSearching: false
          },
          replace: true
        }
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (transactionNumber) {
      const channels = pubNub.getSubscribedChannels()
        .filter((channel) => channel !== RealTimeChannel.MAINTENANCE_MODE);
      pubNub.unsubscribe({ channels: [ ...channels, transactionNumber ] });

      pubNub.addListener({
        message(event) {
          const { message, channel } = event;

          if ( transactionNumber === channel) {
            const { license_plate_data, inquiries, atpve_document } = message;

            if (license_plate_data && license_plate_data.eventType === RealTimeEvent.LICENSE_PLATE_DATA) {
              const { vehicle, report, consult_debt } = license_plate_data;

              if (report.status === ReportStatusEnum.Failed) {
                addToast(`Ocorreu um erro ao tentar buscar os dados do veículo.`, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                navigate('/admin/busca');
                return;
              }

              if (vehicle) {
                setLicensePlateData(vehicle);
                setReport({ ...report, url: consult_debt?.url });
              }
            }

            if (inquiries && inquiries.eventType === RealTimeEvent.INQUIRIES) {
              setInquiries(inquiries.inquiries);
              setInquiriesLoading(false);
            }

            if (atpve_document && atpve_document.eventType === RealTimeEvent.ATPVE_DOCUMENT) {
              const { atpve_document } = message;

              setAtpveUrl(atpve_document.documentUrl);
              setAtpveUrlLoading(false)
              setIsAtpveSuccess(atpve_document.documentUrl.length)
            }
          }
        }
      });

      pubNub.subscribe({channels: [ transactionNumber ]})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionNumber])

  useEffect( () => {
    if (report?.id && licensePlateData) {
      getInquiries(report, licensePlateData.stateRegistration);

      if (
        report.document_type === GetReportDtoDocumentTypeEnum.Atpve
      ) {
        if (licensePlateData.hasAtpve) {
          getAtpveDocument(licensePlateData, report);
        } else {
          setIsAtpveSuccess(false);
          setAtpveUrlLoading(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  const updateReport = (payerName: string, payerDocument: string) => {
    const payer = { name: payerName, document: payerDocument };
    setReport({ ...report, payer } as GetReportDto);
  }

  const getData = async () => {
    if (isDataLoading) return; 

    setIsDataLoading(true)

    try {
      const licensePlateResponse = await vehiclesApi.getVehicleByLicensePlate(
        { licensePlate, transactionNumber: transactionNumber || '', documentType: crvAtpve, whatsapp, email }
      );
      const { vehicle, report, consult_debt } = licensePlateResponse;

      if (vehicle) {
        setLicensePlateData(vehicle);
        setReport({ ...report, url: consult_debt?.url });
      }

      if (report.status === ReportStatusEnum.Failed) {
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 500 } };
      }
    } catch (vehicleError: any) {
      if (vehicleError?.response?.status === 404) {
        addToast('Placa não encontrada, favor conferir os dados para repetir a consulta', {
          appearance: 'error',
          autoDismiss: true,
        });
        navigate('/admin/busca');
        return;
      }

      if (!vehicleError?.response) {
        setIsSlowSearch(true);
        return;
      }

      if (vehicleError?.response?.status === 409) {
        navigate('/admin/busca');
        return;
      }

      addToast(`Ocorreu um erro ao tentar buscar os dados do veículo.`, {
        appearance: 'error',
        autoDismiss: true,
      });
      navigate('/admin/busca');
    } finally {
      setIsDataLoading(false)
    }
  }

  async function getAtpveDocument(vehicle: GetVehicleDto, report: GetReportDto) {
    try {
      if (vehicle.stateRegistration === 'MG') {
        await vehiclesApi.getAtpveDocument({ transactionNumber: report.transaction_number });
      }
    } catch {}
  }

  async function getInquiries(report: GetReportDto, stateRegistration: string) {
    try {
      if (report && stateRegistration === 'MG') {
        const { license_plate, cns, transaction_number, document_type } = report;

        vehiclesApi.getInquiries({
          transactionNumber: transactionNumber || '',
          licensePlate: license_plate,
          cns,
          reference: transaction_number,
          type: document_type,
        });
      }
    } catch {}
  }

  return licensePlateData === undefined ? (
    <VStack marginY={'auto'} marginX={'auto'} justifyContent={'center'}>
      <Spinner size='xl' marginX='auto'/>
      { isSlowSearch ? (
          <Alert status='warning' textAlign='center' mt='5'>
            Sua consulta está demorando mais do que o esperado, aguarde mais alguns instantes. <br/>
            Não refaça a consulta com a mesma placa enquanto esta busca não for finalizada ou poderá haver nova cobrança.
          </Alert>
        ) : (
          <Text textAlign='center' mt='5'>
            Buscando dados do veículo...
          </Text>
        )
      }
    </VStack>
  ) : (
    <>
      {
          crvAtpve === GetReportDtoDocumentTypeEnum.Crv ? (
            <Crv
              licensePlateData={licensePlateData}
              inquiries={inquiries}
              report={report}
              updateReport={updateReport}
              inquiriesLoading={inquiriesLoading}
            />
          ) : (
            <Atpv
              licensePlateData={licensePlateData}
              inquiries={inquiries}
              report={report}
              updateReport={updateReport}
              inquiriesLoading={inquiriesLoading}
              atpveUrl={atpveUrl}
              atpveUrlLoading={atpveUrlLoading}
              isAtpveSuccess={isAtpveSuccess}
              clearAtpveUrl={() => setAtpveUrl(undefined)}
            />
          )
      }
    </>
  )
}

export default Result;