import { Flex, FormControl, FormLabel, FormLabelProps, Input, InputProps } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import Text from './Text';

type FileUploadInputProps = {
	label?: string;
	containerProps?: FormLabelProps;
	inputProps?: InputProps;
};

const FileUploadInput: React.FC<FileUploadInputProps> = ({ label, containerProps, inputProps }) => {
	return (
		<FormControl maxW='180'>
			<FormLabel
				_hover={{ opacity: 0.8 }}
				cursor='pointer'
				htmlFor='file-upload'
				borderColor='black'
				borderWidth={1}
				padding={2}
				borderRadius={4}
				{...containerProps}
			>
				<Flex alignItems='center' justifyContent='space-around'>
					<AiOutlineDownload />
					<Text whiteSpace='nowrap' ml={2}>
						{label || 'Enviar Arquivo'}
					</Text>
				</Flex>
			</FormLabel>
			<Input id='file-updload' display='none' type='file' {...inputProps} />
		</FormControl>
	);
};

export default FileUploadInput;
