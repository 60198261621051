/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetDebtsListResponseDto,
} from '../models/index';
import {
    GetDebtsListResponseDtoFromJSON,
    GetDebtsListResponseDtoToJSON,
} from '../models/index';

export interface GetDebtsListByLicensePlateRequest {
    token: string;
}

/**
 * 
 */
export class DebtsListsApi extends runtime.BaseAPI {

    /**
     * Buscar débitos do veículo
     */
    async getDebtsListByLicensePlateRaw(requestParameters: GetDebtsListByLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDebtsListResponseDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getDebtsListByLicensePlate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/debts-lists/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDebtsListResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar débitos do veículo
     */
    async getDebtsListByLicensePlate(requestParameters: GetDebtsListByLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDebtsListResponseDto> {
        const response = await this.getDebtsListByLicensePlateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
