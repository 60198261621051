import React from 'react';

import { Input as DefaultInput, InputProps } from '@chakra-ui/react';
import { Field } from 'formik';

import { defaultColors } from '../../config/variables';

const SquareInput: React.FC<InputProps> = React.forwardRef((props: InputProps) => {
	function maxLengthCheck(object: any) {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength);
		}
	}

	return (
		<Field {...props}>
			{({ form, field }: any) => {
				return (
					<DefaultInput
						{...field}
						color={props.color || defaultColors.primaryColor}
						fontSize={props.fontSize || 'x-large'}
						fontWeight='bolder'
						onBlur={form.handleBlur}
						borderRadius={15}
						bgColor='gray.300'
						value={props.value}
						name={props.name}
						width='50px'
						height='50px'
						maxLength={1}
						onInput={maxLengthCheck}
						textTransform='capitalize'
						padding={props.padding || '5px'}
						textAlign='center'
						{...props}
					/>
				);
			}}
		</Field>
	);
});

export default SquareInput;
