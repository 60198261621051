import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { useToasts } from 'react-toast-notifications';
import { ImportSellersRequest, SellersApi } from '../../clients';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { getApiAuthConfig } from '../../services/api.service';
import UploadForm from './components/UploadForm';

const ImportSellers: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);

	const { addToast } = useToasts();

	const [isUploadLoading, setIsUploadLoading] = React.useState<boolean>(false);
	const [file, setFile] = React.useState<File | null>(null);

	const handleUploadSellersSubmit = async () => {
		setIsUploadLoading(true);
		try {
			if (file) {
				const importSellersRequest: ImportSellersRequest = {
					file: file,
					name: file.name,
					contentType: file.type,
				};

				await sellersApi.importSellers(importSellersRequest);

				addToast('Cartórios cadastrados com sucesso.', {
					appearance: 'success',
					autoDismiss: true,
				});
				setFile(null);
				(document.getElementById('file-upload') as HTMLInputElement).value = '';
			}
		} catch (error: any) {
			if (error.response.status === 422) {
				addToast('O arquivo enviado contém informações inválidas. Verifique o Documento e o CEP dos cartórios.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} else {
				addToast('Falha ao cadastrar os cartórios.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
		setIsUploadLoading(false);
	};

	return (
		<Flex padding={['10px', '50px']} flexDirection='column' minH='88%' overflowX='auto'>
			<Flex marginBottom='40px' borderBottom='2px solid black' maxW={['100%', '100%', '70%']}>
				<Title fontSize={['lg', 'lg', '3xl']} fontWeight={350}>
					Cadastro de Cartórios
				</Title>
			</Flex>
			<Text textAlign={['justify', 'start']} marginBottom='20px'>
				Envie um arquivo para cadastrar os cartórios.
			</Text>
			<Stack mb={4} alignItems={['center', 'center', 'start']} flexDirection='column' w='100%'>
				<UploadForm file={file} handleFormSubmit={handleUploadSellersSubmit} isLoading={isUploadLoading} setFile={setFile} />
			</Stack>
		</Flex>
	);
};

export default ImportSellers;
