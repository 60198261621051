/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardAttributesDto
 */
export interface CardAttributesDto {
    /**
     * 
     * @type {string}
     * @memberof CardAttributesDto
     */
    holder_name: string;
    /**
     * 
     * @type {string}
     * @memberof CardAttributesDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CardAttributesDto
     */
    expiration_month: string;
    /**
     * 
     * @type {string}
     * @memberof CardAttributesDto
     */
    expiration_year: string;
    /**
     * 
     * @type {string}
     * @memberof CardAttributesDto
     */
    security_code: string;
}

/**
 * Check if a given object implements the CardAttributesDto interface.
 */
export function instanceOfCardAttributesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "holder_name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "expiration_month" in value;
    isInstance = isInstance && "expiration_year" in value;
    isInstance = isInstance && "security_code" in value;

    return isInstance;
}

export function CardAttributesDtoFromJSON(json: any): CardAttributesDto {
    return CardAttributesDtoFromJSONTyped(json, false);
}

export function CardAttributesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardAttributesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holder_name': json['holder_name'],
        'number': json['number'],
        'expiration_month': json['expiration_month'],
        'expiration_year': json['expiration_year'],
        'security_code': json['security_code'],
    };
}

export function CardAttributesDtoToJSON(value?: CardAttributesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'holder_name': value.holder_name,
        'number': value.number,
        'expiration_month': value.expiration_month,
        'expiration_year': value.expiration_year,
        'security_code': value.security_code,
    };
}

