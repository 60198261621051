/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetBatchesDto,
} from '../models/index';
import {
    GetBatchesDtoFromJSON,
    GetBatchesDtoToJSON,
} from '../models/index';

export interface GetBatchesRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    sellerIds?: string;
    status?: GetBatchesStatusEnum;
}

/**
 * 
 */
export class SearchBatchApi extends runtime.BaseAPI {

    /**
     * Buscar todos os lotes
     */
    async getBatchesRaw(requestParameters: GetBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBatchesDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBatches.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getBatches.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBatches.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getBatches.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/search-batch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBatchesDtoFromJSON(jsonValue));
    }

    /**
     * Buscar todos os lotes
     */
    async getBatches(requestParameters: GetBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBatchesDto> {
        const response = await this.getBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBatchesStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    Failed: 'failed'
} as const;
export type GetBatchesStatusEnum = typeof GetBatchesStatusEnum[keyof typeof GetBatchesStatusEnum];
