export const cnsMask = (value: string) => {
	const cleanedValue = value.replace(/\D/g, '');

	return cleanedValue.replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2');
};

export const moneyMask = (value: number) => {
	return Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	}).format(value);
};

export const phoneMask = (value: string) => {
	const cleanedValue = value.replace(/\D/g, '');
	let maskedValue = cleanedValue.replace(/^0/, '');

	if (maskedValue.length > 11) {
		maskedValue = maskedValue.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
	} else if (maskedValue.length > 7) {
		maskedValue = maskedValue.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
	} else if (maskedValue.length > 2) {
		maskedValue = maskedValue.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
	} else if (value.trim() !== '') {
		maskedValue = maskedValue.replace(/^(\d*)/, '($1');
	}
	return maskedValue;
};

export const zipCodeMask = (value: string) => {
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{5})(\d{3})$/, '$1-$2');
	return value;
};
