import React, { Dispatch, SetStateAction } from 'react';

import {
	Button,
	Flex,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	TableContainerProps,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import { format, isToday } from 'date-fns';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';
import {
	MdContentCopy,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { GetReportDto, GetReportsDto } from '../../../clients';

import { DataValues } from '../interfaces/DataValues';
import { StatusInfo } from '../interfaces/StatusInfo';

import PayerReceiptModal from '../../../components/PayerReceiptModal';
import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import { useAuth } from '../../../contexts/AuthProvider';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';


type TableProps = TableContainerProps & {
	reports: GetReportsDto | undefined;
	body: GetReportDto[] | undefined;
	dataValues: DataValues;
	setDataValues: Dispatch<SetStateAction<DataValues>>;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setLimit: Dispatch<SetStateAction<number>>;
	fetchData: (dataValues: DataValues) => Promise<void>;
	currentPage: number;
	limit: number;
};

enum Status {
	Success = 'success',
	ReprocessingRequired = 'reprocessing_required',
	Failed = 'failed',
}

const statusText: Record<Status, StatusInfo> = {
	[Status.Success]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Sucesso',
	},
	[Status.ReprocessingRequired]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Reprocessamento Necessário',
	},
	[Status.Failed]: {
		icon: <AiFillCloseCircle color='tomato' size={16} />,
		text: 'Falha',
	},
};

const SimpleTable = (props: TableProps) => {
	const { user } = useAuth();

	function checkIfToday(date: Date): boolean {
		return isToday(date);
	}

	return (
		<>
			<TableContainer mt='40px' w='100%'>
				<Table variant='simple' size='md' display={['none', 'none', 'table']}>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody key={body.transaction_number} _hover={{ background: '#CCCCCC' }}>
							<Tr>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										ID
									</Text>
									<div>{body?.transaction_number}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS
									</Text>
									<div>
										{JSON.stringify(body?.cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2')}
									</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										PLACA
									</Text>
									<div>{body?.license_plate ? body?.license_plate.toUpperCase() : ''}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										TIPO
									</Text>
									<div>{body?.document_type}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA/HORA
									</Text>
									<div>
										{body.created_at ? format(new Date(body.created_at), 'dd/MM/yyyy HH:mm:ss') : ''}
									</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{statusText[body.status as Status].icon} {statusText[body.status as Status].text}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										R$ {centsToRealWithComma(body.consult_value || 0)}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										USUÁRIO
									</Text>
									<div>{body?.user_name}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										LINK
									</Text>
									{body.status === 'success' ? (
										<>
											{user.type === 'backoffice' || (user.type === 'seller' && checkIfToday(body.created_at)) ? (
												<Button
													gap='5px'
													p='5px'
													color={defaultColors.primaryColor}
													variant='link'
													onClick={() => navigator.clipboard.writeText(body.url ?? '')}
												>
													<MdContentCopy />
													<Text>Copiar</Text>
												</Button>
											) : (
												<div>Indisponível</div>
											)}
										</>
									) : (<Text>-</Text>)}
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										RECIBO
									</Text>
									{body.status === 'success' ? (
											<PayerReceiptModal
												report={body}
												sizeButton='sm'
												bgColorButton='transparent'
												variantButton='transparent'
												colorButton={defaultColors.primaryColor}
												paddingButton={0}
												fetchData={props.fetchData}
												dataValues={props.dataValues}
											/>
									) : (<Text>-</Text>)}
								</Td>
							</Tr>
						</Tbody>
					))}
				</Table>
				<Table variant='simple' size='md' display={['flex', 'flex', 'none']} flexDirection='column' w='100%'>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody
							w='100%'
							display='flex'
							alignItems='center'
							flexDirection='column'
							p='10px'
							bg={defaultColors.white}
							boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
							borderRadius='8px'
							border='2px solid rgba(0, 0, 0, 0.2)'
							mb='10px'
							_hover={{ background: defaultColors.white }}
						>
							<Tr w='100%' display='flex' flexDirection='column' alignItems='center' gap='12px'>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										ID:
									</Text>
									<div>{body?.transaction_number}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS:
									</Text>
									<div>
										{JSON.stringify(body?.cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2')}
									</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										PLACA:
									</Text>
									<div>{body?.license_plate ? body?.license_plate.toUpperCase() : ''}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										TIPO:
									</Text>
									<div>{body?.document_type}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										DATA/HORA:
									</Text>
									<div>
										{body.created_at ? format(new Date(body.created_at), 'dd/MM/yyyy HH:mm:ss') : ''}
									</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS:
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{statusText[body.status as Status].icon} {statusText[body.status as Status].text}
									</Flex>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR:
									</Text>
									<div>R$ {centsToRealWithComma(body.consult_value || 0)}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px' whiteSpace='normal'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										USUÁRIO:
									</Text>
									<div>{body?.user_name}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										LINK:
									</Text>
									{user.type === 'backoffice' || (user.type === 'seller' && checkIfToday(body.created_at)) ? (
										<Button
											gap='5px'
											p='5px'
											color={defaultColors.primaryColor}
											variant='link'
											onClick={() => navigator.clipboard.writeText(body.url ?? '')}
										>
											<MdContentCopy />
											<Text>Copiar</Text>
										</Button>
									) : (
										<div>Indisponível</div>
									)}
								</Flex>
								{body.status === 'success' && (
									<Flex flexDirection='row' alignItems='center' gap='8px'>
										<PayerReceiptModal
											report={body}
											sizeButton='sm'
											bgColorButton='transparent'
											variantButton='transparent'
											colorButton={defaultColors.primaryColor}
											paddingButton={0}
											fetchData={props.fetchData}
											dataValues={props.dataValues}
										/>
									</Flex>
								)}
							</Tr>
						</Tbody>
					))}
				</Table>
			</TableContainer>
			{props?.body && props.body.length > 0 && (
				<Stack justifyContent='space-around' flexDirection='row'>
					<Stack flexDirection='row'>
						<Button
							variant='link'
							id='button__double-arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									props.setCurrentPage(1);
									const dataValues = { ...props.dataValues, currentPage: 1 };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowLeft size='20px' />
						</Button>
						<Button
							variant='link'
							id='button__arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									const previousPage = props.currentPage - 1;
									props.setCurrentPage(previousPage);
									const dataValues = { ...props.dataValues, currentPage: previousPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowLeft size='20px' />
						</Button>
					</Stack>
					<Stack>
						<Text>
							<Stack flexDirection='row' alignItems='center' justifyContent='space-around' spacing='40px'>
								<Stack>
									<Text>
										Página {props.reports?.current_page} de {props.reports?.total_pages}
									</Text>
								</Stack>
								<Stack flexDirection='row' alignItems='center' w='40%'>
									<Text>Ir para página: </Text>
									<NumberInput
										id='input__page-number'
										min={1}
										max={props.reports?.total_pages}
										w='50%'
										minW='70px'
										defaultValue={props.currentPage}
										value={props.currentPage}
										onChange={(e) => {
											const newPage = Number(e);
											props.setCurrentPage(newPage);
											if (props.reports && props.reports.total_pages && newPage >= 1 && newPage <= props.reports.total_pages) {
												const dataValues = { ...props.dataValues, currentPage: newPage };
												props.setDataValues(dataValues);
												props.fetchData(dataValues);
											}
										}}
									>
										<NumberInputField
											min={1}
											max={props.reports?.total_pages}
											border='2px solid black'
											borderRadius='15px'
											_disabled={{
												color: 'black',
											}}
										/>
										<NumberInputStepper w='30%'>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Stack>
								<Stack>
									<Select
										id='input_select'
										defaultValue={10}
										w='60%'
										minW='120px'
										border='2px solid black'
										borderRadius='15px'
										value={props.limit}
										onChange={(e) => {
											const selectedValue = Number(e.target.value);
											props.setLimit(selectedValue);
											const dataValues = { ...props.dataValues, limit: selectedValue, currentPage: 1 };
											props.setDataValues(dataValues);
											props.setCurrentPage(1);
											props.fetchData(dataValues);
										}}
									>
										<option value={10}>Ver 10</option>
										<option value={20}>Ver 20</option>
										<option value={30}>Ver 30</option>
										<option value={40}>Ver 40</option>
										<option value={50}>Ver 50</option>
										<option value={100}>Ver 100</option>
									</Select>
								</Stack>
							</Stack>
						</Text>
					</Stack>
					<Stack flexDirection='row'>
						<Button
							variant='link'
							color='black'
							id='button__arrow-right'
							onClick={() => {
								const totalPages = props?.reports?.total_pages || 1;
								if (props.currentPage < totalPages) {
									const nextPage = props.currentPage + 1;
									props.setCurrentPage(nextPage);
									const dataValues = { ...props.dataValues, currentPage: nextPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowRight size='20px' />
						</Button>
						<Button
							variant='link'
							color='black'
							id='button__double-arrow-right'
							onClick={() => {
								const totalPages = props?.reports?.total_pages || 1;
								if (props.currentPage < totalPages) {
									props.setCurrentPage(totalPages);
									const dataValues = { ...props.dataValues, currentPage: totalPages };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowRight size='20px' />
						</Button>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default SimpleTable;
