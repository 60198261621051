import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { format, startOfToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CurrencyInput } from 'react-currency-mask';
import { useToasts } from 'react-toast-notifications';
import { BilletsApi, GetBilletDto, ReissueExpiredBilletRequest } from '../../../clients';
import SingleDatePicker from '../../../components/SingleDatePicker';
import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import { getApiAuthConfig } from '../../../services/api.service';
import { cnsMask } from '../../../services/mask.service';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';
import { DataValues } from '../interfaces/DataValues';

interface ReissueModalProps {
	isOpen: boolean;
	onClose: () => void;
	billet: GetBilletDto | null;
	dataValues: DataValues;
	setDataValues: Dispatch<SetStateAction<DataValues>>;
	fetchData: (dataValues: DataValues) => Promise<void>;
}

const ReissueModal: React.FC<ReissueModalProps> = (props: ReissueModalProps) => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const billetsApi = new BilletsApi(apiConfig);
	const [selectedReissueDate, setSelectedReissueDate] = useState<Date>(startOfToday());
	const [amount, setAmount] = useState<number | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			if (props.billet) {
				const ReissueExpiredBilletRequest: ReissueExpiredBilletRequest = {
					billetId: props.billet.id,
					reissueExpiredBilletDto: { delivery_date: selectedReissueDate?.toISOString(), value: amount ?? props?.billet?.amount },
				};
				await billetsApi.reissueExpiredBillet(ReissueExpiredBilletRequest);
			}
			addToast(`Boleto reemitido com sucesso.`, {
				appearance: 'success',
				autoDismiss: true,
			});
			const dataValues: DataValues = {
				...props.dataValues,
				currentPage: 1,
				paymentStatus: undefined,
				shippingStatus: undefined,
			};
			props.setDataValues(dataValues);
			props.fetchData(dataValues);
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar reemitir o boleto.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent maxWidth='800px'>
				<ModalHeader textAlign='center'>Reemitir Boleto</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box p='10px' border='1px solid gray' borderRadius='8px'>
						<Text fontWeight='bold' mb='10px'>
							Boleto Original
						</Text>
						<Text>
							<strong>Cartório:</strong> {props.billet?.seller_name}
						</Text>
						<Text>
							<strong>CNS:</strong> {cnsMask(props?.billet?.seller_cns || '')}
						</Text>
						<Text>
							<strong>Valor:</strong> R$ {centsToRealWithComma(props?.billet?.amount || 0)}
						</Text>
						<Text>
							<strong>Data de Expiração:</strong>{' '}
							{props.billet?.expires_at ? format(new Date(props.billet.expires_at), 'dd/MM/yyyy', { locale: ptBR }) : ''}
						</Text>
						<Text>
							<strong>Código de Barras:</strong> {props.billet?.barcode}
						</Text>
					</Box>
					<Box p='10px' border='1px solid gray' borderRadius='8px' mt={5}>
						<Text fontWeight='bold'>Dados para Reemissão</Text>
						<Flex mt={4} gap={4}>
							<FormControl>
								<FormLabel>Valor do Boleto</FormLabel>
								<CurrencyInput
									value={props?.billet?.amount ? props?.billet?.amount / 100 : 0}
									onChangeValue={(_event, originalValue) => {
										setAmount(Math.round(Number(originalValue) * 100));
									}}
									InputElement={<Input />}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Data de Vencimento</FormLabel>
								<SingleDatePicker selectedDate={selectedReissueDate} setSelectedDate={setSelectedReissueDate} />
							</FormControl>
						</Flex>
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme='red' onClick={props.onClose} mr={3}>
						Fechar
					</Button>
					<Button
						isLoading={isLoading}
						isDisabled={isLoading}
						backgroundColor={defaultColors.primaryColor}
						color='white'
						onClick={handleSubmit}
					>
						Reemitir
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ReissueModal;
