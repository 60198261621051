import React from 'react';

import { Spinner, Stack } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ConsultDebtsLinksApi, GetConsultDebtLinkDto } from '../../../clients';

import { getApiDefaultConfig } from '../../../services/api.service';

import { useConsultDebts } from '../../ConsultDebts/context/ConsultDebtsProvider';
import { useConsultVehicleHistory } from '../../ConsultVehicleHistory/context/ConsultVehicleHistoryProvider';

import { ConsultSelect } from '../components/ConsultSelect';

import { PagesEnum } from '../enums/pages.enum';

import ConsultDebts from '../../ConsultDebts';
import ConsultVehicleHistory from '../../ConsultVehicleHistory';

export const ConsultHome: React.FC = () => {
	const { setPayer: setPayerConsultDebts } = useConsultDebts();
	const { setPayer: setPayerConsultVehicleHistory } = useConsultVehicleHistory();
	const apiConfig = getApiDefaultConfig();
	const consultDebtsApi = new ConsultDebtsLinksApi(apiConfig);

	const [stage, setStage] = React.useState<PagesEnum>(PagesEnum.ConsultSelect);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [consultDebtsData, setConsultDebtsData] = React.useState<GetConsultDebtLinkDto | undefined>(undefined);
	const [showToast, setShowToast] = React.useState<boolean>(true);

	const location = useLocation();
	let { token } = useParams();
	const { addToast } = useToasts();

	const getToken = async () => {
		if (stage === PagesEnum.ConsultSelect) {
			setLoading(true);
		}
		if (token) {
			const response = await consultDebtsApi.getConsultDebtLink({ token });
			setConsultDebtsData(response);
			if (response.payer) {
				setPayerConsultDebts(response.payer);
				setPayerConsultVehicleHistory(response.payer);
			}
		}
		setLoading(false);
	};

	React.useEffect(() => {
		getToken();
		//eslint-disable-next-line
	}, [stage]);

	React.useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const success = queryParams.get('success');
		if (showToast) {
			if (success === 'true') {
				addToast(`Seu pagamento está sendo processado.`, {
					appearance: 'success',
					autoDismiss: true,
				});
				setShowToast(false);
			} else if (success === 'false') {
				addToast(`Erro ao realizar o pagamento.`, {
					appearance: 'error',
					autoDismiss: true,
				});
				setShowToast(false);
			}
		}
		queryParams.delete('success');
		window.history.replaceState(null, 'CNTV', location.pathname);
		//eslint-disable-next-line
	}, []);

	return (
		<>
			{loading ? (
				<Stack justify='center' align='center' height='76vh' w='100%'>
					<Spinner />
				</Stack>
			) : (
				<>
					{stage === PagesEnum.ConsultSelect && <ConsultSelect setStage={setStage} consultDebtsData={consultDebtsData} />}
					{stage === PagesEnum.ConsultVehicleHistory && <ConsultVehicleHistory consultDebtsData={consultDebtsData} setStage={setStage} />}
					{stage === PagesEnum.ConsultDebts && <ConsultDebts setStage={setStage} />}
				</>
			)}
		</>
	);
};
