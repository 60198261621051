import React from 'react';

import { Center, Flex, Image, Tab, TabList, TabPanel, TabPanels, TabProps, Tabs, Text } from '@chakra-ui/react';

import CreditCardIcon from '../../../assets/icons/credit-card.svg';
import QRCodeIcon from '../../../assets/icons/qr-code.svg';

import { PaymentForm } from '../types/paymentForm.type';

import { defaultColors } from '../../../config/variables';

import CreditCard from './CreditCard';
import QrCode from './QrCode';

type TabsWrapperProps = {
	formValues: PaymentForm;
	setStage: React.Dispatch<React.SetStateAction<number>>;
};

type CustomTabProps = {
	iconSrc: string;
	label: string;
	description: string;
} & TabProps;

const CustomTab: React.FC<CustomTabProps> = (props: CustomTabProps) => {
	const { iconSrc, label, description, ...rest } = props;

	return (
		<Tab
			{...rest}
			_hover={{ bg: '#F1FdFF' }}
			_selected={{ borderWidth: 2, borderColor: 'blue.500' }}
			backgroundColor='gray.100'
			border={`'1px solid ${defaultColors.primaryColor}`}
			rounded='md'
			mb='10px'
		>
			<Flex direction='column' alignItems='center'>
				<Image src={iconSrc} />
				<Text fontSize='md' fontWeight='semibold' align={['center', 'unset']} mb='4px'>
					{label}
				</Text>
				<Text fontSize='xs'>{description}</Text>
			</Flex>
		</Tab>
	);
};

const TabsWrapper: React.FC<TabsWrapperProps> = (props: TabsWrapperProps) => {
	const [tabIndex, setTabIndex] = React.useState<any>(0);

	const handleTabsChange = (index: number) => {
		setTabIndex(index)
	}

	return (
		<Tabs variant='enclosed' align='center' onChange={handleTabsChange}>
			<TabList flexDirection={[`column`, `unset`]} justifyContent='center' gap={[2, 20]}>
				<CustomTab
					label='Cartão de crédito'
					description='Confirmação instantânea'
					iconSrc={CreditCardIcon}
					className='custom-tab__credit'
				/>
				
					<CustomTab label='PIX QR code' description='Confirmação instantânea' iconSrc={QRCodeIcon} className='custom-tab__qrcode' />
				
			</TabList>
			<Center my='16px'>
				<Text fontSize='lg' fontWeight='semibold' align={['center', 'unset']}>
					Resumo do pagamento
				</Text>
			</Center>
			<TabPanels>
				<TabPanel p='0' className='tab-panel__credit'>
					<CreditCard formValues={props.formValues} />
				</TabPanel>

				<TabPanel p='0' className='tab-panel__qrcode'>
					<QrCode formValues={props.formValues} tabIndex={tabIndex} setStage={props.setStage} />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}

export default TabsWrapper;
