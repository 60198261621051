/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReissueExpiredBilletDto
 */
export interface ReissueExpiredBilletDto {
    /**
     * 
     * @type {number}
     * @memberof ReissueExpiredBilletDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof ReissueExpiredBilletDto
     */
    delivery_date: string;
}

/**
 * Check if a given object implements the ReissueExpiredBilletDto interface.
 */
export function instanceOfReissueExpiredBilletDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "delivery_date" in value;

    return isInstance;
}

export function ReissueExpiredBilletDtoFromJSON(json: any): ReissueExpiredBilletDto {
    return ReissueExpiredBilletDtoFromJSONTyped(json, false);
}

export function ReissueExpiredBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReissueExpiredBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'delivery_date': json['delivery_date'],
    };
}

export function ReissueExpiredBilletDtoToJSON(value?: ReissueExpiredBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'delivery_date': value.delivery_date,
    };
}

