import * as yup from 'yup';

import { cpfCnpjValidator } from '../../../services/cpfCnpj.service';

const cpfCnpjSchema = yup
	.string()
	.transform((value) => (value ? value.replace(/[^\d]+/g, '') : ''))
	.test('is-valid-cpf-cnpj', 'Documento inválido', function (value) {
		if (value) {
			return cpfCnpjValidator(value as string);
		}
		return true;
	});

export const schema = yup.object().shape({
	email: yup.string().required('Campo obrigatório').email('Digite um e-mail válido'),
	name: yup
		.string()
		.matches(/(\w+\s\w+)/, 'Digite o nome completo')
		.required('Campo obrigatório'),
	document: cpfCnpjSchema.required('Campo obrigatório'),
	zipcode: yup.string().required('Campo obrigatório'),
	street: yup.string().required('Campo obrigatório'),
	number: yup.string().required('Campo obrigatório'),
	city: yup.string().required('Campo obrigatório'),
	district: yup.string().required('Campo obrigatório'),
	state: yup.string().required('Campo obrigatório'),
});
