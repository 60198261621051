export function utcToBrazillianEndDate(endDate: Date): Date {
	const hours = endDate.getUTCHours();
	const minutes = endDate.getUTCMinutes();
	const seconds = endDate.getUTCSeconds();

	if (hours === 2 && minutes === 59 && seconds === 59) {
		return new Date(endDate.getTime() - 3 * 60 * 60 * 1000);
	}

	return endDate;
}
