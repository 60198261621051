import { Select as ChakraSelect, Flex, InputGroup, InputLeftAddon, Link, Spinner, Stack } from '@chakra-ui/react';
import React from 'react';

import { Formik } from 'formik';
import { usePubNub } from 'pubnub-react';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import {
	GetBatchesDto,
	GetBatchesStatusEnum,
	SearchBatchApi,
	SearchBatchStatusEnum,
	SellersApi,
	VehicleBatchRequest,
	VehiclesApi,
} from '../../clients';
import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import RangeDatePicker, { IPeriod } from '../../components/RangeDatePicker';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { defaultColors } from '../../config/variables';
import { useAuth } from '../../contexts/AuthProvider';
import { getApiAuthConfig } from '../../services/api.service';
import { RealTimeChannel } from '../../services/real-time.service';
import { SellersOptions } from '../Reports/interfaces/SellersOptions';
import { customStyle } from '../Reports/styles/SelectStyle';
import SimpleTable from './components/Table';
import UploadForm from './components/UploadForm';
import { DataValues } from './interfaces/DataValues';

const SearchBatch: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const vehiclesApi = new VehiclesApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);
	const searchBatchApi = new SearchBatchApi(apiConfig);

	const { addToast } = useToasts();
	const pubNub = usePubNub();
	const { isBackoffice, user } = useAuth();

	const [isUploadLoading, setIsUploadLoading] = React.useState<boolean>(false);
	const [isSearchLoading, setIsSearchingLoading] = React.useState<boolean>(false);
	const [batchesData, setBatchesData] = React.useState<GetBatchesDto | undefined>(undefined);
	const [file, setFile] = React.useState<File | null>(null);
	const [email, setEmail] = React.useState<string>('');
	const [sellersData, setSellersData] = React.useState<SellersOptions[] | undefined>(undefined);
	const [sellerIds, setSellerIds] = React.useState<SellersOptions[] | undefined>([]);
	const [status, setStatus] = React.useState<GetBatchesStatusEnum | undefined>(undefined);
	const [currentPage, setCurrentPage] = React.useState<number>(1);
	const [limit, setLimit] = React.useState<number>(10);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);
	const [dataValues, setDataValues] = React.useState<DataValues>({
		startDate: period[0].startDate.toString(),
		endDate: period[0].endDate.toString(),
		sellerIds: [],
		limit,
		currentPage,
		status,
	});

	const initialUploadValues = { email };
	const initialSearchValues = {
		period,
		sellerIds,
		status,
	};

	const statusTranslations = {
		[SearchBatchStatusEnum.Pending]: 'Pendente',
		[SearchBatchStatusEnum.Success]: 'Sucesso',
		[SearchBatchStatusEnum.Failed]: 'Falha',
	};

	const handleUploadFormSubmit = async () => {
		setIsUploadLoading(true);
		try {
			if (file) {
				const vehicleBatchRequest: VehicleBatchRequest = {
					file: file,
					name: file.name,
					contentType: file.type,
					email: email,
				};

				await vehiclesApi.vehicleBatch(vehicleBatchRequest);

				addToast('Lote enviado com sucesso. Aguarde o recebimento dos resultados.', {
					appearance: 'success',
					autoDismiss: true,
				});
				setFile(null);
				setEmail('');
				(document.getElementById('file-upload') as HTMLInputElement).value = '';
				await fetchData(dataValues);
			}
		} catch (error: any) {
			if (error.response.status === 409) {
				addToast('Já existe um lote pendente enviado. Caso necessário, entre em contato com o suporte.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
			if (error.response.status === 422) {
				addToast('O arquivo enviado contém informações inválidas. Envie o lote conforme o modelo disponibilizado acima.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
			else {
				addToast('Falha ao enviar o lote.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
		setIsUploadLoading(false);
	};

	async function fetchSellersData() {
		const sellersResponse = await sellersApi.getSellers();
		const parsedSellers = sellersResponse.map((seller) => ({ value: seller.cns, label: seller.name, id: seller.id }));
		setSellersData(parsedSellers);
	}

	async function fetchData(dataValues: DataValues) {
		setIsSearchingLoading(true);
		const batchesResponse = await searchBatchApi.getBatches({
			startDate: dataValues?.startDate,
			endDate: dataValues?.endDate,
			limit: dataValues.limit,
			currentPage: dataValues.currentPage,
			sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			status: dataValues?.status,
		});
		setBatchesData(batchesResponse);
		setIsSearchingLoading(false);
	}

	const handleFormSubmit = async () => {
		const startDate = period[0].startDate.toString();
		const endDate = period[0].endDate.toString();
		const currentPage = 1;
		const dataValues = { startDate, endDate, sellerIds, limit, currentPage, status };
		setDataValues(dataValues);
		setCurrentPage(1);
		fetchData(dataValues);
	};

	const updateBatchStatus = () => {
		const channels = pubNub.getSubscribedChannels().filter((channel) => channel !== RealTimeChannel.MAINTENANCE_MODE);
		pubNub.unsubscribe({ channels });
		pubNub.subscribe({ channels: [user.seller.id] });
		pubNub.addListener({
			message(event) {
				const { message } = event;
				const { search_batch } = message;
				const { updatedBatch } = search_batch;
				const updatedBatches = batchesData?.results.map((batch) => {
					if (batch.id === updatedBatch.id) {
						return {
							...batch,
							status: updatedBatch.status,
							url: updatedBatch.url,
						};
					}
					return batch;
				});

				if (updatedBatches) {
					setBatchesData({ ...(batchesData as GetBatchesDto), results: updatedBatches });
				}
			},
		});
	};

	React.useEffect(() => {
		if (isBackoffice) {
			fetchSellersData();
		}
		fetchData(dataValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		updateBatchStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pubNub, batchesData]);

	return (
		<Flex padding={['10px', '50px']} flexDirection='column' minH='88%' overflowX='auto'>
			<Flex marginBottom='40px' borderBottom='2px solid black' maxW={['100%', '100%', '70%']}>
				<Title fontSize={['lg', 'lg', '3xl']} fontWeight={350}>
					Consulta Veicular em Lote
				</Title>
			</Flex>
			<Text textAlign={['justify', 'start']} marginBottom='20px'>
				Caro notário(a),{' '}
				<Link href={process.env.REACT_APP_VEHICLE_BATCH_MODEL} color={defaultColors.primaryColor} isExternal target='_blank'>
					clique aqui
				</Link>{' '}
				para obter um documento modelo de envio de consultas em lote. Não insira quaisquer informações adicionais ao enviar o documento,
				apenas digite a placa e o tipo de documento nas respectivas colunas.
			</Text>
			<Stack mb={4} alignItems={['center', 'center', 'start']} flexDirection='column' w='100%'>
				<UploadForm
					email={email}
					file={file}
					handleFormSubmit={handleUploadFormSubmit}
					initialValues={initialUploadValues}
					isLoading={isUploadLoading}
					setEmail={setEmail}
					setFile={setFile}
				/>
			</Stack>
			<Flex textAlign={['justify', 'start']} marginY='20px' fontStyle='italic' fontSize='13px' flexDirection='row' gap='5px'>
				<Text color='red'>*</Text>
				<Text>
					Esta página é destinada para a realização de consultas em grandes quantidades, caso for realizar consultas individuais ou em
					pequena quantidade, realize na página de Consulta Veicular.
				</Text>
			</Flex>

			<Flex flexDirection='column' w='100%' borderTop='1px solid black' minH='290px'>
				<Formik enableReinitialize initialValues={initialSearchValues} onSubmit={handleFormSubmit}>
					{({ handleSubmit }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Stack
									direction={['column', 'column', 'row']}
									alignItems={['center', 'center', 'flex-start']}
									spacing='20px'
									mb='20px'
									mt='40px'
									w='100%'
									justify='flex-start'
									wrap='wrap'
								>
									<Flex>
										<FormControl>
											<RangeDatePicker
												id='input__date-picker'
												name='period'
												size='md'
												p='24px'
												period={period}
												setPeriod={setPeriod}
												handleSubmit={() => {}}
											/>
										</FormControl>
									</Flex>

									{isBackoffice && (
										<Flex w='200px'>
											<FormControl>
												<Select
													id='input__cns-name'
													name='cns'
													placeholder='Buscar'
													isMulti
													styles={customStyle}
													options={sellersData}
													onChange={(e) => setSellerIds(e as SellersOptions[])}
												/>
											</FormControl>
										</Flex>
									)}

									<Stack spacing={4} w='200px'>
										<InputGroup size='md' border='2px solid black' borderRadius='15px'>
											<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
												Status
											</InputLeftAddon>
											<ChakraSelect
												name='success'
												id='input__success'
												borderRadius='0px 15px 15px 0px'
												onChange={(e) => {
													setStatus(e.target.value as GetBatchesStatusEnum);
												}}
												border='none'
												value={status}
											>
												<option value=''>Todos</option>
												{(Object.keys(SearchBatchStatusEnum) as Array<keyof typeof SearchBatchStatusEnum>).map((key) => (
													<option key={key} value={SearchBatchStatusEnum[key]}>
														{statusTranslations[SearchBatchStatusEnum[key]]}
													</option>
												))}
											</ChakraSelect>
										</InputGroup>
									</Stack>

									<Flex maxW='200px' flex='0 1 auto'>
										<FormControl>
											<Button
												id='button__search'
												type='submit'
												size='md'
												isLoading={isSearchLoading}
												border={`2px solid ${defaultColors.secondaryColor}`}
												bgColor={defaultColors.primaryColor}
												borderRadius='10px'
												w='100%'
											>
												Consultar
											</Button>
										</FormControl>
									</Flex>
								</Stack>
							</form>
						);
					}}
				</Formik>
				<Stack mb={4} alignItems='center'>
					{isSearchLoading ? (
						<Spinner size='lg' mt='40px' />
					) : (
						<SimpleTable
							limit={limit}
							currentPage={currentPage}
							fetchData={fetchData}
							setCurrentPage={setCurrentPage}
							setLimit={setLimit}
							setDataValues={setDataValues}
							dataValues={dataValues}
							batches={batchesData}
							body={batchesData?.results}
							isBackoffice={isBackoffice}
						/>
					)}
				</Stack>
			</Flex>
		</Flex>
	);
};

export default SearchBatch;
