import React, { useEffect } from 'react';

import { ListItem, Spinner, Stack, UnorderedList } from '@chakra-ui/react';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useToasts } from 'react-toast-notifications';

import { GetConsultDebtLinkDto, PaymentsApi } from '../../clients';
import { useConsultVehicleHistory } from './context/ConsultVehicleHistoryProvider';

import { PagesEnum } from '../Consult/enums/pages.enum';

import { getApiDefaultConfig } from '../../services/api.service';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Checkout from './components/Checkout';
import PDFConsultVehicleHistory from '../../components/PDFConsultVehicleHistory';

type ConsultVehicleHistoryProps = {
	consultDebtsData: GetConsultDebtLinkDto | undefined;
	setStage: React.Dispatch<React.SetStateAction<number>>;
};

const ConsultVehicleHistory: React.FC<ConsultVehicleHistoryProps> = (props: ConsultVehicleHistoryProps) => {
	const { setSimulation, linkPaid, showDocument, setShowDocument } = useConsultVehicleHistory();
	const apiConfig = getApiDefaultConfig();
	const paymentsApi = new PaymentsApi(apiConfig);
	const { addToast } = useToasts();

	const [simulationloading, setSimulationLoading] = React.useState<boolean>(true);

	async function getSimulation() {
		try {
			if (props.consultDebtsData?.consult_value) {
				const simulationResponse = await paymentsApi.listSimulation({ amountCents: props.consultDebtsData?.consult_value });
				setSimulation(simulationResponse);
			}
		} catch (simulationError) {
			addToast('Erro ao gerar a simulação dos valores.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSimulationLoading(false);
		}
	}


	useEffect(() => {
		if (props.consultDebtsData?.is_paid) {
			setShowDocument(true);
			setSimulationLoading(false);
			
			return;
		}
		getSimulation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (linkPaid && showDocument) {
		
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDocument, linkPaid]);

	return (
		<>
			{!simulationloading ? (
				<Stack minH='76vh' w='100%'>
					<Stack p='15px' alignItems={['center', 'start']}>
						<Button
							variant='unset'
							bgColor='transparent'
							color='black'
							onClick={() => {
								props.setStage(PagesEnum.ConsultSelect);
							}}
						>
							<MdOutlineKeyboardBackspace size={30} />
							<Text> Voltar</Text>
						</Button>
					</Stack>

					{!props.consultDebtsData?.is_paid && (
						<>
							{!showDocument && (
								<Stack gap='50px' p={[5, 0]}>
									<Stack alignItems='center'>
										<Title fontSize='24px'>Consulta Veicular Completa</Title>

										<UnorderedList fontFamily='montserrat' marginTop='20px'>
											<ListItem> Dados cadastrais.</ListItem>
											<ListItem> Cadastro nacional.</ListItem>
											<ListItem> Cadastro estadual.</ListItem>
											<ListItem> Restrições e impedimentos (nacional e estadual).</ListItem>
											<ListItem> Gráfico de preço.</ListItem>
											<ListItem> Precificador.</ListItem>
											<ListItem> Dados de Leilão.</ListItem>
											<ListItem> Score de leilão.</ListItem>
											<ListItem> Indício de Sinistro.</ListItem>
											<ListItem> Histórico de roubo e Furto.</ListItem>
											<ListItem> Recall.</ListItem>
											<ListItem> Histórico de Gravame.</ListItem>
											<ListItem> Multas.</ListItem>
											<ListItem> CSV (Certificado de segurança veicular - Inmetro).</ListItem>
											<ListItem> Multas Renainf.</ListItem>
										</UnorderedList>
									</Stack>
									<Checkout />
								</Stack>
							)}
						</>
					)}
					{showDocument && <PDFConsultVehicleHistory url={''} />}
				</Stack>
			) : (
				<Stack justify='center' align='center' height='76vh' w='100%'>
					<Spinner />
					<Text textAlign='center'>Carregando consulta veicular, aguarde</Text>
				</Stack>
			)}
		</>
	);
};

export default ConsultVehicleHistory;
