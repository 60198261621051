import React from 'react';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	List,
	ListItem,
	MenuButton,
	Menu as ChakraMenu,
	MenuList,
	MenuItem,
	MenuOptionGroup,
	MenuItemOption,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoMenuOutline } from 'react-icons/io5';

import Text from '../../components/Text';
import { FaWhatsapp } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthProvider';

type Links = {
	title: string;
	link: string;
	submenus: {
		title: string;
		link: string;
		action?: any;
	}[];
}[];

const initialLinks: Links = [
	{
		title: 'Gestão',
		link: '',
		submenus: [
			{
				title: 'Home',
				link: '/admin',
			},
			{
				title: 'Resumo de Consultas',
				link: '/admin/resumo',
			},
			{
				title: 'Consulta Veicular',
				link: '/admin/busca',
			},
			{
				title: 'Consulta em Lote',
				link: '/admin/lote',
			},
			{
				title: 'Histórico de Consultas',
				link: '/admin/historico',
			},
			{
				title: 'Financeiro',
				link: '/admin/financeiro',
			},
		],
	},
	{
		title: 'Backoffice',
		link: '',
		submenus: [
			{
				title: 'Cadastrar Cartórios',
				link: '/admin/cadastro-cartorios',
			},
		],
	},
	{
		title: 'Ajuda',
		link: '',
		submenus: [
			{
				title: 'Tutorial',
				action: (url: string) => {
					handleSupportClick(url);
				},
				link: 'https://parcela-cntv-api-prod-uploads.s3.amazonaws.com/help/Tutorial.pdf',
			},
			{
				title: 'FAQ',
				action: (url: string) => {
					handleSupportClick(url);
				},
				link: 'https://parcela-cntv-api-prod-uploads.s3.amazonaws.com/help/FAQ.pdf',
			},
		],
	},
];

const whatsappMessage = 'https://wa.me/5531982598606?text=Olá,%20preciso%20de%20suporte%20com%20o%20CNTV.%20Poderia%20me%20ajudar?';

const handleSupportClick = (link: string) => {
	window.open(link, '_blank');
};

const Menu: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { isBackoffice } = useAuth()

	const links = isBackoffice ? initialLinks : initialLinks.filter(link => link.title !== 'Backoffice');

	return (
		<Flex minH='100%' padding='30px' display={['none', 'none', 'flex']} zIndex={2} flexDirection='column' justifyContent='space-between'>
			<List spacing='12px' minWidth='250px' borderRight='0.2px #ccc solid'>
				{links &&
					links.map((link, index) => {
						if (link.submenus && link.submenus.length) {
							return (
								<Accordion border='none' allowToggle defaultIndex={0} key={index}>
									<AccordionItem border='none'>
										<AccordionButton>
											<Flex as='span' flex='1' textAlign='left' fontWeight='bolder' fontStyle='italic' fontSize='22px'>
												<Text>{link.title}:</Text>
											</Flex>
											<AccordionIcon />
										</AccordionButton>
										<AccordionPanel pb='16px'>
											{link.submenus.map((submenu, i) => (
												<ListItem
													key={i}
													backgroundColor={location.pathname === submenu.link ? '#ECFFD9' : 'none'}
													_hover={{ backgroundColor: '#f6ffed' }}
													paddingLeft='10px'
													paddingY='7px'
													onClick={() => {
														if (!submenu?.action) {
															navigate(submenu.link);
														} else {
															submenu.action(submenu.link);
														}
													}}
													cursor='pointer'
													borderRadius='3px'
												>
													{!submenu.action ? (
														<Link to={submenu.link}>
															<Text>{submenu.title}</Text>
														</Link>
													) : (
														<Text>{submenu.title}</Text>
													)}
												</ListItem>
											))}
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							);
						}
						return (
							<ListItem
								key={index}
								backgroundColor={location.pathname === link.link ? '#ECFFD9' : 'none'}
								_hover={{ backgroundColor: '#f6ffed' }}
								paddingLeft='10px'
								paddingY='7px'
								onClick={() => navigate(link.link)}
								cursor='pointer'
								borderRadius='3px'
							>
								<Link to={link.link}>{link.title}</Link>
							</ListItem>
						);
					})}
			</List>
			<Flex gap='5px' alignItems='center'>
				<FaWhatsapp color='green' />
				<Text
					cursor='pointer'
					onClick={() => {
						handleSupportClick(whatsappMessage);
					}}
				>
					Suporte WhatsApp
				</Text>
			</Flex>
		</Flex>
	);
};

export default Menu;

export const HamburguerMenu: React.FC = () => {
	const location = useLocation();
	const { isBackoffice } = useAuth()

	const links = isBackoffice ? initialLinks : initialLinks.filter(link => link.title !== 'Backoffice');
	
	return (
		<Flex p={2} display='flex'>
			<ChakraMenu>
				<MenuButton px={3} py={3} transition='all 0.2s' borderRadius='md' borderWidth='1px'>
					<IoMenuOutline />
				</MenuButton>
				<MenuList color={'black'}>
					{links &&
						links.map((link, index) => {
							if (link.submenus && link.submenus.length) {
								return (
									<>
										<MenuOptionGroup title={link.title} type='radio' />
										{link.submenus.map((submenu, i) => (
											<MenuItemOption value={submenu.link} _focus={{ backgroundColor: 'transparent' }}>
												{!submenu.action ? (
													<Link to={submenu.link}>
														<Text p={2} borderRadius={5} backgroundColor={location.pathname === submenu.link ? '#ECFFD9' : 'none'}>
															{submenu.title}
														</Text>
													</Link>
												) : (
													<Text onClick={() => handleSupportClick(submenu.link)} p={2} borderRadius={5} backgroundColor={location.pathname === submenu.link ? '#ECFFD9' : 'none'}>
														{submenu.title}
													</Text>
												)}
											</MenuItemOption>
										))}
									</>
								);
							}
							return (
								<MenuItem>
									<Link to={link.link}>
										<Text>{link.title}</Text>
									</Link>
								</MenuItem>
							);
						})}
					<Flex gap='5px' alignItems='center' p={2}>
						<FaWhatsapp color='green' />
						<Text
							cursor='pointer'
							onClick={() => {
								handleSupportClick(whatsappMessage);
							}}
						>
							Suporte WhatsApp
						</Text>
					</Flex>
				</MenuList>
			</ChakraMenu>
		</Flex>
	);
};
