import React from 'react';

import { ErrorMessage, ErrorMessageProps } from 'formik';

import Text from '../Text';

type FormErrorMessageProps = ErrorMessageProps;

const FormErrorMessage = (props: FormErrorMessageProps) => {
	return (
		<ErrorMessage {...props} render={msg => <Text fontSize='sm' color='red.500'>{msg}</Text>}/>
	);
};

export default FormErrorMessage;