import React from 'react';

import { Box, Flex, Spinner, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import Select from 'react-select';

import { GetSummaryByDateResponseDto, SellersApi, SummariesApi } from '../../clients';

import { getApiAuthConfig } from '../../services/api.service';
import { moneyMask } from '../../services/mask.service';

import RangeDatePicker, { IPeriod } from '../../components/RangeDatePicker';
import Text from '../../components/Text';
import Card from './components/Card';
import QuickFilters from './components/QuickFilters';
import { utcToBrazillianEndDate } from '../../services/utc-to-brazillian-end-date.service';
import { useAuth } from '../../contexts/AuthProvider';
import { customStyle } from '../Reports/styles/SelectStyle';
import { SellersOptions } from '../Reports/interfaces/SellersOptions';

const Summary: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const summaryApi = new SummariesApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);
	const { addToast } = useToasts();
	const {isBackoffice} = useAuth()

	const isMobile = useBreakpointValue({ base: false, md: true });
	const [summaryData, setSummaryData] = React.useState<GetSummaryByDateResponseDto | undefined>(undefined);
	const [sellersData, setSellersData] = React.useState<SellersOptions[] | undefined>(undefined);
	const [sellerIds, setSellerIds] = React.useState<SellersOptions[] | undefined>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [quickDateChange, setQuickDateChange] = React.useState<boolean>(false);
	const [selectedFilter, setSelectedFilter] = React.useState<number>(1);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	async function fetchSellersData() {
		const sellersResponse = await sellersApi.getSellers();
		const parsedSellers = sellersResponse.map((seller) => ({ value: seller.cns, label: seller.name, id: seller.id }));
		setSellersData(parsedSellers);
	}

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const data: GetSummaryByDateResponseDto = await summaryApi.getSummaryByDate({
				startDate: period[0].startDate.toString(),
				endDate: utcToBrazillianEndDate(period[0].endDate).toString(),
				sellerIds: sellerIds?.map((seller) => seller.id).join(',')
			});

			setSummaryData(data);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quickDateChange, sellerIds]);

	React.useEffect(() => {
		if (isBackoffice) {
			fetchSellersData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex height='100%' width='100%' flexDirection='column' p={['5px', '20px']} overflowX='auto'>
			{isBackoffice && (
					<Flex w='300px' justifyContent={['center', 'start']}>
						<Select
							id='input__cns-name'
							name='cns'
							placeholder='Buscar'
							isMulti
							styles={customStyle}
							options={sellersData}
							onChange={(e) => setSellerIds(e as SellersOptions[])}
						/>
					</Flex>
				)}
			{isLoading ? (
				<Stack w='100%' h='100%' p='10px' flexDirection='row'>
					<Spinner />
					<Text>Buscando consultas, aguarde.</Text>
				</Stack>
			) : (
				<Flex gap={5} mb={5} justifyContent={['center', 'start']} alignItems='center'>
					<Box position='relative'>
						<RangeDatePicker
							setPeriod={setPeriod}
							period={period}
							setFieldValue={() => {}}
							handleSubmit={() => {
								fetchData();
								setSelectedFilter(0);
							}}
							backgroundColor='primary'
							borderWidth={1}
							borderColor='white'
							py='5'
							mt='5px'
							width='100%'
							textAlign='left'
							fontSize={['sm', null, null, null, 'md']}
						/>
					</Box>
					{isMobile && (
						<QuickFilters
							selectedFilter={selectedFilter}
							setPeriod={setPeriod}
							setQuickDateChange={setQuickDateChange}
							setSelectedFilter={setSelectedFilter}
						/>
					)}
				</Flex>
			)}

			<Flex height='100%' width='100%' flexDirection='column' p='5px'>
				<Card
					img={require('../../assets/images/icon-consultas.png')}
					title='Consultas'
					highlighted='Consolidadas'
					value={moneyMask(parseInt(summaryData?.total_value?.toString() || '0') / 100)}
					total={summaryData?.total || 0}
					totalInvoiced={summaryData?.total_invoiced || 0}
					isLoading={isLoading}
					marginBottom='5px'
				/>
				<Flex flexDirection={['column', 'column', 'row']}>
					<Card
						img={require('../../assets/images/icon-atpve.png')}
						title='Consultas de'
						highlighted='ATPVe'
						value={moneyMask(parseInt(summaryData?.total_atpve_value?.toString() || '0') / 100)}
						total={summaryData?.total_atpve || 0}
						totalInvoiced={summaryData?.total_atpve_invoiced  || 0}
						isLoading={isLoading}
						marginRight='5px'
					/>
					<Card
						img={require('../../assets/images/icon-crv.png')}
						title='Consultas de'
						highlighted='CRV'
						value={moneyMask(parseInt(summaryData?.total_crv_value?.toString() || '0') / 100)}
						total={summaryData?.total_crv || 0}
						totalInvoiced={summaryData?.total_crv_invoiced  || 0}
						marginY={['5px', '5px', '0px']}
						isLoading={isLoading}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Summary;
