import * as React from 'react';

import { setSessionCookie, getSessionCookie, destroySessionCookie } from '../session';
import { getApiDefaultConfig, getApiAuthConfig } from '../services/api.service';
import { clear as clearUserSession, persist as persistUserSession, getAll as getUserSessionData } from '../services/local-storage.service';

import {
	UserLoginDto,
	AuthApi,
	LoginRequest,
	UserLoginResponseDtoTypeEnum,
	UserLoginResponseDto,
	UserRefreshTokenResponseDto,
} from '../clients';

interface AuthContextData {
	isBackoffice: boolean;
	signed: boolean;
	user: any;
	updateUser: (user: any) => void;
	login: (user: any) => Promise<UserLoginResponseDto>;
	logout: () => void;
	refresh: (user: any) => void;
	refreshToken: () => Promise<UserRefreshTokenResponseDto>;
	getTokenExpirationTime: () => number;
	isTokenExpired: () => boolean;
	getToken(): string | null;
}

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<any> = ({ children }) => {
	const [user, setUser] = React.useState(getSessionCookie());
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isUpdated, setIsUpdated] = React.useState<boolean>(false);

	React.useEffect(() => {
		const session = getSessionCookie();

		if (session) {
			const userData = getUserSessionData();
			const parsedUserData = { ...session, ...userData };

			setUser(parsedUserData);
		}

		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (isUpdated) {
			const session = getSessionCookie();

			if (session) {
				const userData = getUserSessionData();

				setUser({ ...session, ...userData });
			}
			setIsUpdated(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUpdated]);

	function storeSessionCookie(response: UserLoginResponseDto) {
		const sessionCookie = {
			id_token: response.id_token,
			refresh_token: response.refresh_token,
			type: response.type,
		};

		setSessionCookie(sessionCookie);
	}

	function storeUserInfo(response: UserLoginResponseDto) {
		const { id_token, refresh_token, type, ...parsedResponse } = response;

		persistUserSession(parsedResponse);
	}

	function getParsedUserData() {
		const session = getSessionCookie();
		const userData = getUserSessionData();
		const parsedUserData = { ...session, ...userData };

		return parsedUserData;
	}

	async function login(userData: UserLoginDto): Promise<UserLoginResponseDto> {
		const apiConfig = getApiDefaultConfig();

		const loginRequest: LoginRequest = {
			userLoginDto: userData,
		};

		const authApi = new AuthApi(apiConfig);
		const response = await authApi.login(loginRequest);

		storeUserInfo(response);
		storeSessionCookie(response);

		setUser(response);

		return response;
	}

	async function refresh(userData: any) {
		storeUserInfo(userData);
		storeSessionCookie(userData);

		setUser(userData);

		setIsUpdated(true);
	}

	function getToken(): string | null {
		const parsedUserData = getParsedUserData();

		if (user) {
			return parsedUserData.id_token;
		}
		return null;
	}

	function getTokenExpirationTime(): number {
		const parsedUserData = getParsedUserData();

		if (parsedUserData && parsedUserData.id_token) {
			return JSON.parse(atob(parsedUserData.id_token.split('.')[1])).exp * 1000;
		}
		return -1;
	}

	function isTokenExpired(): boolean {
		const parsedUserData = getParsedUserData();

		if (parsedUserData && parsedUserData.id_token) {
			return Date.now() >= getTokenExpirationTime();
		}
		return false;
	}

	async function refreshToken(): Promise<UserRefreshTokenResponseDto> {
		const apiConfig = getApiAuthConfig();
		const authApi = new AuthApi(apiConfig);

		const parsedUserData = getParsedUserData();

		const { refresh_token, id_token } = await authApi.refreshToken({
			userRefreshTokenDto: {
				refresh_token: parsedUserData.refresh_token,
			},
		});

		const tokenData = {
			id_token,
			refresh_token,
		};

		parsedUserData.id_token = id_token;
		parsedUserData.refresh_token = refresh_token;

		storeUserInfo(parsedUserData);
		storeSessionCookie(parsedUserData);

		return tokenData;
	}

	function logout() {
		destroySessionCookie();
		setUser(null);
		clearUserSession();
	}

	function updateUser(user: any) {
		storeUserInfo(user);
		storeSessionCookie(user);
		setUser(user);
	}

	return (
		<AuthContext.Provider
			value={{
				isBackoffice: !!(user?.type === UserLoginResponseDtoTypeEnum.Backoffice),
				signed: !!user,
				user,
				updateUser,
				login,
				logout,
				refresh,
				refreshToken,
				isTokenExpired,
				getTokenExpirationTime,
				getToken,
			}}
		>
			{!isLoading && children}
		</AuthContext.Provider>
	);
};

export function useAuth() {
	const context = React.useContext(AuthContext);

	return context;
}
