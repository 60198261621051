/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDebts } from './GetDebts';
import {
    GetDebtsFromJSON,
    GetDebtsFromJSONTyped,
    GetDebtsToJSON,
} from './GetDebts';

/**
 * 
 * @export
 * @interface GetDebtsListResponseDto
 */
export interface GetDebtsListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    consult_debts_link_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    uf_jurisdiction?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    status?: GetDebtsListResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    vehicle_model?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDebtsListResponseDto
     */
    year_model?: number;
    /**
     * 
     * @type {number}
     * @memberof GetDebtsListResponseDto
     */
    year_manufacture?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    license_plate: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    chassi?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    renavam?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    motor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    color_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    document_type?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    owner_document?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    owner_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    license_plate_city?: string;
    /**
     * 
     * @type {Array<GetDebts>}
     * @memberof GetDebtsListResponseDto
     */
    debts?: Array<GetDebts>;
    /**
     * 
     * @type {string}
     * @memberof GetDebtsListResponseDto
     */
    created_at?: string;
}


/**
 * @export
 */
export const GetDebtsListResponseDtoStatusEnum = {
    Pending: 'pending',
    Succeeded: 'succeeded',
    Failed: 'failed'
} as const;
export type GetDebtsListResponseDtoStatusEnum = typeof GetDebtsListResponseDtoStatusEnum[keyof typeof GetDebtsListResponseDtoStatusEnum];


/**
 * Check if a given object implements the GetDebtsListResponseDto interface.
 */
export function instanceOfGetDebtsListResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "license_plate" in value;
    isInstance = isInstance && "owner_name" in value;

    return isInstance;
}

export function GetDebtsListResponseDtoFromJSON(json: any): GetDebtsListResponseDto {
    return GetDebtsListResponseDtoFromJSONTyped(json, false);
}

export function GetDebtsListResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDebtsListResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'consult_debts_link_id': !exists(json, 'consult_debts_link_id') ? undefined : json['consult_debts_link_id'],
        'uf_jurisdiction': !exists(json, 'uf_jurisdiction') ? undefined : json['uf_jurisdiction'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'vehicle_model': !exists(json, 'vehicle_model') ? undefined : json['vehicle_model'],
        'year_model': !exists(json, 'year_model') ? undefined : json['year_model'],
        'year_manufacture': !exists(json, 'year_manufacture') ? undefined : json['year_manufacture'],
        'license_plate': json['license_plate'],
        'chassi': !exists(json, 'chassi') ? undefined : json['chassi'],
        'renavam': !exists(json, 'renavam') ? undefined : json['renavam'],
        'motor': !exists(json, 'motor') ? undefined : json['motor'],
        'color_name': !exists(json, 'color_name') ? undefined : json['color_name'],
        'document_type': !exists(json, 'document_type') ? undefined : json['document_type'],
        'owner_document': !exists(json, 'owner_document') ? undefined : json['owner_document'],
        'owner_name': json['owner_name'],
        'license_plate_city': !exists(json, 'license_plate_city') ? undefined : json['license_plate_city'],
        'debts': !exists(json, 'debts') ? undefined : ((json['debts'] as Array<any>).map(GetDebtsFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function GetDebtsListResponseDtoToJSON(value?: GetDebtsListResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'consult_debts_link_id': value.consult_debts_link_id,
        'uf_jurisdiction': value.uf_jurisdiction,
        'state': value.state,
        'status': value.status,
        'vehicle_model': value.vehicle_model,
        'year_model': value.year_model,
        'year_manufacture': value.year_manufacture,
        'license_plate': value.license_plate,
        'chassi': value.chassi,
        'renavam': value.renavam,
        'motor': value.motor,
        'color_name': value.color_name,
        'document_type': value.document_type,
        'owner_document': value.owner_document,
        'owner_name': value.owner_name,
        'license_plate_city': value.license_plate_city,
        'debts': value.debts === undefined ? undefined : ((value.debts as Array<any>).map(GetDebtsToJSON)),
        'created_at': value.created_at,
    };
}

