import React from 'react';

import { Flex, FlexProps, Image, Spinner } from '@chakra-ui/react';

import Text from '../../../components/Text';

type CardProps = FlexProps & {
	title: string;
	highlighted: string;
	img: string;
	value: string;
	total: number;
	totalInvoiced: number;
	isLoading: boolean;
	isRow?: boolean;
};

const Card: React.FC<CardProps> = (props: CardProps) => {
	return (
		<Flex
			flexDirection='column'
			alignItems='center'
			width='100%'
			minHeight='170px'
			backgroundColor='#f6ffed'
			border='2px solid #ccc'
			borderRadius='7px'
			padding='10px'
			{...props}
			title={`${props.title} ${props.highlighted}`}
		>
			<Text fontSize='xl' maxWidth='300px' textAlign='center'>
				{props.title} <b>{props.highlighted}</b>
			</Text>
			<Image height={['70px']} src={props.img} />
			{props.isLoading ? (
				<Spinner size='lg' margin={10} />
			) : (
				<Flex flexDirection={props.isRow ? 'row' : 'column'}>
					<Flex flexDirection='row' justifyContent='center' alignItems='center'>
						<Text fontSize='sm' marginRight='4px'>
							Realizadas:
						</Text>
						<Text fontSize='2xl'>{props.total}</Text>
					</Flex>

					<Flex flexDirection='row' justifyContent='center' alignItems='center'>
						<Text fontSize='sm' marginRight='4px'>
							Faturadas:
						</Text>
						<Text fontSize='2xl'>{props.totalInvoiced}</Text>
					</Flex>


					<Flex flexDirection='row' justifyContent='center' alignItems='center'>
						<Text fontSize='sm' marginRight='4px'>
							Valor:
						</Text>
						<Text fontSize='3xl'>{props.value}</Text>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default Card;
