import Cookies from 'js-cookie';

import { isProduction } from './config/variables';

const productionKey = '@cntv:session';
const staticEnvironmentKey = `@cntv-${process.env.REACT_APP_STAGE}:session`;

const getEnvironmentKey = () => {
	if (isProduction) {
		return productionKey;
	}

	return staticEnvironmentKey;
};

export const setSessionCookie = (session: any): void => {
	const environmentKey = getEnvironmentKey();
	Cookies.remove(productionKey);
	Cookies.remove(environmentKey);
	Cookies.set(environmentKey, JSON.stringify(session));
};

export const getSessionCookie = (): any => {
	let sessionCookie = Cookies.get(getEnvironmentKey());

	if (sessionCookie === 'undefined' || !sessionCookie) {
		sessionCookie = Cookies.get(productionKey);

		if (sessionCookie === 'undefined' || !sessionCookie) {
			return null;
		}
	}

	return JSON.parse(sessionCookie);
};

export const destroySessionCookie = (): void => {
	Cookies.remove(productionKey);
	Cookies.remove(getEnvironmentKey());
};
