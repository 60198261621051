/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDebtsDto } from './GetDebtsDto';
import {
    GetDebtsDtoFromJSON,
    GetDebtsDtoFromJSONTyped,
    GetDebtsDtoToJSON,
} from './GetDebtsDto';

/**
 * 
 * @export
 * @interface GetVehicleInquiriesDto
 */
export interface GetVehicleInquiriesDto {
    /**
     * 
     * @type {GetDebtsDto}
     * @memberof GetVehicleInquiriesDto
     */
    debitos: GetDebtsDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleInquiriesDto
     */
    autuacoes: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleInquiriesDto
     */
    inconsistent_data?: boolean;
}

/**
 * Check if a given object implements the GetVehicleInquiriesDto interface.
 */
export function instanceOfGetVehicleInquiriesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "debitos" in value;
    isInstance = isInstance && "autuacoes" in value;

    return isInstance;
}

export function GetVehicleInquiriesDtoFromJSON(json: any): GetVehicleInquiriesDto {
    return GetVehicleInquiriesDtoFromJSONTyped(json, false);
}

export function GetVehicleInquiriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVehicleInquiriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debitos': GetDebtsDtoFromJSON(json['debitos']),
        'autuacoes': json['autuacoes'],
        'inconsistent_data': !exists(json, 'inconsistent_data') ? undefined : json['inconsistent_data'],
    };
}

export function GetVehicleInquiriesDtoToJSON(value?: GetVehicleInquiriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debitos': GetDebtsDtoToJSON(value.debitos),
        'autuacoes': value.autuacoes,
        'inconsistent_data': value.inconsistent_data,
    };
}

