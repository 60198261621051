import React from 'react';

import { Flex, FormControl, Icon, InputGroup, InputRightElement, Stack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import { RegisterUserDto, RegisterUserRequest, UsersApi } from '../../clients';

import Header from '../../containers/Header';
import MfaModal from '../../containers/mfaModal/MfaModal';

import { getApiDefaultConfig } from '../../services/api.service';
import { cpfCnpjMask, cpfCnpjValidator } from '../../services/cpfCnpj.service';
import { cnsMask } from '../../services/mask.service';

import Button from '../../components/Button';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import Input from '../../components/Input';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { defaultColors } from '../../config/variables';

const Register: React.FC = () => {
	const apiConfig = getApiDefaultConfig();
	const { addToast } = useToasts();
	const navigate = useNavigate();

	const [mfaOpen, setMfaOpen] = React.useState<boolean>(true);
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);
	const [stage, setStage] = React.useState<number>(0);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<string>('');

	const initialValues = {
		name: '',
		document: '',
		email: '',
		password: '',
		confirm_password: '',
		seller_cns: '',
		seller_document: '',
		seller_name: '',
		seller_email: '',
	};

	const schema = yup.object().shape({
		name: yup.string().required('Campo obrigatório'),
		document: yup
			.string()
			.required('Campo obrigatório')
			.transform((value) => (value ? value.replace(/[^\d]+/g, '') : ''))
			.test('is-valid-cpf-cnpj', 'Documento inválido', function (value) {
				return cpfCnpjValidator(value as string);
			}),
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		password: yup.string().required('Campo obrigatório').min(8, 'A senha deve ter pelo menos 8 caracteres'),
		confirm_password: yup
			.string()
			.required('Campo obrigatório')
			.oneOf([yup.ref('password'), 'password'], 'As senhas devem corresponder'),
		seller_cns: yup.string().required('Campo obrigatório'),
		seller_document: yup
			.string()
			.required('Campo obrigatório')
			.transform((value) => (value ? value.replace(/[^\d]+/g, '') : ''))
			.test('is-valid-cpf-cnpj', 'Documento inválido', function (value) {
				return cpfCnpjValidator(value as string);
			}),
		seller_name: yup.string().required('Campo obrigatório'),
		seller_email: yup.string().required('Campo obrigatório').email('Formato de e-mail inválido'),
	});

	const handleClick = (field: string) => {
		if (field === 'password') {
			setShowPassword(!showPassword);
		} else if (field === 'confirm_password') {
			setShowConfirmPassword(!showConfirmPassword);
		}
	};

	const handleFormSubmit = async (values: any) => {
		setIsLoading(true);
		const { document, seller_cns, seller_document, confirm_password, ...parsedParams } = values;

		const usersApi = new UsersApi(apiConfig);

		const user: RegisterUserDto = {
			...parsedParams,
			document: document.replace(/\D+/gi, ''),
			seller_cns: seller_cns.replace(/\D+/gi, ''),
			seller_document: seller_document.replace(/\D+/gi, ''),
		};

		const data: RegisterUserRequest = {
			registerUserDto: user,
		};

		try {
			const response = await usersApi.registerUser(data);

			setUser(response.id);
			setMfaOpen(true);
		} catch (error) {
			addToast('Dados inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Stack minH='88%'>
			<Header logoutButton={false} showUser={false}/>
			<Stack w='100%' flexDirection='column' justifyContent='space-between' gap={[35, 0]}>
				<Flex alignSelf='flex-end' px='20px' pt='8px'>
					<Text fontSize='large'>Já possui cadastro?</Text>
					<Text
						fontSize='large'
						color={defaultColors.primaryColor}
						paddingLeft='4px'
						_hover={{
							textDecoration: 'underline',
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/');
						}}
					>
						Entre aqui
					</Text>
				</Flex>
				<Flex borderBottom='3px solid black' justifyContent='end' w='200px' alignSelf='flex-start'>
					<Flex>
						<Title color='black' fontWeight='400'>
							Cadastro
						</Title>
					</Flex>
				</Flex>
			</Stack>
			<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit} validateOnMount>
				{({ handleSubmit, setFieldValue, errors }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack justifyContent='center' direction={['column', 'column', 'row']} w='100%' h='100%' mt={[20, 0]}>
								{stage === 0 && (
									<VStack w={['100%', '100%', '60%']} maxW='800px' px='50px' mt='20px' align='start'>
										<Flex w='100%' mb='8px' justifyContent='center'>
											<Title fontWeight='400' color='black'>
												Serventia
											</Title>
										</Flex>
										<FormControl>
											<Input
												name='seller_cns'
												id='input__cns'
												placeholder='CNS'
												mb='8px'
												isReadOnly={false}
												border={`1px solid ${defaultColors.lightGreen}`}
												onChange={(e) => setFieldValue('seller_cns', cnsMask(e.target.value))}
											/>
											<FormErrorMessage name='seller_cns' />
											<Input
												name='seller_document'
												id='input__cnpj'
												placeholder='CPF/CNPJ'
												mb='8px'
												maxLength={18}
												isReadOnly={false}
												border={`1px solid ${defaultColors.lightGreen}`}
												onChange={(e) => setFieldValue('seller_document', cpfCnpjMask(e.target.value))}
											/>
											<FormErrorMessage name='seller_document' />
											<Input name='seller_name' id='input__seller-name' border={`1px solid ${defaultColors.lightGreen}`} placeholder='Razão Social' mb='8px' isReadOnly={false} />
											<FormErrorMessage name='seller_name' />
											<Input name='seller_email' id='input__seller-email' border={`1px solid ${defaultColors.lightGreen}`} placeholder='E-mail da Serventia' mb='8px' isReadOnly={false} />
											<FormErrorMessage name='seller_email' />
										</FormControl>
										{stage === 0 && (
											<Flex h='50px' w='100%' justify='center' align='center' mb='50px'>
												<Button
													isDisabled={
														!!errors.seller_name || 
														!!errors.seller_document || 
														!!errors.seller_email || 
														!!errors.seller_cns
													}
													border={`2px solid ${defaultColors.secondaryColor}`}
													h='35px'
													minWidth='200px'
													type='button'
													onClick={() => {
														setStage(1);
													}}
												>
													Próxima Etapa
												</Button>
											</Flex>
										)}
									</VStack>
								)}
								{stage === 1 && (
									<VStack w={['100%', '100%','60%']} maxW='800px' px='50px' mt='20px' align='start'>
										<Flex w='100%' mb='8px' justifyContent='center'>
											<Title fontWeight='400' color='black'>
												Usuário
											</Title>
										</Flex>
										<FormControl>
											<Input name='name' id='input__name' placeholder='Nome' border={`1px solid ${defaultColors.lightGreen}`} mb='8px' isReadOnly={false} />
											<FormErrorMessage name='name' />
											<Input
												name='document'
												id='input__document'
												placeholder='CPF'
												mb='8px'
												border={`1px solid ${defaultColors.lightGreen}`}
												isReadOnly={false}
												onChange={(e) => setFieldValue('document', cpfCnpjMask(e.target.value))}
											/>
											<FormErrorMessage name='document' />
											<Input name='email' id='input__email' placeholder='E-mail do Usuário' border={`1px solid ${defaultColors.lightGreen}`} mb='8px' isReadOnly={false} />
											<FormErrorMessage name='email' />
											<FormControl>
												<InputGroup size='md'>
													<Input
														name='password'
														id='input__password'
														placeholder='Senha'
														mb='8px'
														autoComplete='nope'
														pr='72px'
														border={`1px solid ${defaultColors.lightGreen}`}
														type={showPassword ? 'text' : 'password'}
														isReadOnly={false}
													/>
													<InputRightElement width='72px'>
														<Button size='xs' bgColor='transparent' onClick={() => handleClick('password')}>
															<Icon as={showPassword ? BsEyeFill : BsEyeSlashFill} boxSize='20px' color={defaultColors.primaryColor} />
														</Button>
													</InputRightElement>
												</InputGroup>
												<FormErrorMessage name='password' />
											</FormControl>
											<FormControl>
												<InputGroup size='md'>
													<Input
														name='confirm_password'
														id='input__confirm-password'
														placeholder='Confirmar Senha'
														mb='8px'
														border={`1px solid ${defaultColors.lightGreen}`}
														isReadOnly={false}
														autoComplete='nope'
														pr='72px'
														type={showConfirmPassword ? 'text' : 'password'}
													/>
													<InputRightElement width='72px'>
														<Button size='xs' bgColor='transparent' onClick={() => handleClick('confirm_password')}>
															<Icon as={showConfirmPassword ? BsEyeFill : BsEyeSlashFill} boxSize='20px' color={defaultColors.primaryColor} />
														</Button>
													</InputRightElement>
												</InputGroup>
												<FormErrorMessage name='confirm_password' />
											</FormControl>
										</FormControl>
										{stage === 1 && (
											<Flex h='50px' w='100%' gap={[3, 0]} flexDirection={['column', 'row']} justifyContent='space-between' align='center' mb='50px'>
												<Button
													border={`2px solid ${defaultColors.secondaryColor}`}
													h='35px'
													minWidth='200px'
													type='button'
													onClick={() => {
														setStage(0);
													}}
												>
													Voltar
												</Button>
												<Button
													isDisabled={
														!!errors.name || 
														!!errors.email || 
														!!errors.document || 
														!!errors.password || 
														!!errors.confirm_password
													}
													h='35px'
													minWidth='200px'
													border={`2px solid ${defaultColors.secondaryColor}`}
													type='submit'
													isLoading={isLoading}
												>
													Cadastrar
												</Button>
											</Flex>
										)}
									</VStack>
								)}
							</Stack>
						</form>
					);
				}}
			</Formik>
			{user && <MfaModal mfaOpen={mfaOpen} userId={user} setMfaOpen={setMfaOpen} />}
		</Stack>
	);
};

export default Register;
