import React from 'react';

import { Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useDebounce } from '../../../services/useDebounce.service';

interface ConfirmModalTypes {
	onClose: () => void;
	isOpen: boolean;
	handleModalSubmit: () => Promise<void>;
}

const ConfirmModal: React.FC<ConfirmModalTypes> = (props: ConfirmModalTypes) => {
	const [isAcceptLoading, setIsAcceptLoading] = React.useState<boolean>(false);

	const handleDecline = () => {
		props.onClose();
	};

	const handleAccept = useDebounce(() => {
		props.handleModalSubmit();
		props.onClose();
		setIsAcceptLoading(false);
	}, 300);

	return (
		<Modal isOpen={props.isOpen} onClose={handleDecline} closeOnOverlayClick={false} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<Flex padding='20px' flexDirection='column'>
					<Title textAlign='center' fontSize='2xl' borderBottom='1px solid grey'>
						Confirmar Consulta em Lote
					</Title>
					<Flex mt='15px'>
						<Text textAlign='justify'>
							Esta consulta gerará um relatório com todas as placas pesquisadas, sendo cobrada uma taxa individual para cada uma delas.
							Deseja continuar?
						</Text>
					</Flex>
					<Flex my='25px' justifyContent='space-around'>
						<Button type='submit' bgColor='red' onClick={handleDecline}>
							Não
						</Button>
						<Button
							isLoading={isAcceptLoading}
							type='submit'
							onClick={() => {
								setIsAcceptLoading(true);
								handleAccept();
							}}
						>
							Sim
						</Button>
					</Flex>
					<Flex>
						<Flex textAlign='justify' fontSize='13px' fontStyle='italic' flexDirection='column' gap='10px'>
							<Flex gap='5px'>
								<Text color='red'>*</Text>
								<Text>
									Se a pesquisa retornar placas com falhas, envie um novo lote contendo <strong>APENAS</strong> as placas com falhas. O
									reenvio de placas já consultadas com sucesso resultará em uma nova cobrança.
								</Text>
							</Flex>
							<Flex gap='5px'>
								<Text color='red'>*</Text>
								<Text>
									Antes de enviar um novo lote, verifique no histórico de consultas se as placas já foram pesquisadas anteriormente com sucesso. O processamento do lote pode
									levar alguns minutos.
								</Text>
							</Flex>
							<Flex gap='5px'>
								<Text color='red'>*</Text>
								<Text>
									Caso o resultado não seja enviado por e-mail, o download não esteja disponível ou o lote permaneça pendente após algumas horas, entre em contato com o suporte. O
									envio de lotes duplicados resultará em novas cobranças.
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmModal;
