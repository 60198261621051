import React from 'react';

import { Navigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';

const PrivateRoute = ({ children }:any) => {
  const { signed } = useAuth();

  return signed ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;