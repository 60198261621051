import React from 'react';

import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';

import { GetReportDto, GetVehicleInquiriesDto } from '../clients';

type InquiriesPrintProps = {
	report: GetReportDto | undefined;
	inquiries?: GetVehicleInquiriesDto | undefined;
};

export const InquiriesPrint: React.FC<InquiriesPrintProps> = (props: InquiriesPrintProps) => {
	return (
		<Flex
			flexDirection='column'
			w='85%'
			css={{
				'@media screen': {
					display: 'none',
				},
				'@media print': {
					display: 'flex',
				},
			}}
		>
			<Box
				borderTop='1px solid #E2E8F0'
				borderBottom='4px solid #E2E8F0'
				p='16px'
				borderRadius='md'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
				w='100%'
			>
				<Stack w='100%' alignItems='center' py='10px'>
					<QRCode value={props.report?.url ?? ''} size={120} renderAs='svg' />
					<Text textAlign='center'>
						Acesse o QRCode para consulta detalhada. Caso o veículo apresente débitos, também será possível realizar o pagamento em até 12x
						no cartão.
					</Text>
					{props.report?.url && (
						<Text mt='5px'>
							{' '}
							<strong>Link:</strong> <br /> {props.report?.url}
						</Text>
					)}
				</Stack>
			</Box>
			{props?.inquiries &&
				!props?.inquiries?.inconsistent_data &&
				(props?.inquiries?.debitos?.multas || props?.inquiries?.debitos?.ipva || props?.inquiries?.debitos?.licenciamento) && (
					<Box
						borderTop='1px solid #E2E8F0'
						borderBottom='4px solid #E2E8F0'
						p='16px'
						borderRadius='md'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
						w='100%'
					>
						<Stack>
							<Flex flexDirection='column'>
								<Text textAlign={['center', 'center', 'start']} fontWeight='bold'>
									Este veículo possui débitos.
								</Text>
								<Text textAlign={['center', 'center', 'start']} className='red-star'>
									Os débitos referem-se a IPVA, Licenciamento ou Multas.
								</Text>
								<Text textAlign={['center', 'center', 'start']} className='red-star'>
									Multas: Somente serão exibidas as multas disponíveis no Departamento de Trânsito de MG.
								</Text>
							</Flex>
						</Stack>
					</Box>
				)}
		</Flex>
	);
};
