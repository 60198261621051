import React from 'react';

import { Flex, Stack } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import Button from './Button';

const WhatsAppSupportButton: React.FC = () => {

	const handleSupportClick = () => {
		window.open(
            'https://wa.me/5531982598606?text=Olá,%20preciso%20de%20suporte%20com%20o%20CNTV.%20Poderia%20me%20ajudar?', 
            '_blank'
        );
	};

	return (
		<Stack w='100%' alignItems={['center', 'center', 'end']} mt='10px'>
			<Flex flexDirection='row' mt='8px'>
				<Button textAlign={['center', 'center', 'end']} onClick={handleSupportClick}>
					<FaWhatsapp />
					<Flex ml='5px'>Suporte</Flex>
				</Button>
			</Flex>
		</Stack>
	);
};

export default WhatsAppSupportButton;
