import React from 'react';

import { Flex, Icon, Image, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import { AuthApi, ResetPasswordRequestRequest } from '../../clients';

import { getApiDefaultConfig } from '../../services/api.service';

import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import Input from '../../components/Input';
import { defaultColors } from '../../config/variables';

const ResetPassword: React.FC = () => {
	const [searchParams] = useSearchParams();
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const apiConfig = getApiDefaultConfig();
	const { addToast } = useToasts();
	const navigate = useNavigate();

	const initialValues = {
		email: '',
		password: '',
		confirm_password: '',
	};

	const schema = yup.object().shape({
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		password: yup.string().required('Campo obrigatório'),
		confirm_password: yup
			.string()
			.required('Campo obrigatório')
			.oneOf([yup.ref('password'), 'password'], 'As senhas devem corresponder'),
	});

	const handleFormSubmit = async (values: any) => {
		setIsLoading(true);
		const { email, password } = values;
		const token = searchParams.get('token');

		if (!token) {
			addToast('Token não informado', {
				appearance: 'error',
				autoDismiss: true,
			});
			return;
		}

		try {
			const authApi = new AuthApi(apiConfig);

			const params: ResetPasswordRequestRequest = {
				resetPasswordRequestDto: {
					email,
					newPassword: password,
					token,
				},
			};

			await authApi.resetPasswordRequest(params);

			addToast('Senha atualizada com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});

			navigate('/');
		} catch (error) {
			addToast('E-mail ou senhas não correspondem', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<Flex alignItems='center' justify='center' paddingY='20px' flexDirection='column' minH='88%'>
			<Image src={require('../../assets/images/logo-fundo-branco.png')} w={[100, 150, 250]} marginBottom='20px' />
			<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
				{({ handleSubmit, isValid }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack spacing='12px'>
								<Stack spacing='20px'>
									<FormControl>
										<Input name='email' id='input__email' placeholder='Email' mb='4px' isReadOnly={false} />
									</FormControl>

									<FormControl>
										<InputGroup size='md'>
											<Input
												name='password'
												id='input__password'
												placeholder='Nova senha'
												mb='4px'
												autoComplete='nope'
												pr='72px'
												type={showPassword ? 'text' : 'password'}
												isReadOnly={false}
											/>
											<InputRightElement width='72px'>
												<Button size='xs' bgColor='transparent' onClick={() => setShowPassword(!showPassword)}>
													<Icon as={showPassword ? BsEyeFill : BsEyeSlashFill} boxSize='20px' color={defaultColors.primaryColor} />
												</Button>
											</InputRightElement>
										</InputGroup>
									</FormControl>

									<FormControl>
										<InputGroup size='md'>
											<Input
												name='confirm_password'
												id='input__confirm__password'
												placeholder='Repita nova senha'
												mb='1'
												autoComplete='nope'
												pr='72px'
												type={showConfirmPassword ? 'text' : 'password'}
												isReadOnly={false}
											/>
											<InputRightElement width='72px'>
												<Button size='xs' bgColor='transparent' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
													<Icon as={showConfirmPassword ? BsEyeFill : BsEyeSlashFill} boxSize='20px' color={defaultColors.primaryColor} />
												</Button>
											</InputRightElement>
										</InputGroup>
									</FormControl>

									<Flex w='100%'>
										<Button
											id='button__login'
											w='100%'
											type='submit'
											disabled={!isValid}
											isLoading={isLoading}
											bgColor={defaultColors.primaryColor}
											className='button__login'
										>
											Alterar senha
										</Button>
									</Flex>
								</Stack>
							</Stack>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default ResetPassword;
