import React from 'react';

import { Flex, Heading, Box, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import * as yup from 'yup';

import { ConfirmMailDto, ConfirmMailRequest, UsersApi } from '../../clients';

import { defaultColors } from '../../config/variables';

import { getApiDefaultConfig } from '../../services/api.service';

import Modal from '../Modal';
import Button from '../../components/Button';
import SquareInput from './SquareInput';


type MfaModalProps = {
	mfaOpen: boolean;
	userId: string;
	setMfaOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MfaModal: React.FC<MfaModalProps> = (props: MfaModalProps) => {
	const { userId } = props;
	const apiConfig = getApiDefaultConfig();
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleFormSubmit = async (values: any) => {
		setIsLoading(true);
		const { first_number, second_number, third_number, fourth_number } = values;

		const key = `${first_number}${second_number}${third_number}${fourth_number}`.toUpperCase();

		const usersApi = new UsersApi(apiConfig);

		const confirmMailDto: ConfirmMailDto = {
			email_key: key,
		};

		const params: ConfirmMailRequest = {
			userId,
			confirmMailDto,
		};

		try {
			await usersApi.confirmMail(params);

			addToast('E-mail confirmado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});

			navigate('/');
		} catch (error) {
			addToast('Chave incorreta', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			props.setMfaOpen(false);
			setIsLoading(false);
		}
	};

	const initialValues = {
		first_number: '',
		second_number: '',
		third_number: '',
		fourth_number: '',
	};

	const schema = yup.object().shape({
		first_number: yup.string().required('Campo obrigatório').min(1).max(1),
		second_number: yup.string().required('Campo obrigatório').min(1).max(1),
		third_number: yup.string().required('Campo obrigatório').min(1).max(1),
		fourth_number: yup.string().required('Campo obrigatório').min(1).max(1),
	});

	return (
		<Modal isOpen={props.mfaOpen} onClose={() => props.setMfaOpen(false)}>
			<Box borderRadius='md'>
				<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
					{({ handleSubmit, isValid, setFieldValue }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Flex p='32px' flexDirection='column'>
									<Heading fontSize='lg' paddingBottom='32px' color={defaultColors.primaryColor}>
										Digite o código fornecido pelo administrador da serventia:
									</Heading>

									<HStack mt='8px' mb='20px' spacing='60px' justify='center'>
										<SquareInput
											name='first_number'
											id='first_number'
											onChange={(e) => {
												setFieldValue('first_number', e.target.value);
												document.getElementById('second_number')?.focus();
											}}
										/>
										<SquareInput
											name='second_number'
											id='second_number'
											onChange={(e) => {
												setFieldValue('second_number', e.target.value);
												document.getElementById('third_number')?.focus();
											}}
										/>
										<SquareInput
											name='third_number'
											id='third_number'
											onChange={(e) => {
												setFieldValue('third_number', e.target.value);
												document.getElementById('fourth_number')?.focus();
											}}
										/>
										<SquareInput
											name='fourth_number'
											id='fourth_number'
											onChange={(e) => {
												setFieldValue('fourth_number', e.target.value);
												document.getElementById('submit_mfa_button')?.focus();
											}}
										/>
									</HStack>

									<Flex flexDirection='row' alignItems='center' justifyContent='center' fontSize='md' px='12px' pb='8px'>
										<Button id='submit_mfa_button' isLoading={isLoading} disabled={!isValid} size='lg' type='submit'>
											Confirmar
										</Button>
									</Flex>
								</Flex>
							</form>
						);
					}}
				</Formik>
			</Box>
		</Modal>
	);
};

export default MfaModal;
