import React from 'react';

import { Box, Flex, Image, Button } from '@chakra-ui/react';
import { MdLogout } from 'react-icons/md';
import { IoPerson } from 'react-icons/io5';

import { HamburguerMenu } from '../pages/Admin/Menu';

import { useAuth } from '../contexts/AuthProvider';

import Text from '../components/Text';
import { defaultColors } from '../config/variables';

type HeaderProps = {
	imageView?: boolean;
	historyPage?: boolean;
	showUser?: boolean;
	logoutButton?: boolean;
};

const Header: React.FC<HeaderProps> = ({ imageView = true, logoutButton = true, showUser = true }) => {
	const { logout, user, signed } = useAuth();
	return (
		<>
			<Flex
				justifyContent='space-between'
				backgroundColor={defaultColors.primaryColor}
				boxShadow={`1px 3px 2px 2px ${defaultColors.secondaryColor}`}
				alignItems={['center', 'end']}
			>
				<Box textAlign='start' color='white' flexDirection='row' display={signed ? ['none', 'none', 'flex'] : 'flex'} alignItems='end'>
					{imageView && <Image height={['70px', '100px']} src={require('../assets/images/logo-branco.png')} mx='20px' py='4px' />}
				</Box>
				{signed && showUser && (
					<Box textAlign='start' color='white' flexDirection='row' display={['flex', 'flex', 'none']} alignItems='end'>
						<HamburguerMenu />
					</Box>
				)}
				<Box textAlign='start' color='white' display='flex' flexDirection='row' alignItems='center' gap='8px'>
					{signed && showUser && (
						<Flex flexDirection='row' alignItems='center' gap='8px'>
							<Text textAlign='end'>{user.name}</Text>
							<IoPerson color='white' size={24} />
						</Flex>
					)}

					{logoutButton && (
						<Button
							variant='link'
							id='button__logout'
							color='white'
							size={['sm', 'sm', 'lg']}
							onClick={() => {
								logout();
							}}
						>
							<Text marginRight='10px' fontWeight='500'>
								Sair
							</Text>
							<MdLogout size='30px' />
						</Button>
					)}
				</Box>
			</Flex>
		</>
	);
};

export default Header;
