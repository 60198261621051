/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePixPaymentResponseDTO,
  GetBilletInoformationResponseDto,
  GetSimulationDto,
  PayConsultDto,
  PayDebtDto,
  PixPayConsultDto,
} from '../models/index';
import {
    CreatePixPaymentResponseDTOFromJSON,
    CreatePixPaymentResponseDTOToJSON,
    GetBilletInoformationResponseDtoFromJSON,
    GetBilletInoformationResponseDtoToJSON,
    GetSimulationDtoFromJSON,
    GetSimulationDtoToJSON,
    PayConsultDtoFromJSON,
    PayConsultDtoToJSON,
    PayDebtDtoFromJSON,
    PayDebtDtoToJSON,
    PixPayConsultDtoFromJSON,
    PixPayConsultDtoToJSON,
} from '../models/index';

export interface CreatePaymentQrCodeRequest {
    pixPayConsultDto: PixPayConsultDto;
}

export interface GetBilletInformationRequest {
    debtId: string;
}

export interface ListSimulationRequest {
    amountCents: number;
    isCelcoin?: boolean;
}

export interface PayConsultRequest {
    payConsultDto: PayConsultDto;
}

export interface PayDebtRequest {
    payDebtDto: PayDebtDto;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Efetuar pagamento da consult veícular via Pix
     */
    async createPaymentQrCodeRaw(requestParameters: CreatePaymentQrCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePixPaymentResponseDTO>> {
        if (requestParameters.pixPayConsultDto === null || requestParameters.pixPayConsultDto === undefined) {
            throw new runtime.RequiredError('pixPayConsultDto','Required parameter requestParameters.pixPayConsultDto was null or undefined when calling createPaymentQrCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/create-pix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PixPayConsultDtoToJSON(requestParameters.pixPayConsultDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Efetuar pagamento da consult veícular via Pix
     */
    async createPaymentQrCode(requestParameters: CreatePaymentQrCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePixPaymentResponseDTO> {
        const response = await this.createPaymentQrCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar informações do débito
     */
    async getBilletInformationRaw(requestParameters: GetBilletInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBilletInoformationResponseDto>> {
        if (requestParameters.debtId === null || requestParameters.debtId === undefined) {
            throw new runtime.RequiredError('debtId','Required parameter requestParameters.debtId was null or undefined when calling getBilletInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payments/get-billet-information/{debt_id}`.replace(`{${"debt_id"}}`, encodeURIComponent(String(requestParameters.debtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBilletInoformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar informações do débito
     */
    async getBilletInformation(requestParameters: GetBilletInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBilletInoformationResponseDto> {
        const response = await this.getBilletInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Listar simulação de parcelas de Pagamento de Contas
     */
    async listSimulationRaw(requestParameters: ListSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSimulationDto>>> {
        if (requestParameters.amountCents === null || requestParameters.amountCents === undefined) {
            throw new runtime.RequiredError('amountCents','Required parameter requestParameters.amountCents was null or undefined when calling listSimulation.');
        }

        const queryParameters: any = {};

        if (requestParameters.amountCents !== undefined) {
            queryParameters['amount_cents'] = requestParameters.amountCents;
        }

        if (requestParameters.isCelcoin !== undefined) {
            queryParameters['is_celcoin'] = requestParameters.isCelcoin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payments/simulation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSimulationDtoFromJSON));
    }

    /**
     * Listar simulação de parcelas de Pagamento de Contas
     */
    async listSimulation(requestParameters: ListSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSimulationDto>> {
        const response = await this.listSimulationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Efetuar pagamento da consult veícular
     */
    async payConsultRaw(requestParameters: PayConsultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.payConsultDto === null || requestParameters.payConsultDto === undefined) {
            throw new runtime.RequiredError('payConsultDto','Required parameter requestParameters.payConsultDto was null or undefined when calling payConsult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/pay-consult`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayConsultDtoToJSON(requestParameters.payConsultDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Efetuar pagamento da consult veícular
     */
    async payConsult(requestParameters: PayConsultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.payConsultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Efetuar pagamento de débito veícular
     */
    async payDebtRaw(requestParameters: PayDebtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.payDebtDto === null || requestParameters.payDebtDto === undefined) {
            throw new runtime.RequiredError('payDebtDto','Required parameter requestParameters.payDebtDto was null or undefined when calling payDebt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/pay-debt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayDebtDtoToJSON(requestParameters.payDebtDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Efetuar pagamento de débito veícular
     */
    async payDebt(requestParameters: PayDebtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.payDebtRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
