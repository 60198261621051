import React from 'react';

import { Stack } from '@chakra-ui/react';

import { ConsultDebtsProvider } from '../ConsultDebts/context/ConsultDebtsProvider';
import { ConsultVehicleHistoryProvider } from '../ConsultVehicleHistory/context/ConsultVehicleHistoryProvider';

import Header from '../../containers/Header';
import { ConsultHome } from './containers/ConsultHome';

const Consult: React.FC = () => {
	return (
		<ConsultDebtsProvider>
			<ConsultVehicleHistoryProvider>
				<Stack minH='88%'>
					<Header showUser={false} logoutButton={false} />
					<ConsultHome />
				</Stack>
			</ConsultVehicleHistoryProvider>
		</ConsultDebtsProvider>
	);
};

export default Consult;
