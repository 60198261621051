import React, { ReactNode } from 'react';

import { Text as DefaultText, TextProps as DefaultTextProps } from '@chakra-ui/react';

type TextProps = DefaultTextProps & {
	children: ReactNode;
};

const Text: React.FC<TextProps> = (props: TextProps) => {
  return (
    <DefaultText
        fontFamily={props.fontFamily || 'montserrat'}
        {...props}
    >
        {props.children}
    </DefaultText>
  );
}

export default Text;