import React, { useRef } from 'react';

import { Box, Flex, Modal, ModalContent, ModalOverlay, Stack } from '@chakra-ui/react';
import { TbReportMoney } from 'react-icons/tb';
import ReactToPrint from 'react-to-print';

import { defaultColors } from '../../../config/variables';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { Receipt } from './Receipt';

type DebtReceiptModalProps = {
	sizeButton?: string;
	bgColorButton?: string;
	colorButton?: string;
	variantButton?: string;
	paddingButton?: number | string;
	list?: any;
	debt?: any;
};

export const DebtReceiptModal: React.FC<DebtReceiptModalProps> = (props: DebtReceiptModalProps) => {
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	const componentRef = useRef<HTMLDivElement>(null);

	const toggleModal = () => {
		setModalOpen((prevState) => !prevState);
	};

	return (
		<>
			<Button
				size={props.sizeButton || 'lg'}
				variant={props.variantButton || 'solid'}
				bgColor={props.bgColorButton || defaultColors.primaryColor}
				color={props.colorButton || '#fff'}
				padding={props.paddingButton}
				alignItems='center'
				onClick={toggleModal}
			>
				<TbReportMoney size={22} />
			</Button>
			<Modal isOpen={modalOpen} onClose={toggleModal} >
				<ModalOverlay />
				<ModalContent p='15px' minH='60px' margin='auto'>
					<Stack alignItems='center' gap='20px'>
						<Text fontSize={30} mt='10px'>
						Imprimir Recibo
						</Text>
						<Text>Deseja imprimir o recibo?</Text>
						<Box borderRadius='md' w='100%'>
							<Flex flexDirection='row' alignItems='center' justifyContent='center' fontSize='md' px='12px' pb='8px' gap='20px'>
								<ReactToPrint
									content={() => componentRef.current}
									trigger={() => (
										<Button id='submit_modal-button' size='lg' type='submit'>
											Imprimir
										</Button>
									)}
								/>
								<Button backgroundColor='red' onClick={toggleModal}>Cancelar</Button>
							</Flex>
							<Flex ref={componentRef}>
								<Receipt debt={props.debt} list={props.list} />
							</Flex>
						</Box>
					</Stack>
				</ModalContent>
			</Modal>
		</>
	);
};
