/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetSellerDto,
} from '../models/index';
import {
    GetSellerDtoFromJSON,
    GetSellerDtoToJSON,
} from '../models/index';

export interface ImportSellersRequest {
    file: Blob;
    name?: string;
    contentType?: string;
}

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     * Buscar cartórios
     */
    async getSellersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSellerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerDtoFromJSON));
    }

    /**
     * Buscar cartórios
     */
    async getSellers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSellerDto>> {
        const response = await this.getSellersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Importar cartórios
     */
    async importSellersRaw(requestParameters: ImportSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling importSellers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType as any);
        }

        const response = await this.request({
            path: `/v1/sellers/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Importar cartórios
     */
    async importSellers(requestParameters: ImportSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importSellersRaw(requestParameters, initOverrides);
    }

}
