export const logoUrl: string = process.env.REACT_APP_LOGO!;
export const isProduction: boolean = process.env.REACT_APP_STAGE === 'production';

export const defaultColors = {
    primaryColor: '#00B0BC',
    secondaryColor: '#D9E32C',
    lightGreen: '#7EC751',
    darkGreen: '#13B047',
    lightBlue: '#00B0BC',
    darkBlue: '#246C72',
    white: '#F1FDFF'
}