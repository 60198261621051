import React, { ReactNode } from 'react';

import { Box, Button, Flex, TabProps, Text } from '@chakra-ui/react';

import { defaultColors } from '../../../config/variables';

type CustomTabProps = {
	iconSrc: ReactNode;
	label: string;
	description: string;
} & TabProps;

const ConsultButton: React.FC<CustomTabProps> = ({ iconSrc, label, description, ...rest }) => {
	return (
		<Button
			{...rest}
			_hover={{ bg: '#F1FDFF' }}
			_selected={{ borderWidth: 2, borderColor: 'blue.500' }}
			backgroundColor='gray.100'
			border={`1px solid ${defaultColors.primaryColor}`}
			rounded='md'
			p='20px'
			h='100%'
		>
			<Flex direction='column' alignItems='center' textAlign='center'>
				{iconSrc}
				<Box maxWidth='100%' overflow='hidden'>
					<Text fontSize='md' fontWeight='semibold' mb='4px' mt='20px' whiteSpace='normal' wordBreak='break-word'>
						{label}
					</Text>
				</Box>
				<Box maxWidth='100%' overflow='hidden'>
					<Text fontSize='xs' fontWeight='400' whiteSpace='normal' wordBreak='break-word'>
						{description}
					</Text>
				</Box>
			</Flex>
		</Button>
	);
};

export default ConsultButton;
