/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayerDto } from './PayerDto';
import {
    PayerDtoFromJSON,
    PayerDtoFromJSONTyped,
    PayerDtoToJSON,
} from './PayerDto';

/**
 * 
 * @export
 * @interface PixPayConsultDto
 */
export interface PixPayConsultDto {
    /**
     * 
     * @type {number}
     * @memberof PixPayConsultDto
     */
    amount_cents: number;
    /**
     * 
     * @type {string}
     * @memberof PixPayConsultDto
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof PixPayConsultDto
     */
    installments: number;
    /**
     * 
     * @type {PayerDto}
     * @memberof PixPayConsultDto
     */
    payer: PayerDto;
    /**
     * 
     * @type {string}
     * @memberof PixPayConsultDto
     */
    debt_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PixPayConsultDto
     */
    celcoin_id?: string;
}

/**
 * Check if a given object implements the PixPayConsultDto interface.
 */
export function instanceOfPixPayConsultDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount_cents" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "installments" in value;
    isInstance = isInstance && "payer" in value;

    return isInstance;
}

export function PixPayConsultDtoFromJSON(json: any): PixPayConsultDto {
    return PixPayConsultDtoFromJSONTyped(json, false);
}

export function PixPayConsultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PixPayConsultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'token': json['token'],
        'installments': json['installments'],
        'payer': PayerDtoFromJSON(json['payer']),
        'debt_id': !exists(json, 'debt_id') ? undefined : json['debt_id'],
        'celcoin_id': !exists(json, 'celcoin_id') ? undefined : json['celcoin_id'],
    };
}

export function PixPayConsultDtoToJSON(value?: PixPayConsultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'token': value.token,
        'installments': value.installments,
        'payer': PayerDtoToJSON(value.payer),
        'debt_id': value.debt_id,
        'celcoin_id': value.celcoin_id,
    };
}

