import React from 'react';
import { Flex, Image, Stack } from '@chakra-ui/react';

import { GetReportDto, GetVehicleDto, GetVehicleInquiriesDto } from '../../../clients';

import { InquiriesPrint } from '../../../components/InquiriesPrint';
import Text from '../../../components/Text';
import Title from '../../../components/Title';

import { cpfCnpjMask } from '../../../services/cpfCnpj.service';

import CrvInput from './CrvInput';

interface CrvDocumentProps {
	componentRef?: React.RefObject<HTMLDivElement>;
	licensePlateData?: GetVehicleDto | undefined;
	report?: GetReportDto | undefined;
	inquiries?: GetVehicleInquiriesDto | undefined;
}

const CrvDocument: React.FC<CrvDocumentProps> = (props: CrvDocumentProps) => {
	const ownerName = props.licensePlateData?.owner?.name ?? ' ';
	const cityRegistration = props.licensePlateData?.cityRegistration ?? ' ';
	const stateRegistration = props.licensePlateData?.stateRegistration ?? ' ';
	const ownerNameAddress = `${ownerName}, ${cityRegistration}, ${stateRegistration}`;

	const seats = props.licensePlateData?.seats ? `${props.licensePlateData?.seats}P` : 'N/A';
	const potency = props.licensePlateData?.potency ? `${props.licensePlateData?.potency}CV` : 'N/A';
	const cylinder = props.licensePlateData?.cylinderCapacity ? `${props.licensePlateData?.cylinderCapacity}CC` : 'N/A';
	const seatsPotencyCylinder = `${seats} / ${potency} / ${cylinder}`;

	const specie = props.licensePlateData?.specie ?? '';
	const type = props.licensePlateData?.type ?? '';
	const specieType = `${specie} ${type}`;

	const searchDate = props.report?.reprocessing_date
		? props.report?.reprocessing_date.toLocaleString('pt-BR')
		: props.report?.created_at.toLocaleString('pt-BR');

	const printHeader = {
		'@media print': {
			padding: '15px',
		},
	};
	return (
		<Stack w='100%' ref={props.componentRef} mb='20px' flexDirection='column'>
			<Stack w='100%' flexDirection='row' justifyContent='space-between' mt='25px' alignItems='center' css={printHeader}>
				<Flex maxW='85%' height='min-content'>
					<Title fontSize={['lg', 'lg', '3xl']} fontWeight={350} marginBottom='40px' borderBottom='2px solid black'>
						CRV - Certificado de Registro de Veículo
					</Title>
				</Flex>
				<Flex alignItems='center' w='12%'>
					<Image src={require('../../../assets/images/seal.png')} />
				</Flex>
			</Stack>
			<Stack w='100%' alignItems='center'>
				<Stack w='100%' position='relative' className='crv-print-width'>
					<Stack w='100%'>
						<Stack flexDirection='row' justifyContent='space-between'>
							<CrvInput w={['100%', '40%']} children='COD. RENAVAM' value={props.licensePlateData?.renavam} id='input__vehicle-renavam' />
							{props.licensePlateData?.document_number && (
								<CrvInput
									w={['100%', '40%']}
									children='N°'
									value={props.licensePlateData?.document_number}
									id='input__vehicle-document-number'
								/>
							)}
						</Stack>
						<CrvInput isTextArea={true} children='NOME / ENDEREÇO' value={ownerNameAddress} id='input__seller-name-address' />
						<Stack flexDirection='row'>
							<CrvInput
								w='65%'
								children='CPF / CNPJ'
								value={cpfCnpjMask(props.licensePlateData?.owner?.document as string)}
								id='input__seller-cpf-cnpj'
							/>
							<CrvInput w='35%' children='PLACA' value={props.licensePlateData?.licensePlate} id='input__vehicle-license-plate' />
						</Stack>
						<CrvInput value={props.licensePlateData?.chassis} children='CHASSI' id='input__vehicle-chassis' />
						<Stack flexDirection={['column', 'row']} className='crv-print-box'>
							{(props.licensePlateData?.specie || props.licensePlateData?.type) && (
								<CrvInput w={['100%', '65%']} value={specieType} id='input__vehicle-specie-type' children='ESPÉCIE / TIPO' />
							)}
							<CrvInput
								w={props.licensePlateData?.specie || props.licensePlateData?.type ? ['100%', '65%', '35%'] : '100%'}
								value={props.licensePlateData?.fuel}
								id='input__vehicle-fuel'
								children='COMBUSTÍVEL'
							/>
						</Stack>
						<Stack flexDirection={['column', 'row']} className='crv-print-box'>
							<CrvInput w='100%' value={props.licensePlateData?.brandModel} id='input__vehicle-brand-model' children='MARCA / MODELO' />
							<Stack flexDirection='row'>
								<CrvInput
									w='50%'
									value={props.licensePlateData?.manufactureYear}
									id='input__vehicle-manufacture-year'
									children='ANO FAB.'
								/>
								<CrvInput w='50%' value={props.licensePlateData?.modelYear} id='input__vehicle-model-year' children='ANO MOD.' />
							</Stack>
						</Stack>
						<Stack flexDirection={['column', 'row']} className='crv-print-box'>
							<CrvInput
								w={['100%', '60%']}
								value={seatsPotencyCylinder}
								id='input__vehicle-seats-potency-cylinder'
								children='CAP / POT / CIL'
							/>
							<Stack flexDirection='row' w={['100%', '40%']}>
								{props.licensePlateData?.category && (
									<CrvInput w='55%' value={props.licensePlateData?.category} id='input__vehicle-category' children='CATEGORIA' />
								)}
								<CrvInput
									w={props.licensePlateData?.category ? '45%' : '100%'}
									value={props.licensePlateData?.color}
									id='input__vehicle-color'
									children='COR PRED.'
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<InquiriesPrint report={props.report} inquiries={props.inquiries} />
				<Stack mt={5} alignItems='center'>
					<Text fontSize={['12', '12', '16']} textAlign='center' className='crv-print-font-size'>
						CERTIFICADO DE VALIDAÇÃO ID: {props.report?.transaction_number} - {searchDate}
					</Text>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default CrvDocument;
