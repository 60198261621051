/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSellerDto
 */
export interface GetSellerDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerDto
     */
    cns: string;
}

/**
 * Check if a given object implements the GetSellerDto interface.
 */
export function instanceOfGetSellerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cns" in value;

    return isInstance;
}

export function GetSellerDtoFromJSON(json: any): GetSellerDto {
    return GetSellerDtoFromJSONTyped(json, false);
}

export function GetSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'cns': json['cns'],
    };
}

export function GetSellerDtoToJSON(value?: GetSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'cns': value.cns,
    };
}

