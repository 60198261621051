import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import Header from '../../containers/Header';

import Menu from './Menu';

const Admin: React.FC = () => {
    return (
        <Flex flexDirection='column' minH='88%'>
            <Header/>
            <Flex flexDirection='row' minH='76vh'>
                <Menu />
                <Outlet />
            </Flex>
        </Flex>
    );
}

export default Admin;